import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from "antd";
import React from "react";
import { useReactFlow } from "reactflow";


export default function FlowGraphZoomMenu() {
    const { zoomIn, zoomOut, fitView, setCenter } = useReactFlow();
    return (
        <>
            <Button
                type="text"
                shape="circle"
                size="middle"
                className="flow-chart-button"
                icon={<FontAwesomeIcon icon={solid("magnifying-glass-plus")} />}
                onClick={() => zoomIn() } />
            <Button
                type="text"
                shape="circle"
                size="middle"
                className="flow-chart-button"
                icon={<FontAwesomeIcon icon={solid("magnifying-glass-minus")} />}
                onClick={() => zoomOut() } />
            <Button
                type="text"
                shape="circle"
                size="middle"
                className="flow-chart-button"
                icon={<FontAwesomeIcon icon={solid("down-left-and-up-right-to-center")} />}
                onClick={() => fitView({ maxZoom: 1 }) } />
        </>
    );
}