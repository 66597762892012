import * as React from 'react';
import TabBar from './TabBar';
import AppHeader from './AppHeader';
import MarkdownFormatter from '../components/ui/MarkdownFormatter';
import { Button, Layout, Modal } from 'antd';
import { connect } from 'react-redux';
import { fetchDataTagsThunk } from '../store/actions/tagActions';
import { fetchFilesThunk } from '../store/actions/filesActions';
import { acceptLicenseAgreementThunk, initializeDataThunk, logoutThunk, refreshTokenThunk, setLicenseAgreementHandledAction } from '../store/actions/authActions';
import { bindActionCreators } from 'redux';
import { selectAppLayoutData } from '../store/selectors';
import { AppLayoutDispatchProps, AppLayoutProps, AppLayoutStateProps, IApplicationState } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppDispatch } from '../hooks';
import { fetchNodeBlueprints } from '../store/slices/workflowsSlice';

function AppLayout(props: AppLayoutProps) {
    const [initialized, setInitialized] = React.useState(false);
    const [showLicenseAgreementPopup, setShowLicenseAgreementPopup] = React.useState(false);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!initialized) {
            // This is where all app initializations should come. At this point the token
            // should be available and the proper access should be granted.
            dispatch(initializeDataThunk())
            dispatch(fetchFilesThunk());
            dispatch(fetchNodeBlueprints(null));
            dispatch(fetchDataTagsThunk());
            setInitialized(true);
        }
    });

    React.useEffect(() => {
        if (props.licenseAgreement) {
            setShowLicenseAgreementPopup(true);
        }
    }, [ props.licenseAgreement ]);

    return (
        <>
            <Layout style={{ height: '100vh' }}>
                <AppHeader />
                <Layout>
                    <TabBar />
                </Layout>
            </Layout>
            <Modal
                className="flow-graph-node-settings-modal"
                open={showLicenseAgreementPopup}
                width='80%'
                closable={false}
                maskClosable={false}
                keyboard={false}
                footer={[
                    <Button type="text" onClick={() => {
                        setShowLicenseAgreementPopup(false);
                        props.logout();
                    }}>Reject</Button>,
                    <Button type="primary" onClick={() => {
                        props.acceptLicenseAgreement(props.licenseAgreement?.type);
                        props.refreshToken();
                        setShowLicenseAgreementPopup(false);
                    }}>{props.licenseAgreement?.acceptButtonText}</Button>
                ]}>
                <MarkdownFormatter>
                    {`
# ${props.licenseAgreement?.name}

${props.licenseAgreement?.markdownText}
                    `}
                </MarkdownFormatter>
            </Modal>
        </>
    );
}


export default connect<AppLayoutStateProps, AppLayoutDispatchProps, {}, IApplicationState>(
    (state) => selectAppLayoutData(state),
    dispatch => bindActionCreators({
        acceptLicenseAgreement: acceptLicenseAgreementThunk,
        refreshToken: refreshTokenThunk,
        logout: logoutThunk,
        setLicenseAgreementHandled: setLicenseAgreementHandledAction,
    }, dispatch)
)(AppLayout)