import { nanoid } from "@reduxjs/toolkit";
import { Node, Edge, MarkerType } from "reactflow";
import { UNKNOWN_NODE_BLUEPRINT } from "../constants";
import { FlowGraphViewModel, NodeBlueprint, Workflow, WorkflowBackend, WorkflowNodeData } from "../types";
import { EdgeUIType, NodeUIType } from "./enums";
import { Dictionary } from "lodash";
import {immerable} from "immer"

export function flowGraphDataToViewModel(workflowId: string, nodes: Array<Node<WorkflowNodeData>>, edges: Array<Edge<any>>): FlowGraphViewModel {
    return {
        workflowId: workflowId,
        nodes: nodes.map(n => ({
            workflowNodeId: n.id,
            nodeType: n.data?.blueprint?.nodeBlueprintId ?? -1,
            x: n.position.x,
            y: n.position.y,
            propValues: n.data?.propValues ?? {},
            propValueGroups: n.data?.propValueGroups ?? [],
        })),
        edges: edges.map(e => ({
            workflowEdgeId: e.id,
            sourceNodeId: e.source,
            sourceHandle: e.sourceHandle || nanoid(), // SourceHandle should always be present
            targetNodeId: e.target,
            targetHandle: e.targetHandle || nanoid() // TargetHandle should always be present
        })),
    }
}

export function viewModelToFlowGraphData(result: WorkflowBackend, blueprints: Dictionary<NodeBlueprint>): Workflow {
    const _nodes = [...result.nodes.map(n => ({
        id: n.workflowNodeId,
        data: {
            workflowId: result.workflowId,
            workflowNodeId: n.workflowNodeId,
            blueprint: blueprints[n.nodeType] ?? UNKNOWN_NODE_BLUEPRINT,
            propValues: n.propValues,
            propValueGroups: n.propValueGroups,
        },
        position: { x: n.x, y: n.y },
        ...nodeDataMappings(NodeUIType.Default) // If new node UIs are required, send this from blueprint
    }))];

    const _edges = [...result.edges.map(e => ({
        id: e.workflowEdgeId,
        source: e.sourceNodeId,
        sourceHandle: e.sourceHandle,
        target: e.targetNodeId,
        targetHandle: e.targetHandle,
        ...edgeDataMappings(EdgeUIType.Default) // If new edge UIs are required, send this from source node blueprint
    }))];

    return {
        ...result,
        nodes: _nodes,
        edges: _edges,
        isDeleted: false,
    };
}

export function newNode(blueprint: NodeBlueprint, workflowId: string) {
    const newId = nanoid();
    return {
        id: newId,
        data: {
            workflowId: workflowId,
            workflowNodeId: newId,
            blueprint: blueprint,
            propValues: {},
            propValueGroups: [],
        },
        position: { x: 0, y: 0 },
        ...nodeDataMappings(NodeUIType.Default)
    }
}

export function mapNewEdge(edgeData: any): Edge {
    return {
        ...edgeData,
        ...edgeDataMappings(EdgeUIType.Default)
    };
}

function nodeDataMappings(nodeType: NodeUIType) {
    switch (nodeType) {
        case NodeUIType.Default:
        default:
            return {
                type: "defaultNode"
            }
    }
}

function edgeDataMappings(edgeType: EdgeUIType) {
    switch (edgeType) {
        case EdgeUIType.Default:
        default:
            return {
                type: "defaultEdge",
                arrowHeadType: MarkerType.ArrowClosed,
                className: "default"
            }
    }
}