import * as React from 'react';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import New from '../components/ui/New';
import DangerousConfirmButton from '../components/ui/DangerousConfirmButton';
import { connect, useDispatch } from 'react-redux';
import { Button, Row, Col, Table, Badge, Typography } from 'antd';
import { IApplicationState, Scan, ScansPageDispatchProps, ScansPageOwnProps, ScansPageProps, ScansPageStateProps } from '../types';
import { bindActionCreators } from 'redux';
import { selectScansPage } from '../store/selectors';
import { openViewProjectTabAction } from '../store/actions/projectActions';
import { ScanStatus } from '../core/enums';
import { deleteScanThunk, fetchScanJsonFileThunk, openViewScanTabAction } from '../store/actions/scanActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppDispatch } from '../store/configureAppStore';
import { openViewWorkflowTab } from '../store/slices/workflowsSlice';

const { Column } = Table;
const { Text } = Typography;


function ScansPage(props: ScansPageProps) {
    if (!props.scans) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    const dispatch = useDispatch<AppDispatch>();

    const viewScanWrapped = (scanId: string) => () => props.openViewScanTab(scanId);
    const viewProjectWrapped = (projectId: string) => () => props.openViewProjectTab(projectId);
    const viewWorkflowWrapped = (workflowId: string) => () => openViewWorkflowTab(dispatch, workflowId);

    return (
        <GutteredPage>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={props.scans}
                        rowKey="id"
                        pagination={{position: ["bottomLeft"]}}
                        scroll={{ x: true }}>
                        <Column title="Scan" dataIndex="scanName" className="short-cell" render={(text: any, record: Scan) => (
                            <>
                                <Text ellipsis={{ tooltip: text }}>
                                    {record.isReviewed ? <></> : <New />}
                                    <Button type="link" onClick={viewScanWrapped(record.scanId)}>{text}</Button>
                                </Text>
                            </>
                        )} />
                        <Column title="Project" dataIndex="projectName" render={(text: any, record: Scan) => <><Button type="link" icon={<FontAwesomeIcon icon={solid("globe")} />} onClick={viewProjectWrapped(record.projectId)}>{text}</Button> <Text type="secondary">(ver {record.projectVersion})</Text></>} />
                        <Column title="Workflow" dataIndex="workflowName" render={(text: any, record: Scan) => <><Button type="link" icon={<FontAwesomeIcon icon={solid("diagram-project")} />} onClick={viewWorkflowWrapped(record.workflowId)}>{text}</Button> <Text type="secondary">(ver {record.workflowVersion})</Text></>} />
                        <Column title="Started at" dataIndex="scanStart" render={(_: any, record: Scan) => new Date(record.scanStart).toLocaleString()} />
                        <Column title="Status" dataIndex="scanStatus" render={(_: any, record: Scan) => getScanStatus(record.scanStatus)} />
                        <Column title="Actions" render={(_, record: Scan) => (
                            <>
                                <Button
                                    type='text'
                                    icon={<FontAwesomeIcon icon={solid("download")} />}
                                    onClick={() => { props.fetchScanJsonFile(record.scanId ?? "") }} />
                                <DangerousConfirmButton
                                    popupText='Delete scan?'
                                    icon={solid("trash-can")}
                                    isInProgress={props.scans.filter(s => s.scanId === record.scanId)[0].isDeleteInProgress}
                                    onConfirm={() => props.deleteScan(record.scanId)} />
                            </>
                        )}/>
                    </Table>
                </Col>
            </Row>
        </GutteredPage>
    );
}

function getScanStatus(scanStatus: ScanStatus) {
    var statusBadge = <Badge status="default" text="Unknown" />;
    switch (scanStatus)
    {
        case ScanStatus.SetUpInProgress:
        case ScanStatus.SetupSucceeded:
        case ScanStatus.ScanInProgress:
            statusBadge = <Badge status="processing" text="In progress" />;
            break;
        case ScanStatus.ScanFinishedSuccess:
            statusBadge = <Badge status="success" text="Success" />;
            break;
        case ScanStatus.ScanFinishedNodeWarning:
            statusBadge = <Badge status="warning" text="Success with warnings" />;
            break;
        case ScanStatus.SetupFailed:
        case ScanStatus.ScanFinishedNodeBootUpFailed:
        case ScanStatus.ScanFinishedNodeError:
        case ScanStatus.ScanFinishedNodeException:
        case ScanStatus.ScanFinishedSystemError:
            statusBadge = <Badge status="error" text="Error" />;
            break;
        case ScanStatus.ScanSkipped:
            statusBadge = <Badge color="#444" text="Skipped" />;
            break;
    }

    return statusBadge;
}

export default connect<ScansPageStateProps, ScansPageDispatchProps, ScansPageOwnProps, IApplicationState>(
    (state: IApplicationState, ownProps: any) => selectScansPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        openViewScanTab: openViewScanTabAction,
        openViewProjectTab: openViewProjectTabAction,
        fetchScanJsonFile: fetchScanJsonFileThunk,
        deleteScan: deleteScanThunk,
    }, dispatch)
)(ScansPage)