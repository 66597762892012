import React from 'react';
import { ElementDisplayProps } from '../../types';
import ansicolor from 'ansicolor';
import TabPane from 'antd/lib/tabs/TabPane';
import { Tabs } from 'antd';
import { TOP_TABS_STYLE } from '../../constants';

export default function RawBlobDisplay(elementDisplayProps: ElementDisplayProps) {
    const data: any = elementDisplayProps.results[0];

    if (elementDisplayProps.results.length < 2) {
        return displayOutput(elementDisplayProps.results[0]);
    } else {
        return (
            <Tabs tabBarStyle={TOP_TABS_STYLE}>
                {
                    elementDisplayProps.results.map((r, idx: number) =>
                        <TabPane tab={<div className="ant-tabs-tab-top">{`Run #${idx + 1}`}</div>} key={idx}>
                            { displayOutput(r) }
                        </TabPane>
                    )
                }
            </Tabs>
        );
    }
}

const displayOutput = (data: any) => {
    const colorized: any = ansicolor.parse(data?.Value ?? "No output");
    return <pre style={{maxHeight: "600px"}}>{colorized.spans.map((i: any) => <span style={getStyleObjectFromString(i.css)}>{i.text}</span>)}</pre>;
}

// Ugly but works. From:
// https://dev.to/qausim/convert-html-inline-styles-to-a-style-object-for-react-components-2cbi
const formatStringToCamelCase = (str: string) => {
    const splitted = str.split("-");
    if (splitted.length === 1) return splitted[0];
    return (
        splitted[0] +
        splitted
            .slice(1)
            .map(word => word[0].toUpperCase() + word.slice(1))
            .join("")
    );
};

const getStyleObjectFromString = (str: string) => {
    const style: any = {};
    str.split(";").forEach(el => {
        const [property, value] = el.split(":");
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};