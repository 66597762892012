import React, { lazy } from 'react';
import { PageType } from '../core/enums';
import NotificationPage from '../pages/admin/NotificationPage';
import NotificationsPage from '../pages/admin/NotificationsPage';
import FlowGraphPage from '../pages/FlowGraphPage';
import ProjectPage from '../pages/ProjectPage';
import ProjectsPage from '../pages/ProjectsPage';
import ScanPage from '../pages/ScanPage';
import ScansPage from '../pages/ScansPage';
import FilesPage from '../pages/FilesPage';
//import EditFilePage from '../pages/EditFilePage';
import ProgrammableNodesPage from '../pages/ProgrammableNodesPage';
import UnknownPage from '../pages/UnknownPage';
import WorkflowPage from '../pages/WorkflowPage';
import WorkflowsPage from '../pages/WorkflowsPage';
import { IPage } from '../types';
import SettingsPage from '../pages/SettingsPage';
import ScanSchedulesPage from '../pages/ScanSchedulesPage';

export default class PageFactory {
  // static loadPage(page: IPage) {
  //   const importView = (page: IPage) =>
  //     lazy(() =>
  //       (!page || !page.pageType)
  //         ? import(`../pages/UnknownPage`)
  //         : import(`../pages/${PageType[page.pageType]}Page`).catch(() =>
  //             import(`../pages/UnknownPage`)
  //           )
  //     );
  //   const PageView = importView(page);
  //   return <PageView tabId={page.tabId}/>; // TODO: Do we need `key={shortid.generate()}`
  // };
  static loadPage(page: IPage) {
    switch(page.pageType) {
      case PageType.Projects: return <ProjectsPage tabId={page.tabId} />
      case PageType.Project: return <ProjectPage tabId={page.tabId} />
      case PageType.Workflows: return <WorkflowsPage tabId={page.tabId} />
      case PageType.Workflow: return <WorkflowPage tabId={page.tabId} />
      case PageType.FlowGraph: return <FlowGraphPage tabId={page.tabId} />
      case PageType.Scans: return <ScansPage tabId={page.tabId} />
      case PageType.Scan: return <ScanPage tabId={page.tabId} />
      case PageType.Files: return <FilesPage tabId={page.tabId} />
      //case PageType.EditFile: return <EditFilePage tabId={page.tabId} />
      case PageType.ScanSchedules: return <ScanSchedulesPage tabId={page.tabId} />
      case PageType.ProgrammableNodes: return <ProgrammableNodesPage tabId={page.tabId} />
      case PageType.Settings: return <SettingsPage tabId={page.tabId} />
      case PageType.Admin_Notifications: return <NotificationsPage tabId={page.tabId} />
      case PageType.Admin_Notification: return <NotificationPage tabId={page.tabId} />
      default: return <UnknownPage />
    }
  }
}