import * as React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicatorProps } from '../types';

export default function LoadingIndicator(props: LoadingIndicatorProps) {
    let size: string;
    let position: string = props.position ?? "Center";

    switch (props.size)
    {
        case "L": size = "30px"; break;
        case "S": size = "14px"; break;
        default: size = props.size ? `${props.size}` : "30px"; break;
    }

    return (
        <div className='loading-indicator' style={{
            marginLeft: position == "Right" || position == "Center" ? "auto" : "0",
            marginRight: position == "Left" || position == "Center" ? "auto" : "0"
        }}>
            <FontAwesomeIcon icon={solid("loader")} spin style={{fontSize: size}} />
            <span style={{display: props.text ? "inline" : "none", fontSize: size}}> {props.text}</span>
        </div>
    );
}