import { createAction } from "@reduxjs/toolkit";
import { communication } from "../../core/communication";
import { PageType } from "../../core/enums";
import { IApplicationState } from "../../types";
import { openTabAction } from "./tabActions";
import * as Names from '../actionNames';
import { UploadFile } from "antd";
import { Key } from "react";
import { slice } from "lodash";

export const loadFilesAction = createAction(Names.loadFilesActionName, (data: any) => ({ payload: data }));
export const loadFileAction = createAction(Names.loadFileActionName, (path: string, fileName: string, data: string | undefined= undefined) => ({ payload: { path: path, fileName: fileName, data: data } }));


export function openViewFilesTabAction() {
    return async (dispatch: any) => {
        await dispatch(openTabAction(PageType.Files));
        await dispatch(fetchFilesThunk());
    }
}

export function openEditFileTabAction(path: string, fileName: string) {
    return async (dispatch: any) => {
        await dispatch(openTabAction(PageType.EditFile));
        await dispatch(fetchFileContentsThunk(path, fileName))
    }
}

export function fetchFilesThunk() {
    return async (dispatch: any) => {
        const response = await communication.get(`/api/v1/getAllFiles`);
        if (response.isSuccessful) {
            await dispatch(loadFilesAction(response.result));
        }
    }
}

export function fetchFileContentsThunk(path: string, fileName: string) {
    return async (dispatch: any) => {
        if (fileName) {
            const response = await communication.post(`/api/v1/getFileData`, `${path}/${fileName}`);
            if (response.isSuccessful) {
                await dispatch(loadFileAction(path, fileName, response.result));
            }
        } else {
            await dispatch(loadFileAction(path, fileName, ""));
        }
    }
}

export function deleteFilesThunk(paths: Key[]) {
    return async (dispatch: any) => {
        const response = await communication.post(`/api/v1/deleteFilesAndDirectories`, paths);
        if (response.isSuccessful) {
            await dispatch(fetchFilesThunk());
        }
    }
}

export function fetchZippedFileOrDirThunk(paths: Key[]) {
    return async () => {
        await communication.getFile("/api/v1/downloadFiles", "files.zip", "POST", paths);
    }
}
