import { FilesPageStateProps } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function filePageInitializer(payload: any): FilesPageStateProps {
    return {
        files: [],
        fileSizes: {},
        maxSizeBytes: 0,
        usedSizeBytes: 0,
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("folder-tree"),
        tabText: "Filesystem",
        params: []
    };
}