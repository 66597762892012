import { Pagination } from 'antd';
import React, { useState } from 'react';
import { NodeBlueprintExitType } from '../core/enums';
import { IApplicationState, ScanDisplayProps, ScanNodeResultDisplayDispatchProps, ScanNdeResultDisplayProps, ScanNodeResultDisplayStateProps, ScanNodeResult } from '../types';
import DefaultScanDisplay from './scanDisplays/DefaultNodeDisplay';
import FileDisplay from './scanDisplays/FileDisplay';
import ImageDisplay from './scanDisplays/ImageDisplay';
import RawBlobDisplay from './scanDisplays/RawBlobDisplay';
import { SCAN_EXIT_DATA_PAGE_SIZE } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refreshScanNodeResultThunk } from '../store/actions/scanActions';
import { selectScanNodeExitChunk } from '../store/selectors';
import BasicDisplay from './scanDisplays/BasicDisplay';

function ScanNodeResultDisplay(props: ScanNdeResultDisplayProps) {

    const outputSelector = (scanNodeResult: ScanNodeResult, data: Array<any>, isEmpty: boolean) => {
        data = data.map(d => JSON.parse(d))
        if (!isEmpty && (!data || data.length == 0)) {
            return <FontAwesomeIcon icon={solid("loader")} spin className='page-loading' style={{ margin: "50px auto"}} />
        }
        switch (scanNodeResult.exitType) {
            // TODO: Implement this
            // case NodeBlueprintExitType.Nmap_HostsAll:
            // case NodeBlueprintExitType.Nmap_HostsUp:
            // case NodeBlueprintExitType.Nmap_HostsDown:
            //     return <NmapNodeDisplay scanNodeResult={scanNodeResult} />;
            case NodeBlueprintExitType.EyeWitness_Screenshots:
            case NodeBlueprintExitType.Aquatone_Screenshots:
                return <ImageDisplay results={data} />;
            case NodeBlueprintExitType.EyeWitness_SourceFiles:
            case NodeBlueprintExitType.Aquatone_SourceFiles:
                return <FileDisplay results={data} />;
            case NodeBlueprintExitType.Raw_Blob:
                return <RawBlobDisplay results={data} />;
        }
        if (scanNodeResult.exitType.endsWith("_RawBlob") || scanNodeResult.exitType.endsWith("_RawOutput")) {
            return <RawBlobDisplay results={data} />;
        }
        if (data.length) {
            switch (data[0].DataContract.DataType) {
                case "File": return <FileDisplay results={data} />;
                // case "Bool": return <BasicDisplay results={data} key="Value" generator={(v) => v["Value"]} />
                // case "Int": return <BasicDisplay results={data} key="Value" generator={(v) => v["Value"]} />
                // case "Decimal": return <BasicDisplay results={data} key="Value" generator={(v) => v["Value"]} />
                // case "String": return <BasicDisplay results={data} key="Value" generator={(v) => v["Value"]} />
                // case "LongString": return <BasicDisplay results={data} key="Value" generator={(v) => v["Value"]} />
                // case "ASN": return <BasicDisplay results={data} key="ASN" generator={(v) => v["ASN"]} />
                // case "CIDR": return <BasicDisplay results={data} key="CIDR" generator={(v) => v["CIDR"]} />
                // case "CIDRv4": return <BasicDisplay results={data} key="CIDRv4" generator={(v) => v["CIDRv4"]} />
                // case "CIDRv6": return <BasicDisplay results={data} key="CIDRv6" generator={(v) => v["CIDRv6"]} />
                // case "IP": return <BasicDisplay results={data} key="IP" generator={(v) => v["IP"]} />
                // case "IPWithPort": return <BasicDisplay results={data} generator={(v) => `{${v["IP"]}:${v["Port"]}}`} />
                // case "IPv4": return <BasicDisplay results={data} key="IPv4" generator={(v) => v["IPv4"]} />
                // case "IPv4WithPort": return <BasicDisplay results={data}  generator={(v) => `{${v["IPv4"]}:${v["Port"]}}`} />
                // case "IPv6": return <BasicDisplay results={data} key="IPv6" generator={(v) => v["IPv6"]} />
                // case "IPv6WithPort": return <BasicDisplay results={data}  generator={(v) => `{${v["IPv6"]}:${v["Port"]}}`} />
                // case "Port": return <BasicDisplay results={data} key="Port" generator={(v) => v["Port"]} />
                // case "Domain": return <BasicDisplay results={data} key="Domain" generator={(v) => v["Domain"]} />
                // case "Url": return <BasicDisplay results={data} key="FullUrl" generator={(v) => v["FullUrl"]} />
            }
        }
        return <DefaultScanDisplay results={data} />;
    }

    const [pageNo, setPageNo] = useState(1);

    // const result: any = !scanDisplayProps.scanNodeResult.isList && scanDisplayProps.scanNodeResult.results.length > 0
    //     ? scanDisplayProps.scanNodeResult.results[0]
    //     : scanDisplayProps.scanNodeResult.results;

    const isEmpty: boolean = props.scanNodeResult.resultsCount == 0;
    // if (props.scanNodeResult.isList && props.scanNodeResult.resultsCount > SCAN_EXIT_DATA_PAGE_SIZE) {
        const data = props.scanNodeResult.results[pageNo - 1];
        return (
            <div className='scan-node-result'>
                <div>{outputSelector(props.scanNodeResult, data || [], isEmpty)}</div>
                <Pagination
                    defaultCurrent={pageNo}
                    defaultPageSize={SCAN_EXIT_DATA_PAGE_SIZE}
                    total={props.scanNodeResult.resultsCount}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={(pageNo, pageSize) => { props.refreshScanNodeResult(pageNo - 1, props.scanId, props.scanNodeId, props.scanNodeResult.exitType); setPageNo(pageNo); }} />
            </div>
        );
    // } else {
    //     return (<div className='scan-node-result'><pre style={{margin: "0"}}>{outputSelector(props.scanNodeResult.exitType, props.scanNodeResult.results[0] || [], isEmpty)}</pre></div>);
    // }
}

export default connect<ScanNodeResultDisplayStateProps, ScanNodeResultDisplayDispatchProps, ScanDisplayProps, IApplicationState>(
    (state, ownProps) => selectScanNodeExitChunk(state, ownProps.scanId, ownProps.scanNodeId, ownProps.scanNodeResult.exitType),
    dispatch => bindActionCreators({
        refreshScanNodeResult: refreshScanNodeResultThunk
    }, dispatch)
)(ScanNodeResultDisplay)