import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popconfirm } from 'antd';
import * as React from 'react';
import { DangerousConfirmButtonProps } from '../../types';

export default function DangerousConfirmButton(props: React.PropsWithChildren<DangerousConfirmButtonProps>) {
    const [visible, setVisible] = React.useState(false);

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }
        setVisible(false);
    };

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
        setVisible(false);
    };

    return (
        <Popconfirm
            title={props.popupText}
            visible={visible}
            onConfirm={handleOk}
            onCancel={handleCancel}>
            <Button
                onClick={() => {showPopconfirm()}}
                type='primary'
                icon={<FontAwesomeIcon icon={props.icon} />}
                loading={props.isInProgress}
                disabled={props.disabled}
                block={props.block}
                danger>
                {props.children}
            </Button>
      </Popconfirm>
    );
  };