import { createAction } from "@reduxjs/toolkit";
import * as Names from '../actionNames';
import { communication } from "../../core/communication";

// Simple Actions
export const loadDataTagsAction = createAction(Names.loadDataTagsActionName, (tags: string[]) => ({ payload: { tags: tags }}));
export const pushNewTagAction = createAction(Names.pushNewTagActionName, (tag: string) => ({ payload: { tag: tag }}));

// Thunks

export function fetchDataTagsThunk(): any {
    return async (dispatch: any) => {
        const response = await communication.get("/api/v1/tags/dataTags");
        dispatch(loadDataTagsAction(response.result.allDataTags));
    }
}