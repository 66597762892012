import * as React from 'react';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import GenerateGeneralEditor from '../components/settingsEditors/GeneralEditor';
import GenerateSettingsEditor from '../components/settingsEditors/SettingsEditor';
import LoadingIndicator from '../components/LoadingIndicator';
import { ISettingEditorData, SettingSection, SettingsPageProps } from '../types';
import { SettingsDisplay } from '../components/SettingsDisplay';
import { SettingCoverageType } from '../core/enums';
import { useSelector } from 'react-redux';
import { selectOrganizationId, selectOrganizationSettingSections } from '../store/slices/settingsSlice';
import { RootState } from '../store/configureAppStore';

export default function SettingsPage(props: SettingsPageProps) {

    const organizationId = useSelector<RootState, string>((state) => selectOrganizationId(state))
    const settingSections = useSelector<RootState, SettingSection[]>((state) => selectOrganizationSettingSections(state))

    if (!settingSections || settingSections.length == 0) {
        return (
            <GutteredPage><LoadingIndicator /></GutteredPage>
        );
    }

    const settingEditorData: ISettingEditorData = {
        coverageType: SettingCoverageType.Organization,
        entityId: organizationId,
    }

    return (
        <GutteredPage>
            <SettingsDisplay tabs={[
                GenerateGeneralEditor(),
                GenerateSettingsEditor(settingSections[0], settingEditorData),
                GenerateSettingsEditor(settingSections[1], settingEditorData)
            ]} />
        </GutteredPage>
    );
}
