import { arrayDelete, arrayUpdate, arrayUpdateAll, arrayUpdateCb, dictDelete, dictUpdateByKey, toDictionary } from "../../core/reduxHelpers";
import { IResponseState, Scan, ScanPageState, Scans } from "../../types";
import * as Names from "../actionNames";

const defaultState: Scans = {
    scans: {},
    scanSchedules: [],
}

function scanReducer(state: Scans = defaultState, action: any): Scans {
    switch (action.type) {

        // Loading a single workflow from server is finished - upsert the workflow object
        // This is usually a more detailed load, containing description and seed resources.
        case Names.loadScanActionName:
            return action.payload.response.isSuccessful
                ? loadScan(state, action.payload.response)
                : state;

        // Loading multiple scans from server is finished - upsert the scan object
        // This is usually a simplified data version. Each Scan object contains only data needed for table row output.
        case Names.loadScansActionName:
            return action.payload?.response?.isSuccessful
                ? loadScans(state, action.payload.response)
                : state;

        case Names.loadScanSchedulesActionName:
            return loadScanSchedulesAction(state, action.payload);

        case Names.removeScanFromListActionName:
            return removeScanFromList(state, action.payload.scanId);

        case Names.removeScanScheduleFromListActionName:
            return removeScanScheduleFromList(state, action.payload.scanScheduleId);

        case Names.scanScheduleDeleteInProgressActionName:
            return scanScheduleDeleteInProgress(state, action.payload.scanScheduleId);

        case Names.refreshScanWithNodeActionName:
            return refreshScanWithNode(state, action.payload.scanId, action.payload.scanNodeId, action.payload.response)

        case Names.refreshScanWithNodeExitActionName:
            return refreshScanWithNodeExit(state, action.payload.pageIdx, action.payload.scanId, action.payload.scanNodeId, action.payload.exitTypeId, action.payload.data)

        default:
            return state;
    }
}

function scanPageReducer(state: ScanPageState, action: any): ScanPageState {
    switch (action.type) {
        case Names.updateScanPageDataActionName:
            return {
                ...state,
                tabText: `${action.payload.scan.scanName}` ?? "No name",
                scanId: action.payload.scan.scanId ?? state.scanId,
                scan: { ...state.scan, ...action.payload.scan }
            };
        default:
            return state;
    }
}

export {
    scanReducer,
    scanPageReducer
}

function loadScan(state: Scans, response: IResponseState<Scan>): Scans {
    const loadedScan = response.result;
    return {
        ...state,
        scans: dictUpdateByKey(state.scans, loadedScan.scanId, {
            ...loadedScan,
            isSaveInProgress: false,
            isDeleteInProgress: false
        })
    }
}

function loadScans(state: Scans, response: any): Scans {
    const p = arrayUpdateAll(response.result?.scans ?? [], { isDeleteInProgress: false });
    return {
        ...state,
        scans: {
            ...toDictionary(p, (el: any) => el.scanId)
        }
    };
}

function removeScanFromList(state: Scans, scanId: any): Scans {
    return {
        ...state,
        scans: {
            ...dictDelete(state.scans, scanId)
        }
    };

    //return { ...dictDelete(state, scanId) }
}

function refreshScanWithNode(state: Scans, scanId: string, scanNodeId: string, response: any) {
    var scan = { ...response };
    var scanNode = { ...response.scanNodes[scanNodeId] };

    delete scan.scanNodes;

    return {
        ...state,
        scans: {
            ...dictUpdateByKey(state.scans, scanId, {
                ...state.scans[scanId],
                ...scan,
                scanNodes: dictUpdateByKey(state.scans[scan.scanId]?.scanNodes || {}, scanNodeId, {
                    ...scanNode
                })
            })
        }
    };
}

function refreshScanWithNodeExit(state: Scans, pageIdx: number, scanId: string, scanNodeId: string, exitTypeId: string, data: any) {
    return {
        ...state,
        scans: {
            ...dictUpdateByKey(state.scans, scanId, {
                ...state.scans[scanId],
                scanNodes: dictUpdateByKey(state.scans[scanId]?.scanNodes || {}, scanNodeId, {
                    ...state.scans[scanId]?.scanNodes[scanNodeId],
                    scanNodeResults: arrayUpdateCb(state.scans[scanId]?.scanNodes[scanNodeId]?.scanNodeResults ?? [], i => i.exitType == exitTypeId, elt => ({
                        ...elt,
                        selectedPage: pageIdx,
                        results: { ...elt.results, [pageIdx]: [ ...data.results[pageIdx] ] }
                    }))
                })
            })
        }
    };
}

function loadScanSchedulesAction(state: Scans, payload: any): Scans {
    return {
        ...state,
        scanSchedules: [ ...payload.response.result.scanSchedules ]
    };
}

function scanScheduleDeleteInProgress(state: Scans, scanScheduleId: any): Scans {
    return {
        ...state,
        scanSchedules: [ ...arrayUpdate(state.scanSchedules, ss => ss.id == scanScheduleId, { isDeleteInProgress: true }) ]
    }
}

function removeScanScheduleFromList(state: Scans, scanScheduleId: any): Scans {
    return {
        ...state,
        scanSchedules: [ ...arrayDelete(state.scanSchedules, ss => ss.id == scanScheduleId) ]
    }
}