import React from 'react'
import { Col, Row } from 'antd';
import CenteredComponentPage from '../../components/pageInterfaces/CenteredComponentPage';

export default function LoginUnknownErrorPage() {
    return (
        <CenteredComponentPage>
            <Row className="login-form">
                <Col>
                    <img src="ScourNomadLogo.png" />
                    <h1>ScourNomad</h1>
                    <h3>Unknown general error</h3>
                </Col>
            </Row>
        </CenteredComponentPage>
    );
}
