import { result } from "lodash";
import { Files, File } from "../../types";
import { toDictionary } from "../../core/reduxHelpers";
import * as Names from "../actionNames";

const defaultState: Files = {
    files: [],
    fileSizes: {},
    usedSizeBytes: 0,
    maxSizeBytes: 0,
}

function filesReducer(state: Files = defaultState, action: any): Files {
    switch (action.type) {
        case Names.loadFilesActionName:
            return loadFiles(state, action.payload);
        default:
            return state;
    }
}

export {
    filesReducer
}

function loadFiles(state: Files, payload: any): Files {
    return {
        ...state,
        files: [ ...payload.files ],
        fileSizes: generateFileSizesDict(payload.files),
        usedSizeBytes: payload.usedSizeBytes,
        maxSizeBytes: payload.maxSizeBytes,
    }
}

function generateFileSizesDict(files: File[]) {
    let objects: File[] = [ ...files ];
    let idx = 0;
    while (idx < objects.length) {
        objects.push(...objects[idx].children);
        idx++;
    }

    return toDictionary(objects, obj => obj.key, obj => obj.size);
}
