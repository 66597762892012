import { LoginScreenType } from "../../core/enums";
import { arrayUpdate } from "../../core/reduxHelpers";
import { IAuthState } from "../../types";
import * as Names from "../actionNames";

const defaultState: IAuthState = {
    loginScreen: LoginScreenType.ValidatingLogin,
    accessToken: null,
    errorMessage: null,
    sessionId: null,
    claims: {
        fullName: "<unknown>",
        userName: "<unknown>",
        email: "<unknown>",
        emailVerified: false,
    },
    features: [],
    notifications: [],
    memberOf: [],
}

export default function authReducer(state: IAuthState = defaultState, action: any): IAuthState {
    switch (action.type) {
        case Names.updateAuthDataActionName:
            return updateAuthData(state, action.payload.authData);
        case Names.updateClaimsActionName:
            return updateClaims(state, action.payload.authData);
        case Names.updateFeaturesActionName:
            return updateFeatures(state, action.payload.features);
        case Names.updateNotificationsActionName:
            return updateNotifications(state, action.payload.notifications);
        case Names.markNotificationSeenActionName:
            return markNotificationSeen(state, action.payload.notificationId);
        case Names.changeNotificationDisplayActionName:
            return changeNotificationDisplay(state, action.payload.notificationId, action.payload.visible);
        default:
            return state;
    }
}

function updateAuthData(state: IAuthState, payload: any): IAuthState {
    return {
        ...state,
        loginScreen: payload.nextScreen,
        accessToken: payload.accessToken,
        sessionId: payload.sessionID,
        errorMessage: payload.errorMessage,
        memberOf: payload.memberOf,
    }
}

function updateClaims(state: IAuthState, authData: any): IAuthState {
    return {
        ...state,
        claims: {
            userName: authData.userName,
            fullName: authData.fullName,
            email: authData.email,
            emailVerified: authData.emailVerified,
        }
    }
}

function updateFeatures(state: IAuthState, features: any): IAuthState {
    return {
        ...state,
        features: features
    }
}

function updateNotifications(state: IAuthState, notifications: any): IAuthState {
    return {
        ...state,
        notifications: notifications
    }
}

function markNotificationSeen(state: IAuthState, notificationId: string) {
    return {
        ...state,
        notifications: arrayUpdate(state.notifications || [], n => n.id === notificationId, { isSeen: true })
    }
}

function changeNotificationDisplay(state: IAuthState, notificationId: string, visible: boolean): IAuthState {
    return {
        ...state,
        notifications: arrayUpdate(state.notifications || [], n => n.id === notificationId, { shouldDisplay : visible })
    }
}
