import { message } from "antd";
import { DEFAULT_ERROR_MESSAGE_DURATION, DEFAULT_WARNING_MESSAGE_DURATION } from "../../constants";
import { MessageType } from "../../enums";

export const messagingMiddleware = (store: any) => (next: any) => (action: any) => {
    // Handles any action with "message" and "messageType" property set
    // TODO: Moved to communication.ts, remove this if not needed
    // if (action?.payload?.response?.message && action?.payload?.response?.messageType) {
    //     switch(action.payload.response.messageType) {
    //         case MessageType.Info:
    //             message.info(action.payload.response.message);
    //             break;
    //         case MessageType.Success:
    //             message.success(action.payload.response.message);
    //             break;
    //         case MessageType.Warning:
    //             message.warning(action.payload.response.message, DEFAULT_WARNING_MESSAGE_DURATION);
    //             break;
    //         case MessageType.Error:
    //             message.error(action.payload.response.message, DEFAULT_ERROR_MESSAGE_DURATION);
    //             break;
    //     }
    // }
    next(action);
}