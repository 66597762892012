import { IPage, ScanSchedulesPageStateProps } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function scanSchedulesPageInitializer(payload: IPage): ScanSchedulesPageStateProps {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("calendar-days"),
        tabText: "Scheduled scans",
        scanSchedules: [],
        params: [],
    };
}