// TODO: Move this to core

// TODO: see to move this into union perhaps: https://stackoverflow.com/a/60041791/2413587
export enum MessageType {
    NoMessage,
    Info,
    Success,
    Warning,
    Error
}
