import { NotificationPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function notificationPageInitializer(payload: any): NotificationPageState {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("bell-on"),
        tabText: "Notification",
        notification: null,
        params: []
    };
}