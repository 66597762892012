import { communication } from "../../core/communication";
import { PageType, SettingCoverageType } from "../../core/enums";
import { createSliceWithThunks } from "../../core/reduxCore";
import { SettingSection, Settings } from "../../types";
import { openTabAction } from "../actions/tabActions";
import { AppDispatch, RootState } from "../configureAppStore";
import { NEW_GUID } from "../../constants";

const initialState: Settings = {
    settingSections: {}
}

//
// Selectors
//

export const selectOrganizationId = (state: RootState): string =>
    state.init.organizationId;

export const selectOrganizationSettingSections = (state: RootState): SettingSection[] =>
    state.settings.settingSections[`${SettingCoverageType.Organization}-${state.init.organizationId}`];

//
// Slice
//

const settingsSlice = createSliceWithThunks({
    name: "settings",
    initialState: initialState,
    reducers: (create) => ({

        //
        // Reducers
        //


        //
        // Thunks
        //
        openViewOrganizationSettingsTab: create.asyncThunk(
            async (_, api) => {
                const dispatch = api.dispatch as AppDispatch;
                const state = api.getState() as RootState;

                dispatch(openTabAction(PageType.Settings));
                dispatch(refreshSettings({ coverageType: SettingCoverageType.Organization, entityId: state.init.organizationId }));
            }
        ),
        refreshSettings: create.asyncThunk(
            async ({ coverageType, entityId }, api) => {
                const response = await communication.get(`/api/v1/settings/${coverageType}/${entityId}`);
                return { settingSections: response.result.settingSections, coverageType, entityId };
            },
            {
                pending: (state, action) => {
                },
                rejected: (state, action) => {
                },
                fulfilled: (state, action) => {
                    const key = `${action.payload.coverageType}-${action.payload.entityId || NEW_GUID}`;
                    state.settingSections[key] = action.payload.settingSections;
                },
            }
        ),
        storeSettings: create.asyncThunk(
            async ({ settings, coverageType, entityId }, api) => {
                const settingsResult = Object.entries(settings).map((s: any) => ({
                    key: s[0],
                    value: JSON.stringify({ value: s[1] }) }));
                const response = await communication.post(`/api/v1/settings/${coverageType}/${entityId}/store`, settingsResult);
                return { settingSections: response.result.settingSections, coverageType, entityId };
            },
            {
                pending: (state, action) => {
                },
                rejected: (state, action) => {
                },
                fulfilled: (state, action) => {
                    const key = `${action.payload.coverageType}-${action.payload.entityId || NEW_GUID}`;
                    state.settingSections[key] = action.payload.settingSections;
                },
            }
        ),
        initiateOAuthFlow: create.asyncThunk(
            async ({ coverageType, entityId, settingKey, oAuthType }) => {
                return await communication.get(`/api/v1/settings/${coverageType}/${entityId}/${settingKey}/${oAuthType}/initiate-oauth`);
            },
            {
                fulfilled: (state, action) => {
                    window.open(action.payload.result.url, "_blank");
                },
            }
        )
    })
});


export const {
    // Reducers

    // Thunks
    openViewOrganizationSettingsTab,
    refreshSettings,
    storeSettings,
    initiateOAuthFlow,
} = settingsSlice.actions

export default settingsSlice;