import { createAction } from "@reduxjs/toolkit";
import { communication } from "../../core/communication";
import { PageType, SettingCoverageType } from '../../core/enums';
import { IPage, Project, StoreCallback } from "../../types";
import * as Names from '../actionNames';

import { openTabAction, pageFinishedLoadingAction, updateProjectPageDataAction } from "./tabActions";
import { fetchWorkflows } from "../slices/workflowsSlice";
import { refreshSettings } from "../slices/settingsSlice";

// Simple Actions
export const loadProjectsAction = createAction(Names.loadProjectsActionName, (data: any) => ({ payload: { response: data }}));
export const loadProjectAction = createAction(Names.loadProjectActionName, (data: any, isEdit: boolean) => ({ payload: { response: data, isEdit: isEdit }}));
export const projectUpdateInProgressAction = createAction(Names.projectUpdateInProgressActionName, (tabId: number, projectId: string) => ({ payload: { tabId: tabId, projectId: projectId }}));
export const projectUpdateFinishedAction = createAction(Names.projectUpdateFinishedActionName, (tabId: number, projectId: string, response: IPage, isCreateMode: boolean) => ({ payload: { tabId: tabId, projectId: projectId, response: response, isCreateMode: isCreateMode }}));
export const projectDeleteInProgressAction = createAction(Names.projectDeleteInProgressActionName, (projectId: string) => ({ payload: { projectId: projectId }}))
export const projectDeleteFinishedAction = createAction(Names.projectDeleteFinishedActionName, (projectId: string, response: {}) => ({ payload: { projectId: projectId, response: response }}));

// Complex Actions
export function openViewProjectsTabAction() {
    return async (dispatch: any) => {
        dispatch(fetchWorkflows(null));
        dispatch(openTabAction(PageType.Projects));
        dispatch(fetchProjectsThunk());
    }
}

export function openCreateProjectTabAction() {
    return async (dispatch: any, store: StoreCallback) => {
        dispatch(openTabAction(PageType.Project, { isCreateMode: true }, true));
    }
}

export function openEditProjectTabAction(projectId: string) {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.Project, { projectId: projectId, isEditMode: true }));
        dispatch(fetchProjectThunk(projectId, true));
    }
}

export function openViewProjectTabAction(projectId: string) {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.Project, { projectId: projectId }));
        dispatch(fetchProjectThunk(projectId, false));
    }
}


// Thunks
export function fetchProjectsThunk(tabId?: number): any {
    return async (dispatch: any, store: StoreCallback) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        const response = await communication.get("/api/v1/projects");
        dispatch(loadProjectsAction(response));
        dispatch(pageFinishedLoadingAction(actualTabId));
    }
}

export function fetchProjectThunk(projectId: string, isEdit: boolean, tabId?: number): any {
    return async (dispatch: any, store: StoreCallback) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        const response = await communication.get(`/api/v1/project/${projectId}`);
        dispatch(loadProjectAction(response, isEdit));
        dispatch(refreshSettings({ coverageType: SettingCoverageType.Project, entityId: projectId }));
        dispatch(updateProjectPageDataAction(actualTabId, response.result));
        dispatch(pageFinishedLoadingAction(actualTabId));
    }
}

export function createProjectThunk(project: Project, tabId: number): any {
    return async (dispatch: any) => {
        dispatch(updateProjectPageDataAction(tabId, { isSaveInProgress: true }));
        const response = await communication.post("/api/v1/project/create", project);
        dispatch(projectUpdateFinishedAction(tabId, project.projectId, response, true));
        if (response.isSuccessful) {
            dispatch(updateProjectPageDataAction(tabId, { ...response.result, isCreateMode: false, isSaveInProgress: false }));
        } else {
            dispatch(updateProjectPageDataAction(tabId, { isSaveInProgress: false }));
        }
    }
}

export function updateProjectThunk(project: Project, tabId: number): any {
    return async (dispatch: any) => {
        dispatch(projectUpdateInProgressAction(tabId, project.projectId));
        const response = await communication.post("/api/v1/project/update", project);
        dispatch(projectUpdateFinishedAction(tabId, project.projectId, response, false));
    }
}

export function deleteProjectThunk(projectId: string): any {
    return async (dispatch: any) => {
        dispatch(projectDeleteInProgressAction(projectId));
        const response = await communication.post(`/api/v1/project/${projectId}/delete`);
        dispatch(projectDeleteFinishedAction(projectId, response));
    }
}
