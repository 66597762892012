import { message } from "antd";
import { createAction } from "@reduxjs/toolkit";
import { communication } from "../../core/communication";
import { PageType } from "../../core/enums";
import { closeTabAction, openTabAction } from "./tabActions";
import * as Names from '../actionNames';
import { NotificationViewModel, StoreCallback, UsersAndGroupsPageState } from "../../types";
import { single } from "../../core/reduxHelpers";
import { selectCurrentPage, selectPageByTabId } from "../selectors";

export const loadNotificationsAction = createAction(Names.loadNotificationsActionName, (data: any) => ({ payload: data }));
export const loadUsersAndGroupsAction = createAction(Names.loadUsersAndGroupsActionName, (data: any) => ({ payload: data }));
export const createNotificationAction = createAction(Names.createNotificationActionName, (data: any) => ({ payload: data }));
export const editNotificationAction = createAction(Names.editNotificationActionName, (data: any) => ({ payload: data }));
export const updateNotificationInListAction = createAction(Names.updateNotificationInListActionName, (data: NotificationViewModel) => ({ payload: data }));
export const removeNotificationFromListAction = createAction(Names.removeNotificationFromListActionName, (notificationId: string) => ({ payload: { notificationId: notificationId } }));
export const updateGroupsForUserAction = createAction(Names.updateGroupsForUserActionName, (tabId: number, userId: string, groupIds: string[]) => ({ payload: { tabId: tabId, userId: userId, groupIds: groupIds } }));

// ================== Notifications ==================

// Complex Actions
export function openNotificationsTabAction() {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.Admin_Notifications));
        dispatch(fetchNotificationsThunk());
    }
}

export function createNotificationTabAction() {
    return async (dispatch: any, store: StoreCallback) => {
        dispatch(openTabAction(PageType.Admin_Notification));
        dispatch(createNotificationAction({ tabId: store().pages.selectedPageId }));
    }
}

export function editNotificationTabAction(notificationId: string) {
    return async (dispatch: any, store: StoreCallback) => {
        const notification = single(store().admin.notifications || [], n => n.id == notificationId);
        dispatch(openTabAction(PageType.Admin_Notification));
        dispatch(editNotificationAction({ tabId: store().pages.selectedPageId, notification: notification }));
    }
}

// Thunks

// Notifications

export function fetchNotificationsThunk(): any {
    return async (dispatch: any) => {
        const response = await communication.get("/api/v1/admin/notifications");
        const resultResponse = response.result;
        dispatch(loadNotificationsAction(resultResponse));
    }
}

export function storeNotificationThunk(notificaiton: NotificationViewModel): any {
    return async (dispatch: any, store: StoreCallback) => {
        message.loading("Saving notification...");
        await communication
            .post("/api/v1/admin/notification/store", notificaiton)
            .then(r => r.isSuccessful && (() => {
                dispatch(updateNotificationInListAction(r.result))
                dispatch(closeTabAction(store().pages.selectedPageId))
            })());
    }
}

export function deleteNotificationThunk(notificationId: string): any {
    return async (dispatch: any) => {
        message.loading("Deleting notification...");
        await communication
            .post(`/api/v1/admin/notification/${notificationId}/delete/`)
            .then(r => r.isSuccessful && dispatch(removeNotificationFromListAction(notificationId)));
    }
}
