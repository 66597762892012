import { message } from "antd";
import { communication } from "../../core/communication";
import { HubType } from "../../core/enums";
import { MessageType } from "../../enums";
import { fetchScanThunk, refreshScanNodeThunk } from "../actions/scanActions";
import { DEFAULT_ERROR_MESSAGE_DURATION, DEFAULT_WARNING_MESSAGE_DURATION } from "../../constants";
import { refreshSettingsAction } from "../actions/settingsActions";

export default function initNotificationsHub() {
    communication
        .addHub(HubType.ScanHub, "notifications/")
        .addCallback("Notify", (store, dataJson) => {
            var data = JSON.parse(dataJson);
            switch (data.MessageType) {
                case MessageType.Info:
                    message.info(data.Message);
                    break;
                case MessageType.Success:
                    message.success(data.Message);
                    break;
                case MessageType.Warning:
                    message.warning(data.Message, DEFAULT_WARNING_MESSAGE_DURATION);
                    break;
                case MessageType.Error:
                    message.error(data.Message, DEFAULT_ERROR_MESSAGE_DURATION);
                    break;
            }
        })
        .addCallback("ScanUpdated", (store, dataJson) => {
            // We never want to clear the "Is new?" flag from the scan when calling from here - it is only
            // to create the new scan object in the scans list, it needs to stay new.
            var data = JSON.parse(dataJson);
            store.dispatch(fetchScanThunk(data.ScanId, false, -1));
        })
        .addCallback("ScanNodeUpdated", (store, dataJson) => {
            var data = JSON.parse(dataJson);
            store.dispatch(refreshScanNodeThunk(data.ScanId, data.ScanNodeId));
        })
        .addCallback("SettingUpdated", (store, data) => {
            store
                .dispatch(refreshSettingsAction(data.coverageType, data.entityId))
                .then(() => {
                    message.success("OAuth synchronization finished.");
                });
        })
        .intiateConnection();
}