import React, { Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import MenuButton from './ui/MenuButton';
import { closeTabAction, selectTabAction } from '../store/actions/tabActions';
import PageFactory from '../factories/PageFactory';

import { IApplicationState, IPage, IPagesState, TabBarDispatchProps, TabBarProps, TabBarStateProps } from '../types';
import Paragraph from 'antd/lib/typography/Paragraph';

// TODO: middle click closes tabs
// TODO: drag over tabs hilights them as drop targets
// TODO: Tab bar can scroll a little downwards even if it doesn't need to - when there is a single tab. Just hover over it and scroll down, three dots button will appear.
// TODO: Three dots button styling is incorrect.

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    'data-node-key': string;
}

const DraggableTabNode = ({ className, ...props }: DraggableTabPaneProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props['data-node-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1 }),
        transition,
        cursor: 'move',
    };

    return React.cloneElement(props.children as React.ReactElement, {
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners,
    });
};

const TabBar = function (props: TabBarProps) {
    const tabContents = (tab: IPage) => {
        // TODO: Check this answer for dynamically checking  if ellipsis is on: https://stackoverflow.com/a/63214232/2413587
        return (
            <div onMouseDown={(e) => { if (e.button === 1) { props.closeTab(tab.tabId) } }} onClick={(e) => props.selectTab(tab.tabId)}>
                <div className="tab-controls">
                    {/* TODO: This is the refresh tab button. So far no uses. */}
                    {/* <MenuButton icon={solid("rotate")} size="15px" /> */}
                    <MenuButton onClick={() => props.closeTab(tab.tabId)} icon={solid("xmark")} size="17px" />
                </div>
                <Paragraph ellipsis={{ tooltip: tab.tabText }}>
                    {
                        tab.tabIcon
                            ? <FontAwesomeIcon className="tab-icon" icon={tab.tabIcon} />
                            : <></>
                    } {tab.tabText}
                </Paragraph>
            </div>
        );
    }

    // const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

    return (
        <Tabs
            defaultActiveKey="0"
            tabPosition="left"
            activeKey={`${props.selectedPageId}`}
            className="left-tab-bar"
            items={props.tabs.map(tab => {
                const page = PageFactory.loadPage(tab);
                return ({
                    key: `${tab.tabId}`,
                    label: tabContents(tab),
                    children: (
                        <Suspense fallback="Loading...">
                            {/* TODO: Add something prettier for loading fallback */}
                            {page}
                        </Suspense>)
                });
            })}
            renderTabBar={(tabBarProps, DefaultTabBar) => (
                // <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                //    <SortableContext items={items.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
                        <DefaultTabBar {...tabBarProps} className='left-tab-bar-header'>
                            {(node) => (
                                //<DraggableTabNode {...node.props} key={node.key}>
                                    <>{node}</>
                                //</DraggableTabNode>
                            )}
                        </DefaultTabBar>
                //    </SortableContext>
                // </DndContext>
            )} />
        // <Tabs
        //     defaultActiveKey="0" tabPosition="left" activeKey={`${props.selectedPageId}`}
        //     items={items}
        //     renderTabBar={(tabBarProps, DefaultTabBar) => (
        //         <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        //             <SortableContext items={items.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
        //                 <DefaultTabBar {...tabBarProps}>
        //                     {(node) => (
        //                         <DraggableTabNode {...node.props} key={node.key}>
        //                             {node}
        //                         </DraggableTabNode>
        //                     )}
        //                 </DefaultTabBar>
        //             </SortableContext>
        //         </DndContext>
        //     )} />
    );
}


export default connect<TabBarStateProps, TabBarDispatchProps, {}, IApplicationState>(
    state => state.pages,
    dispatch => bindActionCreators({
        closeTab: closeTabAction,
        selectTab: selectTabAction,
    }, dispatch)
)(TabBar);