import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButtonProps } from "../../types";

export function IconButton(props: IconButtonProps) {
    const opacityStyle = props.disabled ? {opacity: "0.5"} : {};
    const pointerStyle = props.disabled ? {cursor: "not-allowed"} : {cursor: "pointer"}
    return <FontAwesomeIcon
        icon={props.icon}
        onClick={props.disabled ? () => {} : props.onClick}
        style={{...opacityStyle, ...pointerStyle, padding: "8px", verticalAlign: "middle", ...props.style}} />
}