import { combineReducers } from 'redux';
//import { connectRouter } from 'connected-react-router';
import pageReducer from './pageReducer';
import { projectReducer } from './projectReducer';
import { scanReducer } from './scanReducer';
import authReducer from './authReducer';
import { adminReducer } from './adminReducer';
import { signOutActionName } from '../actionNames';
import { LoginScreenType } from '../../core/enums';
import { initReducer } from './initReducer';
import { programmableNodesReducer } from './programmableNodesReducer';
import { filesReducer } from './filesReducer';
import workflowsSlice from '../slices/workflowsSlice';
import settingsSlice from '../slices/settingsSlice';

const appReducer = (history: any) => combineReducers({
    pages: pageReducer,
    auth: authReducer,
    projects: projectReducer,
    workflowData: workflowsSlice.reducer,
    scans: scanReducer,
    programmableNodes: programmableNodesReducer,
    fileData: filesReducer,
    settings: settingsSlice.reducer,
    admin: adminReducer,
    init: initReducer,
    //router: connectRouter(history)
})

export default function rootReducer(history: any) {
    var reducer = appReducer(history);
    return (state: any, action: any) => {
        if (action.type === signOutActionName) {
            // If signout happens, reset the whole state and fix the login screen to jump on the Sign In page
            var initState = reducer(undefined, action);
            return {
                ...initState,
                auth: {
                    ...initState.auth,
                    loginScreen: LoginScreenType.PerformLogin
                }
            }
        } else {
            return reducer(state, action);
        }
    }
}