import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd';
import CenteredComponentPage from '../../components/pageInterfaces/CenteredComponentPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signInThunk } from '../../store/actions/authActions';
import { IApplicationState, SignInDispatchProps, SignInProps, SignInStateProps } from '../../types';
import { selectSignInData } from '../../store/selectors';

declare const SERVER_ENV: any;

function SignInPage(props: SignInProps) {

    // if (props.errorMessage) {
    //     message.error(props.errorMessage, DEFAULT_ERROR_MESSAGE_DURATION);
    // }

    return (
        <CenteredComponentPage>
            <Row className="login-form">
                <Col>
                    <img src="ScourNomadLogo.png" />
                    <h1>ScourNomad</h1>
                </Col>
                <Col>
                    <h3>Log in</h3>
                    <Form
                        name="basic"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={async (values: any) => {
                            props.signIn(values.username, values.password);
                        }}
                        autoComplete="off">
                        <Form.Item label="User Name" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input.Password />
                        </Form.Item>

                        {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item> */}

                        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                            Submit
                            </Button>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 6, span: 16 }} style={{ fontSize: "10px" }}>
                            By signing in and using the ScourNomad service you accept the <b>Terms and Conditions</b> and <b>Privacy policy</b> documents.<br /><br />
                            <a href="https://scournomad.com/terms-and-conditions" className="license-link" target="_blank">Terms and Conditions</a>&nbsp;&nbsp;&nbsp;
                            <a href="https://scournomad.com/privacy-policy" className="license-link" target="_blank">Privacy policy</a>
                        </Form.Item>

                        <span style={{ float: "right", marginTop: "14px", color: "#555" }}>{SERVER_ENV.SOLUTION_VERSION_TAG}</span>
                    </Form>
                </Col>
            </Row>

        </CenteredComponentPage>
    );
}


export default connect<SignInStateProps, SignInDispatchProps, {}, IApplicationState>(
    (state) => selectSignInData(state),
    dispatch => bindActionCreators({
        signIn: signInThunk
    }, dispatch)
)(SignInPage)