import { createAction } from "@reduxjs/toolkit";
import { PageType } from '../../core/enums';
import * as Names from '../actionNames';

// Core tabs Actions
export const openTabAction = createAction(Names.openTabActionName, (pageType: PageType, data?: any, immediate?: any) => ({ payload: { pageType: pageType, data: data, immediate: immediate }}));
export const closeTabAction = createAction(Names.closeTabActionName, (tabId: number) => ({ payload: { tabId: tabId }}));
export const selectTabAction = createAction(Names.selectTabActionName, (tabId: number) => ({ payload: { tabId: tabId }}));
export const reorderTabsAction = createAction(Names.reorderTabsActionName, (orderedKeys: Array<string>) => ({ payload: { orderedKeys: orderedKeys }}));
export const changePageParamsAction = createAction(Names.changePageParamsActionName, (params: any) => ({ payload: { params: params }}));

// Tab data Actions
export const updateProjectPageDataAction = createAction(Names.updateProjectPageDataActionName, (tabId: number, project: any) => ({ payload: { tabId: tabId, project: project }}));
// export const updateWorkflowPageDataAction = createAction(Names.updateWorkflowPageDataActionName, (tabId: number, workflow: any) => ({ payload: { tabId: tabId, workflow: workflow }}));
export const pageFinishedLoadingAction = createAction(Names.pageFinishedLoadingActionName, (tabId: number) => ({ payload: { tabId: tabId }}));