import { NEW_GUID } from "../../constants";
import { LicenseAgreementType } from "../../core/enums";
import { arrayDelete, unique } from "../../core/reduxHelpers";
import { InitData } from "../../types";
import * as Names from "../actionNames";

const defaultState: InitData = {
    allDataTags: [],
    licenseAgreements: [],
    organizationId: NEW_GUID,
}

function initReducer(state: InitData = defaultState, action: any): InitData {
    switch (action.type) {

        case Names.loadDataTagsActionName:
            return loadDataTags(state, action.payload.tags);

        case Names.pushNewTagActionName:
            return pushNewTag(state, action.payload.tag);

        case Names.loadInitDataActionName:
            return loadInitData(state, action.payload);

        case Names.setLicenseAgreementHandledActionName:
            return setLicenseAgreementHandled(state, action.payload.type);

        default:
            return state;
    }
}

export {
    initReducer
}

function loadDataTags(state: InitData, tags: any): InitData {
    return {
        ...state,
        allDataTags: [...tags]
    }
}

function pushNewTag(state: InitData, tag: any): InitData {
    return {
        ...state,
        allDataTags: unique([...state.allDataTags, tag])
    }
}

function loadInitData(state: InitData, payload: any): InitData {
    return {
        ...state,
        ...payload
    }
}

function setLicenseAgreementHandled(state: InitData, type: LicenseAgreementType): InitData {
    return {
        ...state,
        licenseAgreements: arrayDelete(state.licenseAgreements, k => k.type === type)
    }
}
