export enum AccessType // TODO: Map automatically from C#
{
    Calculated,
    Allowed,
    Denied
}

export enum PermissionType // TODO: Map automatically from C#
{
    // ========= Features =========
    UserFeature_Projects = 1,
    UserFeature_Workflows,
    UserFeature_ExecuteWorkflows,
    UserFeature_Scans,
    UserFeature_Files,
    UserFeature_ProgrammableNodes,
    UserFeature_Settings,

    // ========= Projects =========
    UserFunctionality_CreateProject,
    UserFunctionality_EditProject,
    UserFunctionality_DeleteProject,
    UserFunctionality_MaxSeedResources,

    // ========= Workflows =========
    UserFunctionality_CreateWorkflow,
    UserFunctionality_EditWorkflow,
    UserFunctionality_EditFlowGraph,
    UserFunctionality_DeleteWorkflow,
    UserFunctionality_MaxNodesInGraph,

    // ========= Executing scans =========
    UserFunctionality_ExecuteImmediateScan,
    UserFunctionality_ScheduleIntervalScan,
    UserFunctionality_ScheduleDeferredScan,
    UserFunctionality_DeleteScan,
    UserFunctionality_DownloadRawScanData,
    UserFunctionality_DeleteScanSchedule,
    BackendFunctionality_SimultaneousIntervalScans,
    BackendFunctionality_SimultaneousScans,
    BackendFunctionality_MinimumParallelScanInterval,
    UserFunctionality_MaxDaysVisibleScans,
    UserFunctionality_MaxCountInGroupVisibleScans,
    BackendFunctionality_MaxExecutionTime,

    // ========= Programmable nodes =========
    UserFunctionality_ImportNewProgrammableNode,
    UserFunctionality_DeleteProgrammableNode,

    // ========= Filesystem =========
    UserFunctionality_UploadFile,
    UserFunctionality_UploadAndDecompressFile,
    UserFunctionality_DeleteFilesAndDirectories,
    UserFunctionality_DownloadFile,
    UserFunctionality_FilesystemWrite,
    UserFunctionality_FilesystemRead,

    // ========= Settings =========
    UserFunctionality_StoreSetting,

    // ========= Node blueprints =========
    NodeBlueprint_AmassEnumNode,
    NodeBlueprint_AmassIntelNode,
    NodeBlueprint_AmassNode,
    NodeBlueprint_AmassV4Node,
    NodeBlueprint_AquatoneNode,
    NodeBlueprint_ArjunNode,
    NodeBlueprint_CensysNode,
    NodeBlueprint_ConditionalTaggingNode,
    NodeBlueprint_ConverterNode,
    NodeBlueprint_CrtShNode,
    NodeBlueprint_CyberChefNode,
    NodeBlueprint_DnsXNode,
    NodeBlueprint_DroopeScanNode,
    NodeBlueprint_EyeWitnessNode,
    NodeBlueprint_FFUFNode,
    NodeBlueprint_FileInNode,
    NodeBlueprint_FileOutNode,
    NodeBlueprint_FullHuntNode,
    NodeBlueprint_GAUNode,
    NodeBlueprint_GobusterDirNode,
    NodeBlueprint_GobusterDnsNode,
    NodeBlueprint_GobusterNode,
    NodeBlueprint_HakrawlerNode,
    NodeBlueprint_HistoryCompareNode,
    NodeBlueprint_HistoryNode,
    NodeBlueprint_HttprobeNode,
    NodeBlueprint_HttpXNode,
    NodeBlueprint_JoomScanNode,
    NodeBlueprint_KiterunnerNode,
    NodeBlueprint_MasscanNode,
    NodeBlueprint_MergeNode,
    NodeBlueprint_MessengerNode,
    NodeBlueprint_NaabuNode,
    NodeBlueprint_NmapNode,
    NodeBlueprint_NucleiNode,
    NodeBlueprint_ParamSpiderNode,
    NodeBlueprint_RegexFilterNode,
    NodeBlueprint_RegexUpdateNode,
    NodeBlueprint_RemovePropertyNode,
    NodeBlueprint_ReportCreatorNode,
    NodeBlueprint_SeedResourcesNode,
    NodeBlueprint_SetOperationsNode,
    NodeBlueprint_ShodanNode,
    NodeBlueprint_SplitNode,
    NodeBlueprint_SQLMapNode,
    NodeBlueprint_Sublist3rNode,
    NodeBlueprint_SwitchNode,
    NodeBlueprint_TaggingFilterNode,
    NodeBlueprint_TaggingNode,
    NodeBlueprint_TicketCreatorNode,
    NodeBlueprint_TruffleHogNode,
    NodeBlueprint_UniqueNode,
    NodeBlueprint_Unknown,
    NodeBlueprint_VirusTotalNode,
    NodeBlueprint_WappalyzerNode,
    NodeBlueprint_WaybackUrlsNode,
    NodeBlueprint_WebAnalyzeNode,
    NodeBlueprint_WhatWebNode,
    NodeBlueprint_WPScanNode,
    NodeBlueprint_YaraNode,
    NodeBlueprint_SQLiteNode,
    NodeBlueprint_XSStrikeNode,
    NodeBlueprint_GhauriNode,

    // ========= Admin =========
    AdminFeature_Notifications,
    AdminFeature_UsersAndGroups,
}
