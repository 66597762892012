import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';

// TODO: Move this somewhere
// TODO: Create Markdown editor

export default function MarkdownFormatter(props: any) {
    const components = {
        h1: HeadingRenderer,
        h2: HeadingRenderer,
        h3: HeadingRenderer,
        h4: HeadingRenderer,
        h5: HeadingRenderer,
        h6: HeadingRenderer,
        code: SyntaxHighlighterRenderer
    }
    return (
        <div className="markdown">
            <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
                {props.children}
            </ReactMarkdown>
        </div>
    );
}

function HeadingRenderer(props: any) {
    return (<Title level={props.level}>{props.children}</Title>);
}

// TODO: Make the themes changeable
function SyntaxHighlighterRenderer(props: any) {
    // Set code language declared in code block: ```lang
    const match = /language-(\w+)/.exec(props.className || '')
    return !props.inline && match
    ? (
        <SyntaxHighlighter
            style={vscDarkPlus}
            language={match[1]}
            showLineNumbers={true}>
                {props.children[0]}
        </SyntaxHighlighter>
    )
    : (
        <code className={props.className} {...props} />
    );
}