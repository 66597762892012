import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectProjectPage } from '../store/selectors';
import { IApplicationState, ISettingEditorData, Popup, ProjectDetailsDispatchProps, ProjectDetailsOwnProps, ProjectDetailsProps, ProjectPageStateProps, SeedResource } from "../types";
import { Button, Col, Row, Tabs, Typography } from 'antd';
import MarkdownFormatter from './ui/MarkdownFormatter';
import { groupBy } from '../core/reduxHelpers';
import { SeedResourceDescriptions, DataType, SettingCoverageType } from '../core/enums';
import DangerousConfirmButton from './ui/DangerousConfirmButton';
import { deleteProjectThunk, openEditProjectTabAction } from '../store/actions/projectActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ScheduleWorkflowModal from './ScheduleWorkflowModal';
import RunWorkflowModal from './RunWorkflowModal';
import TabPane from 'antd/lib/tabs/TabPane';
import { SettingsDisplay } from './SettingsDisplay';
import GenerateSettingsEditor from '../components/settingsEditors/SettingsEditor';
import LoadingIndicator from './LoadingIndicator';

const { Text, Title } = Typography;


function ProjectDetails(props: ProjectDetailsProps) {
    const seedResources = groupBy(props.project?.seedResources ?? [], (el) => el.resourceType);
    const seedResourceOutput: any[] = [];
    const scheduleWorkflowPopupRef = React.createRef();
    const openScheduleWorkflowPopupModal = () => {
        if (scheduleWorkflowPopupRef.current) {
            (scheduleWorkflowPopupRef.current as Popup).show();
        }
    };
    const runWorkflowPopupRef = React.createRef();
    const openRunWorkflowPopupModal = () => {
        if (runWorkflowPopupRef.current) {
            (runWorkflowPopupRef.current as Popup).show();
        }
    };
    const settingEditorData: ISettingEditorData = {
        coverageType: SettingCoverageType.Project,
        entityId: props.projectId,
    }
    seedResources.forEach(function (resourceList: SeedResource[], key: DataType) {
        seedResourceOutput.push(
            <div key={key}>
                <Title level={5}>{SeedResourceDescriptions.get(key) ?? "<unknown seed resource type>"}</Title>
                <ul>
                    {resourceList.map(function (resource: SeedResource, index: number) {
                        return <li style={{ marginBottom: "15px" }} key={index}>{resource.value} {resource.tags && resource.tags.length ?<span style={{ color: "#666", fontSize: "13px" }}><br /><FontAwesomeIcon icon={solid("tags")} /> {resource.tags.join(", ")}</span> : <></>}</li>;
                    })}
                </ul>
            </div>
        );
    })
    if (!props.project) {
        return (<Row><Col span={24}>{/* TODO: Add skeleton loading - the if statement doesn't work any more as Project is initialized in reducer */}</Col></Row>);
    } else {
        const project = props.project;
        return (
            <>
                <Row>
                    <Col span={24} className='project-details'>
                        <Title className='main-title'>{props.project?.name}</Title>
                        <Text className='meta' type="secondary">Project created on: {new Date(project.dateTimeCreated).toLocaleString()}</Text><br />
                        {
                            project.dateTimeCreated != project.dateTimeUpdated
                            ? <><Text className='meta' type="secondary">Project updated on: {new Date(project.dateTimeUpdated).toLocaleString()} (ver {project.version})</Text><br /></>
                            : <></>
                        }
                    </Col>
                </Row>
                <Tabs>
                    <TabPane tab="Project details" key={1}>
                        <Row>
                            <Col span={24} className='project-details'>
                                <MarkdownFormatter>{props.project?.description}</MarkdownFormatter>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Title level={4}>Resources</Title>
                                { seedResourceOutput }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button
                                    type='primary'
                                    disabled={props.disabled}
                                    icon={<FontAwesomeIcon icon={solid("pen-to-square")} />}
                                    onClick={() => props.openEditProjectTab(project.projectId)}>Edit</Button>
                                <DangerousConfirmButton
                                    popupText={<>Delete project?</>}
                                    icon={solid("trash-can")}
                                    isInProgress={props.isDeleteInProgress}
                                    disabled={props.disabled}
                                    onConfirm={() => props.deleteProject(project.projectId)}>Delete</DangerousConfirmButton>
                                <Button
                                    type="text"
                                    disabled={props.disabled}
                                    icon={<FontAwesomeIcon icon={solid("calendar-days")} />}
                                    onClick={openScheduleWorkflowPopupModal}>Schedule</Button>
                                <Button
                                    type="text"
                                    disabled={props.disabled}
                                    icon={<FontAwesomeIcon icon={solid("play")} />}
                                    onClick={openRunWorkflowPopupModal}>Run scan</Button>
                            </Col>
                        </Row>
                        <ScheduleWorkflowModal ref={scheduleWorkflowPopupRef} projectId={project.projectId} />
                        <RunWorkflowModal ref={runWorkflowPopupRef} projectId={project.projectId} />
                    </TabPane>
                    <TabPane tab="Project settings" key={2}>
                        {
                            !props.settingSections || props.settingSections.length == 0
                            ? <LoadingIndicator position='Center' />
                            : <SettingsDisplay tabs={[
                                GenerateSettingsEditor(props.settingSections[0], settingEditorData),
                                GenerateSettingsEditor(props.settingSections[1], settingEditorData)
                            ]} />
                        }
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

// TODO: This should be fetching project from Projects array - the updates from edit screens need to be applied automatically here after save
export default connect<ProjectPageStateProps, ProjectDetailsDispatchProps, ProjectDetailsOwnProps, IApplicationState>(
    (state, ownProps) => selectProjectPage(state, ownProps.tabId, true),
    dispatch => bindActionCreators({
        openEditProjectTab: openEditProjectTabAction,
        deleteProject: deleteProjectThunk,
    }, dispatch)
)(ProjectDetails)