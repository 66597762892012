import React, { useImperativeHandle } from "react";
import { Button, Collapse, Input, Modal, Space, Tabs, Typography } from "antd";
import { IBlueprintData, NodeBlueprintCategory, NodeBlueprintsListModalProps, NodeBlueprintSubcategory } from "../types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from "react-redux";
import { RootState } from "../store/configureAppStore";
import { selectBlueprintData } from "../store/slices/workflowsSlice";
import { newNode } from "../core/flowGraphHelpers";

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const NodeBlueprintsListModal = React.forwardRef((props: NodeBlueprintsListModalProps, ref: any) => {

    const [visible, setVisible] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const blueprintData = useSelector<RootState, IBlueprintData>(state => selectBlueprintData(state, searchTerm));
    const show = () => { setVisible(true); }

    useImperativeHandle(ref, () => ({ show }));

    const handleClose = () => {
        setVisible(false);
    };

    const items = blueprintData.categories.map((c: NodeBlueprintCategory, index: number) => ({
            label: c.name,
            key: `${index}`,
            children: (
                <Collapse className="node-selector" accordion>
                {
                    c.subcategories.map((nbsc: NodeBlueprintSubcategory, index2: number) =>
                        <Panel header={nbsc.name} key={`${index}_${index2}`}>
                            <Space wrap>
                            {
                                nbsc.nodeBlueprintIds.map(nbid => {
                                    const nb = blueprintData.blueprints[nbid];
                                    return (
                                        <div className="react-flow__node" style={{ position: "relative" }}>
                                            <div><Text strong className="node-name">{nb?.name}</Text> <Text type="secondary">{nb?.version}</Text></div>
                                            <Paragraph className="node-description" ellipsis={{ rows: 2, tooltip: nb?.description }}>{nb?.description}</Paragraph>
                                            <div style={{ display:"flex" }}>
                                                <Button type="dashed" className="fill-rest" icon={<FontAwesomeIcon icon={solid("plus")} />} onClick={() => { props.addNodeToGraph(newNode(nb, props.workflowId)) }}>
                                                    Add node
                                                </Button>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>)
                                })
                            }
                            </Space>
                        </Panel>
                    )
                }
                </Collapse>
            )
        }));

    return (
        <Modal
            open={visible}
            title="Add node"
            onOk={handleClose}
            onCancel={handleClose}
            width="70%"
            footer={[
                <Button key="back" type="text" onClick={handleClose}>Close</Button>
            ]}>
            <Input placeholder="search" onChange={(data) => setSearchTerm(data.target.value)} />
            <Tabs items={items} />
        </Modal>
    );
});

export default NodeBlueprintsListModal;