import _, { List } from "lodash";
import { createSliceWithThunks } from "../../core/reduxCore";
import { IPage, IWorkflowsState, Workflow } from "../../types";
import { changePageParamsAction, openTabAction, pageFinishedLoadingAction } from "../actions/tabActions";
import { PageType } from "../../core/enums";
import { communication } from "../../core/communication";
import { flowGraphDataToViewModel, viewModelToFlowGraphData } from "../../core/flowGraphHelpers";
import { AppDispatch, RootState } from "../configureAppStore";
import { createSelector } from "@reduxjs/toolkit";
import { NEW_GUID } from "../../constants";

const initialState: IWorkflowsState = {
    workflows: {},
    workflowNodeBlueprints: {},
    workflowNodeBlueprintCategories: [],
    workflowNodeBlueprintsAreLoading: false,
    workflowNodeBlueprintsLoaded: false,
    loadingWorkflows: false,
    validation: {
        isValidating: false,
        nodeId: null,
        handleId: null,
        handleType: null,
        validContracts: null,
    }
}

//
// Page openers
//

export const openWorkflowsTab = (dispatch: any) => dispatch(openTabAction(PageType.Workflows));
export const openViewWorkflowTab = (dispatch: any, workflowId: string) => dispatch(openTabAction(PageType.Workflow, { workflowId: workflowId, isEditMode: false, isCreateMode: false }));
export const openCreateNewWorkflowTab = (dispatch: any) => dispatch(openTabAction(PageType.Workflow, { isCreateMode: true }));
export const openEditWorkflowTab = (dispatch: any, workflowId: string) => dispatch(openTabAction(PageType.Workflow, { workflowId: workflowId, isEditMode: true }));
export const openEditFlowGraphTab = (dispatch: any, workflowId: string) => dispatch(openTabAction(PageType.FlowGraph, { workflowId: workflowId }));

//
// Selectors
//

export const selectWorkflows = (state: RootState): Workflow[] =>
    _.values(state.workflowData.workflows).filter(w => !w.isDeleted);

export const selectWorkflowNamesAndIds = (state: RootState): List<any> =>
    _.values(state.workflowData.workflows).map(w => ({ id: w.workflowId, name: w.name }))

export const selectBlueprintData = createSelector(
    (state: RootState) => state.workflowData.workflowNodeBlueprints,
    (state: RootState) => state.workflowData.workflowNodeBlueprintCategories,
    (_: any, searchPparam: string) => searchPparam,
    (blueprints, categories, param) => {
        if (param && param != "") {
            const filteredBlueprints = _.pickBy(blueprints, b =>
                b.name?.toLowerCase()?.includes(param.toLowerCase()) ||
                b.description?.toLowerCase()?.includes(param.toLowerCase()) ||
                b.author?.toLowerCase()?.includes(param.toLowerCase()));
            const filteredCategories = [...categories
                .filter(c => c.subcategories.some(sc => sc.nodeBlueprintIds.some(nbi => _.keys(filteredBlueprints).some(fb => fb === nbi))))
                .map(c => ({
                    ...c,
                    subcategories: c.subcategories
                        .filter(sc => sc.nodeBlueprintIds.some(nbi => _.keys(filteredBlueprints).some(fb => fb === nbi)))
                        .map(sc => ({
                            ...sc,
                            nodeBlueprintIds: sc.nodeBlueprintIds.filter(nbi => _.keys(filteredBlueprints).some(fb => fb === nbi))
                        }))
                })
            )]

            return {
                blueprints: filteredBlueprints,
                categories: filteredCategories,
            }
        } else {
            return {
                blueprints: blueprints,
                categories: categories,
            }
        }
    }
);

export const selectWorkflow = createSelector(
    (state: RootState, tabId: number) => state.pages.tabs[tabId],
    (state: RootState, _: any, workflowId: string) => state.workflowData.workflows[workflowId],
    (tab: IPage, workflow: Workflow): Workflow =>
    {
        const res = !tab?.params?.isCreateMode
            ? { ...workflow, ...tab?.params ?? { } }
            : {
                workflowId: NEW_GUID,
                version: 0,
                name: "",
                description: "",
                isSaveInProgress: false,
                isDeleteInProgress: false,
                isDeleted: false,
                isEditMode: false,
                isCreateMode: true,
                dateTimeCreated: new Date(),
                dateTimeUpdated: new Date(),
                nodes: [],
                edges: [],
            };
        return res;
    }
);


//
// Slice
//

const workflowsSlice = createSliceWithThunks({
    name: "workflows",
    initialState: initialState,
    reducers: (create) => ({

        //
        // Reducers
        //

        beginConnectionValidation: create.reducer<any>((state, action: any) => {
            // We can use workflows slice and don't need to do it through node state because there can
            // only be ove validation event at a time. The user drags a single line at a time which might
            // need a validation. The edge case might be someone using two mouses and dragging two lines
            // at the same time.
            const node = action.payload.node;
            const handleId = action.payload.handleId;
            const handleType = action.payload.handleType;
            const validDataContracts = handleType == "source"
                ? node.data.blueprint.exits.find((e: any) => e.handleId == handleId).dataContracts
                : node.data.blueprint.entries.find((e: any) => e.handleId == handleId).dataContracts;

            state.validation = {
                isValidating: true,
                nodeId: node.Id,
                handleId: handleId,
                handleType: handleType,
                validContracts: validDataContracts,
            }
        }),

        endConnectionValidation: create.reducer((state, action: any) => {
            state.validation = {
                isValidating: false,
                nodeId: null,
                handleId: null,
                handleType: null,
                validContracts: null,
            }
        }),

        //
        // Thunks
        //

        fetchWorkflows: create.asyncThunk(
            async (_: any, api: any) => {
                return await communication.get("/api/v1/workflows");
            },
            {
                pending: (state) => {
                    state.loadingWorkflows = true
                },
                rejected: (state) => {
                    state.loadingWorkflows = false
                },
                fulfilled: (state, action) => {
                    state.loadingWorkflows = false
                    state.workflows = _.keyBy((action.payload.result?.workflows ?? []).map((w: any) => ({...w, isDeleteInProgress: false })), "workflowId");
                },
            }
        ),
        fetchWorkflow: create.asyncThunk(
            async ({ workflowId }, api: any) => {
                const dispatch = api.dispatch as AppDispatch;

                dispatch(changePageParamsAction({ isLoading: true }));
                const createResponse = await communication.get(`/api/v1/workflow/${workflowId}`);
                dispatch(changePageParamsAction({ isLoading: false, workflowId: workflowId }));
                return createResponse;
            },
            {
                pending: (state, action) => {
                },
                rejected: (state, action) => {
                },
                fulfilled: (state, action) => {
                    const blueprints = state.workflowNodeBlueprints;
                    state.workflows[action.payload.result.workflowId] = {
                        ...state.workflows[action.payload.result.workflowId],
                        ...viewModelToFlowGraphData(action.payload.result, blueprints)
                    }
                },
            }
        ),
        createWorkflow: create.asyncThunk(
            async ({ tabId, workflow }, api: any) => {
                const dispatch = api.dispatch as AppDispatch;

                const createResponse = await communication.post("/api/v1/workflow/create", workflow);
                dispatch(changePageParamsAction({ isCreateMode: false, workflowId: createResponse.result.workflowId }));
                return createResponse;
            },
            {
                pending: (state, action) => {
                },
                rejected: (state, action) => {
                },
                fulfilled: (state, action) => {
                    state.workflows[action.payload.result.workflowId] = { ...action.payload.result, isCreateMode: false };
                },
            }
        ),
        deleteWorkflow: create.asyncThunk(
            async ({ workflowId }, api: any) => {
                return await communication.post(`/api/v1/workflow/${workflowId}/delete`);
            },
            {
                pending: (state, action) => {
                    state.workflows[action.meta.arg.workflowId].isDeleteInProgress = true;
                },
                rejected: (state, action) => {
                    state.workflows[action.meta.arg.workflowId].isDeleteInProgress = false;
                },
                fulfilled: (state, action) => {
                    state.workflows[action.meta.arg.workflowId].isDeleteInProgress = false;
                    state.workflows[action.payload.result.workflowId].isDeleted = true;
                },
            }
        ),
        updateWorkflow: create.asyncThunk(
            async ({ workflow }, api: any) => {
                return await communication.post("/api/v1/workflow/update", workflow);
            },
            {
                pending: (state, action) => {
                    state.workflows[action.meta.arg.workflow.workflowId].isSaveInProgress = true;
                },
                rejected: (state, action) => {
                    state.workflows[action.meta.arg.workflow.workflowId].isSaveInProgress = false;
                },
                fulfilled: (state, action) => {
                    const blueprints = state.workflowNodeBlueprints;
                    state.workflows[action.meta.arg.workflow.workflowId].isSaveInProgress = false;
                    state.workflows[action.payload.result.workflowId] = {
                        ...state.workflows[action.payload.result.workflowId],
                        ...viewModelToFlowGraphData(action.payload.result, blueprints)
                    }
                },
            }
        ),
        fetchNodeBlueprints: create.asyncThunk(
            async (api: any) => {
                return await communication.get(`/api/v1/nodeBlueprints`);
            },
            {
                pending: (state, action) => {
                    state.workflowNodeBlueprintsAreLoading = true;
                },
                rejected: (state, action) => {
                    state.workflowNodeBlueprintsAreLoading = false;
                    state.workflowNodeBlueprintsLoaded = false;
                },
                fulfilled: (state, action) => {
                    state.workflowNodeBlueprints = action.payload.result.blueprints;
                    state.workflowNodeBlueprintCategories = action.payload.result.categories;
                    state.workflowNodeBlueprintsAreLoading = false;
                    state.workflowNodeBlueprintsLoaded = true;
                },
            }
        ),
        updateFlowGraph: create.asyncThunk(
            async ({ workflowId, nodes, edges }, api: any) => {
                // TODO: Implement "in progress" indicator
                const workflowViewModel = flowGraphDataToViewModel(workflowId, nodes, edges);
                return await communication.post(`/api/v1/flowGraph/update`, workflowViewModel);
            },
            {
                pending: (state, action) => {
                },
                rejected: (state, action) => {
                },
                fulfilled: (state, action) => {
                    const blueprints = state.workflowNodeBlueprints;
                    state.workflows[action.payload.result.workflowId] = {
                        ...state.workflows[action.payload.result.workflowId],
                        ...viewModelToFlowGraphData(action.payload.result, blueprints)
                    }
                },
            }
        ),
        runProjectWorkflowScan: create.asyncThunk(
            async ({ workflowId, projectId }, api: any) => {
                const response = await communication.get(`/api/v1/run/projectWorkflow/${projectId}/${workflowId}`);
            }
        ),
        deferProjectWorkflowScan: create.asyncThunk(
            async ({ workflowId, projectId, dateTime }, api: any) => {
                const response = await communication.get(`/api/v1/defer/projectWorkflow/${projectId}/${workflowId}/${dateTime.toISOString()}`);
            }
        ),
        intervalProjectWorkflowScan: create.asyncThunk(
            async ({ workflowId, projectId, interval, units }, api: any) => {
                const response = await communication.get(`/api/v1/interval/projectWorkflow/${projectId}/${workflowId}/${interval}/${units}`);
            }
        )
    })
})

export const {
    // Reducers
    beginConnectionValidation,
    endConnectionValidation,

    // Thunks
    fetchWorkflows,
    fetchWorkflow,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,

    fetchNodeBlueprints,
    updateFlowGraph,
    runProjectWorkflowScan,
    deferProjectWorkflowScan,
    intervalProjectWorkflowScan,
} = workflowsSlice.actions

export default workflowsSlice;
