import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, DatePicker, Form, Input, List, Radio, Row, Select, Switch, Tag } from 'antd';
import GutteredPage from '../../components/pageInterfaces/GutteredPage';
import { selectNotificationPage } from '../../store/selectors';
import { IApplicationState, NotificationPageDispatchProps, NotificationPageOwnProps, NotificationPageProps, NotificationPageStateProps, UserGroupAssignementViewModel } from '../../types';
import MarkdownFormatter from '../../components/ui/MarkdownFormatter';
import DangerousConfirmButton from '../../components/ui/DangerousConfirmButton';
import { NotificationAlertType, UserGroupType } from '../../core/enums';
import { deleteNotificationThunk, storeNotificationThunk } from '../../store/actions/adminActions';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { single } from '../../core/reduxHelpers';
import { UsersAndGroupsToSelection } from '../../core/miscHelper';

function NotificationPage(props: NotificationPageProps) {
    if (!props.notification) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    const icons = [
        { icon: solid("cloud-arrow-up") },
        { icon: solid("user-secret") },
        { icon: solid("siren-on") },
        { icon: solid("triangle-exclamation") },
        { icon: solid("bug-slash") },
        { icon: solid("newspaper") },
        { icon: solid("sparkles") },
        { icon: solid("messages") },
        { icon: solid("box-check") },
        { icon: solid("cubes") },
    ]

    const usersAndGroups = UsersAndGroupsToSelection(props.usersAndGroups)?.map((u: UserGroupAssignementViewModel) => {
        var icon;
        switch (u.type) {
            case UserGroupType.Everyone: icon = solid("building-user"); break;
            case UserGroupType.Group: icon = solid("people-group"); break;
            case UserGroupType.User: icon = solid("user"); break;
            default: icon = solid("question-circle"); break;
        }
        return {
            label: <><FontAwesomeIcon icon={icon} />&nbsp;&nbsp;{u.name}</>,
            value: u.id,
        };
    });

    const tagRender = (props: CustomTagProps) => {
        const { value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={value}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}>
                {single(usersAndGroups || [], u => u.value === props.value).label}
            </Tag>
        );
    }

    const [form] = Form.useForm();
    const [preview, setPreview] = React.useState(false);

    return (
        <GutteredPage>
            {
                props.isDeleted
                ? <Alert
                    message="Notification Deleted"
                    description="This notification has already been deleted."
                    type="error"
                    icon={<FontAwesomeIcon icon={solid("trash-can")} />}
                    showIcon />
                : <></>
            }
            <Row>
                <Col span={24}>
                    <Form
                        autoComplete="off"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        initialValues={props.notification}
                        disabled={props.isDeleted}
                        onFinish={(values) => {
                            props.storeNotification({...props.notification, ...values});
                        }}>
                        <Form.Item label="Notification" name="markdownText">
                            {
                                preview
                                    ? <MarkdownFormatter>{form.getFieldValue("markdownText")}</MarkdownFormatter>
                                    : <Input.TextArea className="markdown-editor" placeholder="Notification contents" />
                            }
                        </Form.Item>
                        <Button type="text" onClick={() => setPreview(!preview)} style={{ float: "right", marginBottom: "10px" }}>
                            {preview ? "Edit" : "Preview"}
                        </Button>
                        <div style={{ clear: "both" }}></div>

                        <Form.Item label="Alert type" name="alertType">
                            <Radio.Group>
                                <Radio.Button value={NotificationAlertType.Info}>Info</Radio.Button>
                                <Radio.Button value={NotificationAlertType.Warning}>Warning</Radio.Button>
                                <Radio.Button value={NotificationAlertType.Alert}>Alert</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="Users and groups" name="userGroupAssignmentIds">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "50%" }}
                                placeholder="Please select users and groups to show this notification"
                                options={usersAndGroups || []}
                                optionLabelProp="name"
                                tagRender={tagRender}/>
                        </Form.Item>

                        <Form.Item label="Should auto-display" name="shouldDisplay">
                            <Switch />
                        </Form.Item>

                        <Form.Item label="Should display on alert banner" name="shouldDisplayOnAlertBanner">
                            <Switch />
                        </Form.Item>

                        <Form.Item label="Active date and time" name="activeStart">
                            <DatePicker style={{width: "50%"}} showTime />
                        </Form.Item>

                        <Form.Item label="Inactive date and time" name="activeEnd">
                            <DatePicker style={{width: "50%"}} showTime />
                        </Form.Item>

                        <Form.Item label="Icon" name="iconName">
                            <Input style={{width: "50%"}} />
                        </Form.Item>

                        <Row>
                            <Col offset={4} span={10}>
                                <List
                                    grid={{ gutter: 80, column: 5 }}
                                    dataSource={icons}
                                    renderItem={item => (
                                    <List.Item>
                                        <Button
                                            style={{width: "70px", height: "70px"}}
                                            onClick={() => {
                                                form.setFieldsValue({iconName: item.icon.iconName});
                                            }}>
                                            <FontAwesomeIcon fontSize={30} icon={item.icon} />
                                        </Button>
                                    </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>

                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button
                                type="primary"
                                htmlType="submit">
                                    {props.notification.id ? "Save notification" : "Create notification"}
                            </Button>
                            {
                                props.notification.id
                                    ?
                                        <>
                                            <DangerousConfirmButton
                                                onConfirm={() => props.deleteNotification(props.notification?.id || "")}
                                                popupText='Delete notification?'
                                                icon={solid("trash-can")}>
                                                    Delete
                                            </DangerousConfirmButton>
                                        </>
                                    : <></>
                            }
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </GutteredPage>
    );
}

export default connect<NotificationPageStateProps, NotificationPageDispatchProps, NotificationPageOwnProps, IApplicationState>(
    (state, ownProps) => selectNotificationPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        storeNotification: storeNotificationThunk,
        deleteNotification: deleteNotificationThunk,
    }, dispatch)
)(NotificationPage)