import { createSelector } from "@reduxjs/toolkit";
import { Node } from "reactflow";
import { NEW_GUID } from "../constants";
import { SettingCoverageType, TreeItemObjectType } from "../core/enums";
import { all, any, keys, single, singleOrDefault, values } from "../core/reduxHelpers";
import { IApplicationState, IPage, ProjectPageState, NodeBlueprint, FlowGraphPageState, Scan, ScanPageState, Project, ProjectsPageStateProps, NodeBlueprintCategory, ProjectPageStateProps, ScansPageStateProps, ScanPageStateProps, AppStateProps, AppHeaderStateProps, ForceResetPasswordStateProps, SignInStateProps, NotificationViewModel, NotificationsPageStateProps, NotificationPageStateProps, UsersAndGroupsPageStateProps, UsersAndGroupsViewModel, ScanSchedule, ScanSchedulesPageStateProps, MainPropEditorStateProps, ProgrammableNodesPageStateProps, ProgrammableNode, FilesPageStateProps, Files, AppLayoutStateProps, SettingSection } from "../types";
import { fullPathsToTreeItems, getFullFilePaths, getSelectValuesFromStr } from "../core/treeHelper";
import { Dictionary } from "lodash";
import { AccessType, PermissionType } from "../core/permissionTypes";

export const selectPageByTabId = (state: IApplicationState, tabId: number) => {
    var test = single(state.pages.tabs ?? [], s => s.tabId == tabId);
    return test;
}
export const selectCurrentPage = (state: IApplicationState) => single(state.pages.tabs, t => t.tabId === state.pages.selectedPageId);
export const selectProjects = (state: IApplicationState) => state.projects;
export const selectProjectById = (state: IApplicationState, projectId: string) => state.projects[projectId];
export const selectWorkflows = (state: IApplicationState) => state.workflowData.workflows;
export const selectWorkflowById = (state: IApplicationState, workflowId: string) => state.workflowData.workflows[workflowId];
export const selectWorkflowNodeBlueprintsDict = (state: IApplicationState) => state.workflowData.workflowNodeBlueprints;
export const selectWorkflowNodeBlueprintCategoriesList = (state: IApplicationState) => state.workflowData.workflowNodeBlueprintCategories;
export const selectWorkflowNodeBlueprintsAreLoading = (state: IApplicationState) => state.workflowData.workflowNodeBlueprintsAreLoading;
export const selectIsViewMode = (_1: IApplicationState, _2: number, isEditMode: boolean) => isEditMode;
export const selectAuthData = (state: IApplicationState): AppStateProps => state.auth;
export const selectAppLayoutData = (state: IApplicationState): AppLayoutStateProps => ({ licenseAgreement: state.init.licenseAgreements.filter(la => la.isAccepted !== true)[0] });
export const selectScans = (state: IApplicationState): Dictionary<Scan> => state.scans.scans;
export const selectOrgId = (state: IApplicationState): string => state.init.organizationId;
export const selectScanSchedules = (state: IApplicationState): Array<ScanSchedule> => state.scans.scanSchedules;
// export const selectSettingSections = (
//     state: IApplicationState,
//     _: any,
//     coverageType?: SettingCoverageType | undefined,
//     entityId?: string | undefined): SettingSection[] => {
//         const key = `${coverageType}-${entityId || NEW_GUID}`;
//         return [...state.settings.settingSections[key] || []];
//     }
export const selectSettingSections = (state: IApplicationState): Dictionary<SettingSection[]> => state.settings.settingSections;
export const selectSignInData = (state: IApplicationState): SignInStateProps => ({ errorMessage: state.auth.errorMessage });
export const selectForceResetPasswordData = (state: IApplicationState): ForceResetPasswordStateProps => ({ userName: state.auth.claims.userName, sessionID: state.auth.sessionId || "", errorMessage: state.auth.errorMessage });
export const selectScanNodeExitChunk = (state: IApplicationState, scanId: string, scanNodeId: string, exitTypeId: string) => {
    const exitNode = state.scans.scans[scanId]?.scanNodes[scanNodeId]?.scanNodeResults.filter(snr => snr.exitType == exitTypeId)[0];
    return { data: exitNode?.results[exitNode?.selectedPage] || [] }
}
export const selectAllDataTags = (state: IApplicationState): string[] => state.init.allDataTags;
export const selectProgrammableNodes = (state: IApplicationState): ProgrammableNode[] => state.programmableNodes.nodes;
export const selectProgrammableNodesIsLoading = (state: IApplicationState): boolean => state.programmableNodes.isLoading;
export const selectFileData = (state: IApplicationState) => state.fileData;

// Complex page selectors

export const selectAppHeaderData = (state: IApplicationState): AppHeaderStateProps => {
    const notifications = state.auth.notifications || [];
    return {
        fullName: state.auth.claims.fullName,
        features: state.auth.features,
        notifications: state.auth.notifications,
        displayNotification: notifications.filter(n => n.shouldDisplay)[0] || null,
        bannerDisplayNotifications: notifications.filter(n => n.shouldDisplayOnAlertBanner && !n.isSeen) || [],
        unreadNotificationCount: notifications.filter(n => !n.isSeen).length,
        hasUnconfirmedLicenses: !!state.init.licenseAgreements.filter(la => la.isAccepted !== true).length
    };
};


export const selectProjectsPage = createSelector([selectProjects, selectPageByTabId], (projects: Dictionary<Project>, page: IPage): ProjectsPageStateProps => {
    return {
        ...page,
        isLoading: false,
        projects: values(projects),
    };
});

export const selectProjectPage = createSelector(
    [selectProjects, selectPageByTabId, selectIsViewMode, selectAllDataTags, selectSettingSections],
    (projects: any, page: IPage, isViewMode: boolean, allDataTags: string[], settingSections: Dictionary<SettingSection[]>): ProjectPageStateProps => {
    //if (!page) return null;

    const projectPage = (page as ProjectPageState);

    // This check makes sure the project doesn't exist in the projects dictionary and is used to display the message "project has already been deleted"
    // on the edit and preview screens showing deleted projects. As a sidenote, this variable is true even for not yet created projects, so we might need
    // to rename it in the future.
    const isProjectAlreadyDeleted = !projectPage.project?.isCreateMode && projectPage.project?.projectId != NEW_GUID && all(keys(projects), key => key !== projectPage.project?.projectId);
    const key = `${SettingCoverageType.Project}-${projectPage.projectId || NEW_GUID}`;

    if (isProjectAlreadyDeleted) {
        return {
            ...page,
            settingSections: [...settingSections[key] || []],
            project: projectPage.project,
            projectId: projectPage.projectId,
            isSaveInProgress: false,
            isDeleteInProgress: false,
            isProjectAlreadyDeleted: true,
            allDataTags: [],
        }
    } else {
        return {
            ...page,
            settingSections: [...settingSections[key] || []],
            // If the project is loaded in the preview tab, we need to show the contents from the projects dictionary. That's because we want to
            // reflect immediately any newly saved information.
            project: isViewMode ? projects[projectPage.projectId] : projectPage.project,
            projectId: projectPage.projectId,

            // "Is save in progress?" check is retrieved from the tab data in case of create mode, as it is not yet present in the projects dictionary.
            // For all other cases (edit existing project) the check is retrieved and stored in the projects dictionary. The reason is it needs to be
            // propagated to other editor tabs that have the same project open for editing.
            isSaveInProgress: projectPage.project?.isCreateMode
                ? projectPage.project?.isSaveInProgress
                : projectPage.projectId in projects
                    ? projects[projectPage.projectId].isSaveInProgress
                    : false,

            // "Is delete in progress?" check is always retrieved from the projects dictionary. There is an additional check to make sure the project is
            // not being created - the project being created cannot be deleted
            isDeleteInProgress: !projectPage.project?.isCreateMode &&
                projectPage.projectId in projects
                    ? projects[projectPage.projectId].isDeleteInProgress
                    : false,

            isProjectAlreadyDeleted: false,

            allDataTags: allDataTags,
        }
    }
});

export const selectScansPage = createSelector([selectScans, selectPageByTabId], (scans: Dictionary<Scan>, page: IPage): ScansPageStateProps => {
    return {
        ...page,
        scans: values(scans)
    };
});

export const selectScanSchedulesPage = createSelector([selectScanSchedules, selectPageByTabId], (scanSchedules: Array<ScanSchedule>, page: IPage): ScanSchedulesPageStateProps => {
    return {
        ...page,
        scanSchedules: scanSchedules
    };
});

export const selectScanPage = createSelector([selectScans, selectPageByTabId], (scans: Dictionary<Scan>, page: IPage): ScanPageStateProps => {
    //if (!page) return { }

    const scanPage = (page as ScanPageState);
    const isDeleted = scanPage.scanId != NEW_GUID && !scans[scanPage.scanId]

    return {
        ...page,
        scan: isDeleted ? scanPage.scan : scans[scanPage.scanId],
        isScanDeleted: isDeleted,
    }
});

// Programmable Nodes

export const selectProgrammableNodesPage = createSelector([selectProgrammableNodes, selectFileData, selectProgrammableNodesIsLoading, selectPageByTabId], (programmableNodes: ProgrammableNode[], fileData: Files, isLoading: boolean, page: IPage): ProgrammableNodesPageStateProps => {
    return {
        ...page,
        isLoading: isLoading,
        nodes: [ ...programmableNodes ],
        manifestTreeData: [ ...fullPathsToTreeItems(getFullFilePaths(fileData.files, "^manifest.json$")) ]
    };
});

// Admin

const selectNotifications = (state: IApplicationState) => state.admin.notifications;
const selectUsersAndGroups = (state: IApplicationState) => state.admin.usersAndGroups;

export const selectNotificationsPage = createSelector(
    [selectPageByTabId, selectNotifications, selectUsersAndGroups],
    (page: IPage, notifications: NotificationViewModel[] | null, usersAndGroups: UsersAndGroupsViewModel): NotificationsPageStateProps => {
        return {
            ...page,
            notifications: notifications,
            usersAndGroups: usersAndGroups,
        };
    }
);

export const selectNotificationPage = createSelector(
    [selectPageByTabId, selectNotifications, selectUsersAndGroups],
    (page: IPage, notifications: NotificationViewModel[] | null, usersAndGroups: UsersAndGroupsViewModel): NotificationPageStateProps => {
        const pageNotificationId = (page as NotificationPageStateProps).notification?.id;

        return {
            ...(page as NotificationPageStateProps),
            usersAndGroups: usersAndGroups,
            isDeleted: !(!pageNotificationId || any(notifications || [], n => n.id === pageNotificationId)),
        };
    }
);

export const selectUsersAndGroupsPage = createSelector(
    [selectPageByTabId],
    (page: IPage): UsersAndGroupsPageStateProps => {
        const pageUsersAndGroups = (page as UsersAndGroupsPageStateProps).usersAndGroups;

        return {
            ...(page as UsersAndGroupsPageStateProps),
            usersAndGroups: pageUsersAndGroups,
        };
    }
);

// Auth

export const selectIfAccessGrantedForPermission = createSelector(
    [(state: IApplicationState, _) => state, (_, requiredPermission: PermissionType) => requiredPermission],
    (state, requiredPermission) => {
        return {
            accessGranted: any(state.auth.features, p => p.permissionType === requiredPermission && p.accessType === AccessType.Allowed)
        }
});

export const selectIfAccessGrantedForAllPermissions = createSelector(
    [(state: IApplicationState, _) => state, (_, requiredPermissions: PermissionType[]) => requiredPermissions],
    (state, requiredPermissions) => {
        return {
            accessGranted: all(requiredPermissions, rp => any(state.auth.features, p => p.permissionType === rp && p.accessType === AccessType.Allowed))
        }
});

export const selectIfAccessGrantedForAnyPermissions = createSelector(
    [(state: IApplicationState, _) => state, (_, requiredPermissions: PermissionType[]) => requiredPermissions],
    (state, requiredPermissions) => {
        return {
            accessGranted: any(requiredPermissions, rp => any(state.auth.features, p => p.permissionType === rp && p.accessType === AccessType.Allowed))
        }
});

export const selectMainPropEditorData = createSelector([selectAllDataTags, selectFileData], (allDataTags: string[], fileData: Files): MainPropEditorStateProps => {
    return {
        allDataTags: allDataTags,
        files: [ ...getSelectValuesFromStr(fileData.files, TreeItemObjectType.NormalFile) ],
        directories: [ ...getSelectValuesFromStr(fileData.files, TreeItemObjectType.Directory) ],
        filesAndDirs: [ ...getSelectValuesFromStr(fileData.files) ]
    };
});

export const selectFilesPage = createSelector([selectPageByTabId, selectFileData], (page: IPage, fileData: Files): FilesPageStateProps => {
    const filesPage = (page as FilesPageStateProps);
    return {
        ...filesPage,
        files: [ ...fileData.files ],
        fileSizes: { ...fileData.fileSizes },
        usedSizeBytes: fileData.usedSizeBytes,
        maxSizeBytes: fileData.maxSizeBytes,
    };
});
