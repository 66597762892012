import Hub from './hub';
import { HubType } from './enums';
import { values } from './reduxHelpers';
import { MessageType } from '../enums';
import { notification } from "antd";
import { NumericDictionary } from 'lodash';

class Communication {
    // TODO: Implement this class better. Extract reusable code in functions and reuse it.
    // There should be functions like get(), getJson(), getFile()...

    constructor(store: any) {
        this._store = store;
    }

    private _store: any = null;
    private _token: string | null = null;
    private _hubs: NumericDictionary<Hub> = {};

    private static _communication: Communication;

    public getToken(): string {
        return `bearer ${this._token}`;
    }

    public resetConnections(token: string | null) {
        this._token = token;
        values(this._hubs).map(v => v.resetConnection(token));
    }

    public fetchToken() {
        return `bearer ${this._token}`;
    }

    /* Hubs implementation */

    public addHub(hubType: HubType, url: string) {
        const hub = new Hub(hubType, `/signalr/v1/${url}`, this._store);
        this._hubs[hubType] = hub;

        return hub;
    }

    /* Service calls */

    public async get(url: string) {
        var headers: any = {
            'Content-Type': 'application/json',
        };

        if (this._token) {
            headers['Authorization'] = this.fetchToken();
        }

        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const result = await response.json(); // parses JSON response into native JavaScript objects
        // TODO: try / catch the result if it is just html - error in path usually

        showMessage(result);

        return result;
    }

    public async postFile(url: string, file: any) {
        var headers: any = {};

        if (this._token) {
            headers['Authorization'] = `bearer ${this._token}`;
        }

        var data = new FormData()
        data.append('file', file)

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data // body data type must match "Content-Type" header. TODO: Check if empty body is ok
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const result = await response.json(); // parses JSON response into native JavaScript objects
        showMessage(result);

        return result;
    }

    public async post(url: string, data?: any) {
        var headers: any = {
            'Content-Type': 'application/json',
        };

        if (this._token) {
            headers['Authorization'] = this.fetchToken();
        }

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data ?? {}) // body data type must match "Content-Type" header. TODO: Check if empty body is ok
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const result = await response.json(); // parses JSON response into native JavaScript objects
        showMessage(result);

        return result;
    }

    public async getFile(url: string, fileName: string, method: string = "GET", data: any = null) {
        var headers: any = {
            'Content-Type': 'application/json',
        };

        if (this._token) {
            headers['Authorization'] = this.fetchToken();
        }

        const response = await fetch(url, {
            method: method,
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data !== null ? JSON.stringify(data) : null,
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        });
    }
}

var communication: Communication = new Communication(null);

function initializeCommunication(store: any) {
    communication = new Communication(store);
}

function showMessage(result: any) {
    if (result.messageTitle && result.messageType) {
        const config = {
            message: result.messageTitle,
            description: result.messageDescription ?? ""
        };
        switch(result.messageType) {
            case MessageType.Info:
                notification.info(config);
                break;
            case MessageType.Success:
                notification.success(config);
                break;
            case MessageType.Warning:
                notification.warning(config);
                break;
            case MessageType.Error:
                notification.error(config);
                break;
        }
    }
}

export { communication, initializeCommunication }