import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PageType } from "./core/enums";
import { IPageDescription } from "./types";

export const PageInfo: IPageDescription[] = [
    {
        pageType: PageType.Files,
        tabIcon: solid("folder-tree"),
        tabText: "Filesystem",
    },
    {
        pageType: PageType.FlowGraph,
        tabIcon: solid("diagram-project"),
        tabText: "Flow Graph",
    },
    {
        pageType: PageType.ProgrammableNodes,
        tabIcon: solid("code"),
        tabText: "Programmable nodes",
    },
    {
        pageType: PageType.Projects,
        tabIcon: solid("globe"),
        tabText: "Projects",
    },
    {
        pageType: PageType.Project,
        tabIcon: solid("globe"),
        tabText: "Project", // TODO!
    },
    {
        pageType: PageType.Scans,
        tabIcon: solid("satellite-dish"),
        tabText: "Scans",
    },
    {
        pageType: PageType.Scan,
        tabIcon: solid("satellite-dish"),
        tabText: "Scan", // TODO!
    },
    {
        pageType: PageType.ScanSchedules,
        tabIcon: solid("calendar-days"),
        tabText: "Scheduled scans",
    },
    {
        pageType: PageType.Settings,
        tabIcon: solid("gears"),
        tabText: "Settings",
    },
    {
        pageType: PageType.Workflows,
        tabIcon: solid("diagram-project"),
        tabText: "Workflows",
    },
    {
        pageType: PageType.Workflow,
        tabIcon: solid("diagram-project"),
        tabText: "Workflow", // TODO!
    },

    // Admin
    {
        pageType: PageType.Admin_Notification,
        tabIcon: solid("bell-on"),
        tabText: "Notification",
    },
    {
        pageType: PageType.Admin_Notifications,
        tabIcon: solid("bell-on"),
        tabText: "Notifications",
    },
    {
        pageType: PageType.Admin_UsersAndGroups,
        tabIcon: solid("users"),
        tabText: "Users and groups",
    },
]