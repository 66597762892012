import { NotificationPageState, UsersAndGroupsPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function usersAndGroupsPageInitializer(payload: any): UsersAndGroupsPageState {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("users"),
        tabText: "Users and groups",
        params: [],
        usersAndGroups: {
            users: [],
            groups: [],
        }
    };
}