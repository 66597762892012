import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Modal, Select } from "antd";
import React, { useImperativeHandle } from "react";
import { RunWorkflowModalProps } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { runProjectWorkflowScan, selectWorkflowNamesAndIds } from "../store/slices/workflowsSlice";
import { AppDispatch, RootState } from "../store/configureAppStore";

const { Option } = Select;

const RunWorkflowModal = React.forwardRef((props: RunWorkflowModalProps, ref: any) => {
    const [visible, setVisible] = React.useState(false);
    const workflowNamesAndIds = useSelector<RootState, any>((state) => selectWorkflowNamesAndIds(state))
    const dispatch = useDispatch<AppDispatch>();

    const show = () => { setVisible(true); }
    useImperativeHandle(ref, () => ({ show }));

    const close = () => {
        setVisible(false);
    };

    const [workflowId, setWorkflowId] = React.useState("");

    return (
        <Modal
            className="schedule-workflow-modal"
            open={visible}
            title="Run workflow"
            width="385px"
            onOk={() => { }}
            onCancel={() => { close(); }}
            footer={[
                <Button key="back" type="text" onClick={() => close() }>Cancel</Button>,
                <Button
                    key="run"
                    type="primary"
                    onClick={() => { dispatch(runProjectWorkflowScan({ workflowId: workflowId, projectId: props.projectId })); close(); } }
                    icon={<FontAwesomeIcon icon={solid("play")} />}
                    disabled={workflowId === null || workflowId === ""}>Execute</Button>,
                ]}>
                <Select placeholder="Select Workflow" style={{ width: "100%" }} onSelect={(v: any) => setWorkflowId(v)}>
                {
                    workflowNamesAndIds.map((wni: any) => <Option value={wni.id}>{wni.name}</Option>)
                }
                </Select>
        </Modal>
    );
});

export default RunWorkflowModal;
