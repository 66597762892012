import { PageType } from "../../core/enums";
import * as Names from "../actionNames";
import { all, arrayDelete, arrayUpdateCb, last, select, single } from "../../core/reduxHelpers";
import { IPage, IPagesState, PageSetup } from "../../types";

import { projectPageReducer } from "./projectReducer";
import { scanPageReducer } from "./scanReducer";
import { notificationPageReducer, usersAndGroupsPageReducer } from "./adminReducer";

import flowGraphPageInitializer from "./pageInitializers/flowGraphPageInitializer";
import notificationPageInitializer from "./pageInitializers/notificationPageInitializer";
import notificationsPageInitializer from "./pageInitializers/notificationsPageInitializer";
import projectPageInitializer from "./pageInitializers/projectPageInitializer";
import projectsPageInitializer from "./pageInitializers/projectsPageInitializer";
import scanPageInitializer from "./pageInitializers/scanPageInitializer";
import scansPageInitializer from "./pageInitializers/scansPageInitializer";
// import workflowPageInitializer from "./pageInitializers/workflowPageInitializer";
// import workflowsPageInitializer from "./pageInitializers/workflowsPageInitializer";
import usersAndGroupsPageInitializer from "./pageInitializers/usersAndGroupsPageInitializer";
import settingsPageInitializer from "./pageInitializers/settingsPageInitializer";
import scanSchedulesPageInitializer from "./pageInitializers/scanSchedulesPageInitializer";
import filePageInitializer from "./pageInitializers/filePageInitializer";
import programmableNodesPageInitializer from "./pageInitializers/programmableNodesPageInitializer";
import _ from "lodash";
import { PageInfo } from "../../pageInfo";

const initialState: IPagesState = {
    tabs: [],
    selectedPageId: -1,
    latestOpenedTabId: -1,
    tabTruncations: { }
}

// TODO: Refactor tabId values - they should exist and tabs should have ids, but actions should be performed on
// the currently selected pages (state.selectedPageId). Only in special circumstances should the active page
// change data on another one - I believe we currently don't have such cases

// TODO: Refactor this better - put it in a function or something
const pagesSetup = new Map<PageType, PageSetup>();
pagesSetup.set(PageType.Projects, { pageInitializer: projectsPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Project, { pageInitializer: projectPageInitializer, pageReducer: projectPageReducer });
// pagesSetup.set(PageType.Workflows, { pageInitializer: workflowsPageInitializer, pageReducer: null });
// pagesSetup.set(PageType.Workflow, { pageInitializer: workflowPageInitializer, pageReducer: null });
// pagesSetup.set(PageType.FlowGraph, { pageInitializer: flowGraphPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Scans, { pageInitializer: scansPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Scan, { pageInitializer: scanPageInitializer, pageReducer: scanPageReducer });
pagesSetup.set(PageType.ScanSchedules, { pageInitializer: scanSchedulesPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Files, { pageInitializer: filePageInitializer, pageReducer: null });
pagesSetup.set(PageType.ProgrammableNodes, { pageInitializer: programmableNodesPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Settings, { pageInitializer: settingsPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Admin_Notifications, { pageInitializer: notificationsPageInitializer, pageReducer: null });
pagesSetup.set(PageType.Admin_Notification, { pageInitializer: notificationPageInitializer, pageReducer: notificationPageReducer });
pagesSetup.set(PageType.Admin_UsersAndGroups, { pageInitializer: usersAndGroupsPageInitializer, pageReducer: usersAndGroupsPageReducer });

// TODO: Refactor this with switch / case
export default function pageReducer(state: IPagesState = initialState, action: any) {
    switch (action.type) {
        case Names.reorderTabsActionName:
            return reorderTabs(state, action);
        case Names.selectTabActionName:
            return selectTab(state, action);
        case Names.openTabActionName:
            return openTab(state, action);
        case Names.closeTabActionName:
            return closeTab(state, action);
        case Names.changePageParamsActionName:
            return changePageParams(state, action);
        case Names.pageFinishedLoadingActionName:
            return pageFinishedLoading(state, action);
    }

    // Try to reduce a page. We will attempt to reduce a page if the action name starts with "page/"
    // and if the payload has the tabId. Otherwise the state is left unchanged.
    if (action.type.startsWith("page/")) {
        return reducePage(state, action);
    }

    return state;
}

// Reducers:

function reorderTabs(state: IPagesState, action: any) {
    const reorderedTabs = select(action.payload.orderedKeys, key => single(state.tabs, tab => `${tab.tabId}` === key));
    return {
        ...state,
        tabs: [...reorderedTabs]
    }
}

function selectTab(state: IPagesState, action: any) {
    if (all(state.tabs, tab => tab.tabId !== action.payload.tabId)) {
        // This is a workaround where sometimes the reselect gets triggered on close page although the page that was
        // closed was not actually selected. If the tab that is being selected doesn't exist, do nothing.
        return state;
    } else {
        return {
            ...state,
            selectedPageId: action.payload.tabId
        };
    }
}

function openTab(state: IPagesState, action: any) {
    action.payload.tabId = nextTabId(state.tabs);
    const newPage: IPage = {
        ...initPage(action.payload),
    };

    return {
        ...state,
        latestOpenedTabId: action.payload.tabId,
        selectedPageId: action.payload.tabId,
        tabs: [...state.tabs, newPage]
    };
}

function closeTab(state: IPagesState, action: any) {
    var newSelectedPageId = -1;
    if (action.payload.tabId !== state.selectedPageId) {
        newSelectedPageId = state.selectedPageId;
    } else {
        var selectedPageArrayIndex = state.tabs.findIndex(t => t.tabId === state.selectedPageId);
        if (selectedPageArrayIndex != -1 && state.tabs.length > 1) {
            if (selectedPageArrayIndex < state.tabs.length - 1) {
                newSelectedPageId = state.tabs[selectedPageArrayIndex + 1].tabId;
            } else {
                newSelectedPageId = state.tabs[selectedPageArrayIndex - 1].tabId
            }
        }
    }

    return {
        ...state,
        selectedPageId: newSelectedPageId,
        tabs: arrayDelete(state.tabs, (tab: IPage) => tab.tabId === action.payload.tabId)
    }
}

function changePageParams(state: IPagesState, action: any) {
    return {
        ...state,
        tabs: arrayUpdateCb(
            state.tabs,
            tab => tab.tabId === state.selectedPageId,
            tab => ({
                params: { ...tab.params, ...action.payload.params }
            })
        )
    }
}

// TODO: This might be obsolete now that we are looking if the actual objects have been initialized instead of isLoading flag
function pageFinishedLoading(state: IPagesState, action: any) {
    return {
        ...state,
        tabs: arrayUpdateCb(
            state.tabs,
            tab => tab.tabId === state.selectedPageId,
            tab => ({
                isLoading: false
            })
        )
    };
}

function reducePage(state: IPagesState, action: any) {
    return {
        ...state,
        tabs: arrayUpdateCb(
            state.tabs,
            tab => tab.tabId === state.selectedPageId,
            // `reducePage` call must not be cloned using the "..." operator as it on some
            // cases leaves the tab intact. We shouldn't redraw in that case.
            tab => performReducePage(tab, action),
        )
    };
}

// Utilities:

function nextTabId(tabs: IPage[]) {
    const maxId = tabs.reduce((maxId, tab) => Math.max(tab.tabId, maxId), -1);
    return maxId + 1;
}

function initPage(payload: any): IPage {
    const pageSetup = pagesSetup.get(payload.pageType);
    if (pageSetup && pageSetup.pageInitializer) {
        return pageSetup.pageInitializer(payload);
    } else {
        const newPageSetup = PageInfo.find((pi) => pi.pageType === payload.pageType)
        if (newPageSetup) {
            return { ...newPageSetup, tabId: payload.tabId, params: payload.data }
        } else {
            throw Error(`Initializer for type "${PageType[payload.pageType]}" doesn't exist.`)
        }
    }
}

function performReducePage(tabState: any, action: any): any {
    if (tabState.pageType) {
        const pageSetup = pagesSetup.get(tabState.pageType);
        if (pageSetup && pageSetup.pageReducer) {
            return pageSetup.pageReducer(tabState, action);
        }
    }
    return tabState;
}
