import { RuleObject } from "antd/lib/form";
import cidrRegex from "cidr-regex";
import ipRegex from "ip-regex";
import isValidDomain from "is-valid-domain";
import { DataType } from "./enums";

const validateIpAndPort = (address: string, type: string, split: string, ipCheck: (ip: string) => boolean) => {
    if (!address) return Promise.reject(new Error('Missing address and port'));
    const items = address.split(split, 2)
    if (items.length != 2) return Promise.reject(new Error('Missing port'));
    if (!ipCheck(items[0])) return Promise.reject(new Error(`${type} address looks faulty`));
    if (!/^[0-9]+$/.test(items[1]) || parseInt(items[1]) < 0 || parseInt(items[1]) > 65535) return Promise.reject(new Error('Port looks faulty'));

    return Promise.resolve();
}

const validateAsn = (asn: string) => {
    const isValid = /^[0-9]+$/.test(asn);
    const number = isValid ? Number(asn) : -1;
    return isValid && number >= 0 && number <= 4294967295 ? Promise.resolve() : Promise.reject(new Error('ASN looks faulty'));
}

export const SeedResourceValidations = new Map<DataType, RuleObject>([
    [DataType.ASN, { validator: (_: any, value) => validateAsn(value), warningOnly: true }],
    [DataType.CIDRv4, { validator: (_: any, value) => cidrRegex.v4({ exact: true }).test(value) ? Promise.resolve() : Promise.reject(new Error('CIDRv4 looks faulty')), warningOnly: true }],
    [DataType.CIDRv6, { validator: (_: any, value) => cidrRegex.v6({ exact: true }).test(value) ? Promise.resolve() : Promise.reject(new Error('CIDRv6 looks faulty')), warningOnly: true }],
    [DataType.IPv4, { validator: (_: any, value) => ipRegex.v4({ exact: true }).test(value) ? Promise.resolve() : Promise.reject(new Error('IPv4 address looks faulty')), warningOnly: true }],
    [DataType.IPv4WithPort, { validator: (_: any, value) => validateIpAndPort(value, "IPv4", ":", (ip) => ipRegex.v4({ exact: true }).test(ip)), warningOnly: true }],
    [DataType.IPv6, { validator: (_: any, value) => ipRegex.v6({ exact: true }).test(value) ? Promise.resolve() : Promise.reject(new Error('IPv6 address looks faulty')), warningOnly: true }],
    [DataType.IPv6WithPort, { validator: (_: any, value) => validateIpAndPort(value, "IPv6", "p", (ip) => ipRegex.v6({ exact: true }).test(ip)), warningOnly: true }],
    [DataType.Domain, { validator: (_: any, value) => isValidDomain(value, { subdomain: true, wildcard: true, allowUnicode: true, topLevel: false }) ? Promise.resolve() : Promise.reject(new Error('Domain looks faulty')), warningOnly: true }],
    [DataType.Url, { validator: (_: any, value) => { try { new URL(value); return Promise.resolve(); } catch { return Promise.reject(new Error('URL looks faulty')); } }, warningOnly: true }],
]);