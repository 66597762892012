import { IconName } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Table, Tag, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GutteredPage from '../../components/pageInterfaces/GutteredPage';
import DangerousConfirmButton from '../../components/ui/DangerousConfirmButton';
import { UserGroupType } from '../../core/enums';
import { extractTitle } from '../../core/markdownHelpers';
import { single } from '../../core/reduxHelpers';
import { createNotificationTabAction, deleteNotificationThunk, editNotificationTabAction } from '../../store/actions/adminActions';
import { selectNotificationsPage } from '../../store/selectors';
import { IApplicationState, NotificationsPageDispatchProps, NotificationsPageOwnProps, NotificationsPageProps, NotificationsPageStateProps, NotificationViewModel } from '../../types';
import { UsersAndGroupsToSelection } from '../../core/miscHelper';

const { Text } = Typography;

function NotificationsPage(props: NotificationsPageProps) {
    if (!props.notifications) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    return (
        <GutteredPage>
            <Row>
                <Col span={12}>
                    <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={solid("diagram-project")} />}
                        onClick={() => props.createNotificationTab()}>
                            New Notification
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={props.notifications || []}
                        rowKey="id"
                        pagination={{position: ["bottomLeft"]}}
                        scroll={{ x: true }}>
                        <Column title="Text" dataIndex="markdownText" ellipsis={true} render={(text: any, vm: NotificationViewModel) => <span style={{ maxWidth: "300px" }} className='ellipsis'><FontAwesomeIcon icon={["fas", vm.iconName as IconName]} />{extractTitle(text)}</span>} />
                        <Column title="Auto" dataIndex="shouldDisplay" width={90} render={(flag: boolean) => flag ? <div style={{textAlign: "center"}}><FontAwesomeIcon icon={solid("check-circle")} /></div> : <></>} />
                        <Column title="Banner" dataIndex="shouldDisplayOnAlertBanner" width={90} render={(flag: boolean) => flag ? <div style={{textAlign: "center"}}><FontAwesomeIcon icon={solid("check-circle")} /></div> : <></>} />
                        <Column title="Active since" dataIndex="activeStart" width={170} render={(date: Date) => new Date(date).toLocaleString()} />
                        <Column title="Active until" dataIndex="activeEnd" width={170} render={(date: Date) => new Date(date).toLocaleString()} />
                        <Column title="Users and Groups" dataIndex="userGroupAssignmentIds" render={(userAndGroupIds: string[]) => (
                            <>
                            {(userAndGroupIds || []).map((ug, idx) => {
                                // This might have to be chaged if we ever implement partial users and groups loading
                                const item = single(UsersAndGroupsToSelection(props.usersAndGroups) || [], ugElt => ugElt.id == ug);
                                var icon;
                                switch (item.type) {
                                    case UserGroupType.Everyone: icon = solid("building-user"); break;
                                    case UserGroupType.Group: icon = solid("people-group"); break;
                                    case UserGroupType.User: icon = solid("user"); break;
                                    default: icon = solid("question-circle"); break;
                                }
                                return (
                                    <Tag key={idx}>
                                        <FontAwesomeIcon icon={icon} />&nbsp;&nbsp;{item.name}
                                    </Tag>
                                );
                            })}
                            </>
                        )} />
                        <Column title="Actions" dataIndex="id" width="120px" render={(id: string) => (
                            <>
                                <Button
                                    type="text"
                                    icon={<FontAwesomeIcon icon={solid("pen-to-square")}
                                    onClick={() => props.editNotificationTab(id)} />} />
                                <DangerousConfirmButton
                                    popupText="Delete notification?"
                                    onConfirm={() => props.deleteNotification(id)}
                                    icon={solid("trash-can")} />
                            </>
                        )}/>
                    </Table>
                </Col>
            </Row>
        </GutteredPage>
    );
}

export default connect<NotificationsPageStateProps, NotificationsPageDispatchProps, NotificationsPageOwnProps, IApplicationState>(
    (state, ownProps) => selectNotificationsPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        createNotificationTab: createNotificationTabAction,
        editNotificationTab: editNotificationTabAction,
        deleteNotification: deleteNotificationThunk,
    }, dispatch)
)(NotificationsPage)