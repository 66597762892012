import { NotificationsPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function notificationsPageInitializer(payload: any): NotificationsPageState {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("bell-on"),
        tabText: "Notifications",
        params: []
    };
}