import * as React from 'react';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Col, ConfigProvider, Empty, Modal, Popover, Row, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import DangerousConfirmButton from '../components/ui/DangerousConfirmButton';
import { IApplicationState, ProgrammableNode, ProgrammableNodeWorkflow, ProgrammableNodesPageDispatchProps, ProgrammableNodesPageOwnProps, ProgrammableNodesPageProps, ProgrammableNodesPageStateProps } from '../types';
import { connect } from 'react-redux';
import { selectProgrammableNodesPage } from '../store/selectors';
import { bindActionCreators } from 'redux';
import { deleteProgrammableNodeThunk, importProgrammableNodeThunk } from '../store/actions/programmableNodesActions';
import { DataNode } from 'antd/lib/tree';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import { Key } from 'react';
import { mapData } from '../core/treeHelper';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/configureAppStore';
import { openViewWorkflowTab } from '../store/slices/workflowsSlice';

function ProgrammableNodesPage(props: ProgrammableNodesPageProps) {
    if (props.isLoading) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    const dispatch = useDispatch<AppDispatch>();

    const [showModal, setShowModal] = React.useState(false);
    const [selectedKey, setSelectedKey] = React.useState<Key | undefined>(undefined);
    const [treeData, setTreeData] = React.useState<DataNode[]>([]);

    React.useEffect(() => {
        setTreeData(mapData(props.manifestTreeData));
    }, [ props.manifestTreeData ]);

    const renderWorkflowsPopup = (workflows: Array<ProgrammableNodeWorkflow>) => <>
        {
            !workflows || workflows.length == 0
                ? <>Not used.</>
                : workflows.map(w => <div>
                    <Button
                        type="link"
                        icon={<FontAwesomeIcon icon={solid("diagram-project")} />}
                        onClick={() => openViewWorkflowTab(dispatch, w.workflowId || "")}>
                            {w.workflowName}
                    </Button>
                </div>)
        }
    </>;

    return (
        <GutteredPage>
            <Row>
                <Col span={12}>
                    <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={solid("file-import")} />}
                        onClick={() => setShowModal(true)}>
                            Import programmable node
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No programmable nodes imported"/>}>
                        <Table
                            dataSource={props.nodes}
                            rowKey="id"
                            pagination={{position: ["bottomLeft"]}}
                            scroll={{ x: true }}>
                            <Column title="Node id" dataIndex="nodeId" />
                            <Column title="Node name" dataIndex="nodeName" />
                            <Column title="Author" dataIndex="author" />
                            <Column title="Version" dataIndex="version" />
                            <Column title="Used in nodes" dataIndex="usedInWorkflows" render={(workflows: Array<ProgrammableNodeWorkflow>) => (
                                <Popover content={renderWorkflowsPopup(workflows)} placement="bottom" showArrow arrowPointAtCenter>
                                    <Button type='dashed' icon={<FontAwesomeIcon icon={regular("magnifying-glass")} />}>View</Button>
                                </Popover>
                            )}/>
                            <Column title="Actions" width="120px" render={(text, record: ProgrammableNode) => (
                                <DangerousConfirmButton
                                    popupText={<>Delete custom node?</>}
                                    icon={solid("trash-can")}
                                    isInProgress={record.isDeleteInProgress}
                                    onConfirm={() => { props.deleteProgrammableNode(record.nodeId) }} />
                            )}/>
                        </Table>
                    </ConfigProvider>
                </Col>
            </Row>
            <Modal
                title="Select manifest file"
                open={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button type="primary" disabled={!selectedKey} onClick={() => {
                        props.importProgrammableNode(selectedKey ?? "")
                        setShowModal(false);
                    }}>Select</Button>
                ]}>
                {
                    treeData.length !== 0
                        ? <DirectoryTree
                            className="filesystem-tree filesystem-tree-modal"
                            showIcon
                            selectable
                            // draggable
                            blockNode
                            treeData={treeData}
                            titleRender={(node) => <>{node.title}</>}
                            onSelect={(keys, info) => setSelectedKey(keys[0])} />
                        : <div className="filesystem-tree-empty">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No manifest files found" />
                        </div>
                }
            </Modal>
        </GutteredPage>
    );
}


export default connect<ProgrammableNodesPageStateProps, ProgrammableNodesPageDispatchProps, ProgrammableNodesPageOwnProps, IApplicationState>(
    (state, ownProps) => selectProgrammableNodesPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        deleteProgrammableNode: deleteProgrammableNodeThunk,
        importProgrammableNode: importProgrammableNodeThunk,
    }, dispatch)
)(ProgrammableNodesPage)