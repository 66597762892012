import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlowGraphPageProps, Popup, Workflow, WorkflowNodeData } from '../types';
import ReactFlow, { Node, Background, Controls, ConnectionLineType, BackgroundVariant, ReactFlowProvider, OnConnectStartParams, addEdge, Connection, useNodesState, useEdgesState, NodeChange, useReactFlow } from 'reactflow';
import SurfacePage from '../components/pageInterfaces/SurfacePage';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import DefaultNode from '../components/graphElements/nodes/DefaultNode';
import DefaultEdge from '../components/graphElements/edges/DefaultEdge';
import FlowGraphZoomMenu from '../components/FlowGraphZoomMenu';
import NodeBlueprintsListModal from '../components/NodeBlueprintsListModal';
import { AppDispatch, RootState } from '../store/configureAppStore';
import { selectWorkflow, fetchWorkflow, updateFlowGraph, beginConnectionValidation, endConnectionValidation } from '../store/slices/workflowsSlice';

import 'reactflow/dist/style.css';
import { mapNewEdge } from '../core/flowGraphHelpers';
import { nanoid } from '@reduxjs/toolkit';

const nodeTypes = { defaultNode: DefaultNode };
const edgeTypes = { defaultEdge: DefaultEdge };

export default function FlowGraphPage(props: FlowGraphPageProps) {
    return (
        <ReactFlowProvider>
            <FlowGraphPageInternal tabId={props.tabId} />
        </ReactFlowProvider>
    )
}

function FlowGraphPageInternal(props: FlowGraphPageProps) {

    const dispatch = useDispatch<AppDispatch>();
    const id = nanoid();

    const pageData = useSelector<RootState, any>(state => state.pages.tabs.find(t => t.tabId === props.tabId)?.params);
    const workflow = useSelector<RootState, Workflow>(state => selectWorkflow(state, props.tabId, pageData.workflowId ?? ""));

    React.useEffect(() => {
        if (!workflow.isDeleted && !workflow.isCreateMode) {
            dispatch(fetchWorkflow({ workflowId: workflow.workflowId }));
        }
    }, []);

    const nodesPopup = React.createRef();
    const openAddNodeModal = () => {
        if (nodesPopup.current) {
            (nodesPopup.current as Popup).show();
        }
    };

    const [nodes, setNodes, onNodesChange] = useNodesState(workflow.nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(workflow.edges);
    const reactFlow = useReactFlow();
    const onConnect = useCallback((edge: Connection) => { setEdges((eds) => addEdge(mapNewEdge(edge), eds)); }, []);
    const addNode = (node: Node<WorkflowNodeData>) => setNodes([...nodes, node]);

    const onNodesChangeIntermediary = (changes: NodeChange[]) => {
        onNodesChange(changes);
    }

    return (
        <SurfacePage>
            {/* This svg is the blue arrow for selected links. `Position: absolute` hides unwanted svg element */}
            <svg style={{ position: "absolute" }}>
                <defs>
                    <marker className="react-flow__arrowhead" id="react-flow__arrowclosed__selected" markerWidth="12.5" markerHeight="12.5" viewBox="-10 -10 20 20" orient="auto" refX="0" refY="0">
                        <polyline stroke="#177ddc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" fill="#177ddc" points="-5,-4 0,0 -5,4 -5,-4"></polyline>
                    </marker>
                </defs>
            </svg>
            <div style={{ position: "absolute" }}>
                <Button
                    type="primary"
                    shape="circle"
                    size="large"
                    className="flow-chart-button large"
                    icon={<FontAwesomeIcon icon={solid("plus")} />}
                    onClick={() => openAddNodeModal()}/>
                <Button
                    type="text"
                    shape="circle"
                    size="large"
                    className="flow-chart-button"
                    icon={<FontAwesomeIcon icon={solid("save")} />}
                    onClick={() => {
                        debugger
                        var nodes = reactFlow.getNodes();
                        var edges = reactFlow.getEdges();

                        dispatch(updateFlowGraph({
                            workflowId: workflow.workflowId,
                            nodes: nodes,
                            edges: edges,
                        }));
                    }} />
            </div>
            <NodeBlueprintsListModal ref={nodesPopup} workflowId={pageData.workflowId} addNodeToGraph={addNode} />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChangeIntermediary}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                snapToGrid={true}
                proOptions={{ hideAttribution: true }}
                snapGrid={[10, 10]}
                minZoom={0.1}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                deleteKeyCode={"Delete"}
                // TODO: Finish up validating connections on Any type - check MatchAnyTypes property in
                // NodeBlueprintBase.cs. This should be validated and fixed on edge create, delete, and
                // update.
                connectionLineStyle={{ stroke: "#CCC", strokeWidth: "2px", strokeDasharray: "6 6" }}
                connectionLineType={ConnectionLineType.SmoothStep}
                onConnectStart={(_: any, params: OnConnectStartParams) => {
                    const node = reactFlow.getNode(params.nodeId || "");
                    dispatch(beginConnectionValidation({ node: node, handleId: params.handleId, handleType: params.handleType }));
                }}
                onConnectEnd={(_: any) => { dispatch(endConnectionValidation()); }}>
                <Background
                    variant={BackgroundVariant.Dots}
                    color='#ddd'
                    gap={10}
                    size={1}
                    id={id} />
                    <Controls
                        showFitView={false}
                        showZoom={false}
                        showInteractive={false}>
                        <FlowGraphZoomMenu />
                    </Controls>
            </ReactFlow>
        </SurfacePage>
    );
}
