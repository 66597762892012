import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function MenuButton(props: any) {
    return (
        <button
            className={['sub-menu-button', ...props.classNames].join(' ')}
            style={{width: props.size, height: props.size, ...props.style}}
            onClick={props.onClick}>
            <FontAwesomeIcon icon={props.icon} />
        </button>
    );
}

MenuButton.defaultProps = {
    classNames: [],
    style: {}
}