import * as React from 'react';
import { GeneralEditorProps, MemberOfViewModel } from '../../types';
import { Avatar, Col, Descriptions, Row, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureAppStore';
import { IconType } from '../../core/enums';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const GeneralEditorComponent = (props: GeneralEditorProps) => {

    const claims = useSelector<RootState, any>((state) => state.auth.claims);
    const userGroups = useSelector<RootState, MemberOfViewModel[]>((state) => state.auth.memberOf);
    const userGroupTags = <>
        {userGroups.map(ug => {
            var icon: any;
            switch (ug.iconType) {
                case IconType.FontAwesome: icon = <FontAwesomeIcon icon={["fas", ug.icon as IconName]} />
            }
            return <Tag color={ug.color} icon={icon}>{ug.name}</Tag>;
        })}
    </>

    return (
    <Row>
        <Col span={4} style={{textAlign: "center"}}>
            <Avatar size={100} icon={<FontAwesomeIcon icon={regular("user")} />} style={{}} /><br />
        </Col>
        <Col span={20}>
            <Descriptions size='small' column={1}>
                <Descriptions.Item label="User name">{claims.userName}</Descriptions.Item>
                <Descriptions.Item label="Full name">{claims.fullName}</Descriptions.Item>
                <Descriptions.Item label="Email">{claims.email}</Descriptions.Item>
                <Descriptions.Item label="Member of">{userGroupTags}</Descriptions.Item>
            </Descriptions>
        </Col>
    </Row>)
};

export default () => ({
    displayComponent: (setCurrentKeyHasChanges: (changed: boolean) => void) =>
        <GeneralEditorComponent setCurrentKeyHasChanges={setCurrentKeyHasChanges} />,
    displayTitle: () => "General",
});