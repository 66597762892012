import { IPage, ScansPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function scansPageInitializer(payload: IPage): ScansPageState {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("satellite-dish"),
        tabText: "Scans",
        scans: [],
        params: [],
    };
}