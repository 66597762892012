import { Button, Col, Form, Input, Row } from 'antd';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Workflow, WorkflowEditProps } from "../types";
import MarkdownFormatter from './ui/MarkdownFormatter';
import DangerousConfirmButton from './ui/DangerousConfirmButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createWorkflow, deleteWorkflow, updateWorkflow, openEditFlowGraphTab } from '../store/slices/workflowsSlice';
import { AppDispatch } from '../store/configureAppStore';

export default function WorkflowEdit(props: WorkflowEditProps) {

    const dispatch = useDispatch<AppDispatch>();

    const [preview, setPreview] = React.useState(false);
    const [creating, setCreating] = React.useState(false);
    const [form] = Form.useForm();
    const isDisabled = props.workflow.isDeleted;

    return (
        <Row>
            <Col span={24}>
                <Form
                    autoComplete="off"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    form={form}
                    initialValues={props.workflow}
                    onFinish={(values: any) => {
                        const record: Workflow = { ...props.workflow, ...values };
                        if (props.workflow.isCreateMode) {
                            dispatch(createWorkflow({ workflow: record }));
                        } else {
                            dispatch(updateWorkflow({ workflow: record }));
                        };
                    }}>
                    <Form.Item label="Workflow Name" name="name" rules={[{ required: true, message: 'Workflow Name required.' }]}>
                        <Input placeholder="Example workflow" disabled={isDisabled} showCount maxLength={200} />
                    </Form.Item>
                    <Form.Item label="Workflow Description" name="description">
                        {
                            preview
                                ? <MarkdownFormatter>{form.getFieldValue("description")}</MarkdownFormatter>
                                : <Input.TextArea className="markdown-editor" placeholder="Here comes workflow description in markdown" disabled={isDisabled} showCount maxLength={10000} />
                        }
                    </Form.Item>
                    <Button type="text" onClick={() => setPreview(!preview)} disabled={isDisabled} style={{ float: "right", marginBottom: "10px" }}>
                        {preview ? "Edit" : "Preview"}
                    </Button>
                    <div style={{ clear: "both" }}></div>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={props.workflow.isSaveInProgress || (props.workflow.isCreateMode && creating)}
                            onClick={() => setCreating(true)}
                            disabled={isDisabled}>
                                {props.workflow.isCreateMode ? "Create workflow" : "Save workflow"}
                        </Button>
                        {
                            props.workflow.isEditMode
                                ? <DangerousConfirmButton
                                    popupText={<>Delete workflow?</>}
                                    icon={solid("trash-can")}
                                    isInProgress={props.workflow.isDeleteInProgress}
                                    disabled={isDisabled}
                                    onConfirm={ () => dispatch(deleteWorkflow({ workflowId: props.workflow.workflowId })) }>Delete</DangerousConfirmButton>
                                : <></>
                        }
                        {
                            props.workflow.isEditMode
                                ? <Button
                                    type='text'
                                    disabled={isDisabled}
                                    icon={<FontAwesomeIcon icon={solid("diagram-project")} />}
                                    onClick={() => { openEditFlowGraphTab(dispatch, props.workflow.workflowId) }}>Edit flow chart</Button>
                                : <></>
                        }
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
