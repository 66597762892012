import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd';
import CenteredComponentPage from '../../components/pageInterfaces/CenteredComponentPage';
import { SignUpProps } from '../../types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/configureAppStore';
import { signUpThunk } from '../../store/actions/authActions';

export default function SignUpPage(props: SignUpProps) {

    const dispatch = useDispatch<AppDispatch>();

    return (
        <CenteredComponentPage>
            <div  className="sign-up-form">
                <Row>
                    <Col span={4} offset={2}>
                        <img className="logo" src="ScourNomadLogo.png" />
                        <h3>ScourNomad</h3>
                    </Col>
                    <Col span={18}>
                        <h1>Sign-up form</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={async (values: any) => {
                                dispatch(signUpThunk({
                                    userName: values.userName,
                                    fullName: values.fullName,
                                    email: values.email,
                                    isFreeTrial: props.isFreeTrial,
                                    marketplaceToken: props.marketplaceToken ?? ""
                                }))
                            }}
                            autoComplete="off">

                            <Form.Item label="User Name" name="userName" rules={[
                                { required: true, message: 'Please input your username' }]}>
                                <Input maxLength={50} />
                            </Form.Item>

                            <Form.Item label="Full Name" name="fullName" rules={[{ required: true, message: 'Please input your full name' }]}>
                                <Input maxLength={50} />
                            </Form.Item>

                            <Form.Item label="E-mail address" name="email" rules={[
                                { required: true, message: 'Please input your e-mail address' },
                                { type: "email", message: 'You have entered invalid e-mail address' }]}>
                                <Input maxLength={50} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                Sign up
                                </Button>
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 6, span: 16 }} style={{ fontSize: "10px" }}>
                                By signing up and using the ScourNomad service you accept the <b>Terms and Conditions</b> and <b>Privacy policy</b> documents.<br /><br />
                                <a href="https://scournomad.com/terms-and-conditions" className="license-link" target="_blank">Terms and Conditions</a>&nbsp;&nbsp;&nbsp;
                                <a href="https://scournomad.com/privacy-policy" className="license-link" target="_blank">Privacy policy</a>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </CenteredComponentPage>
    );
}
