import { createAction } from "@reduxjs/toolkit";
import { communication } from "../../core/communication";
import * as Names from '../actionNames';
import { message, notification } from 'antd';
import { DEFAULT_ERROR_MESSAGE_DURATION } from "../../constants";
import { LicenseAgreementType } from "../../core/enums";
import { SignUpForm } from "../../types";

// Simple Actions
export const updateAuthDataAction = createAction(Names.updateAuthDataActionName, (authData: any) => ({ payload: { authData: authData }}));
export const updateClaimsAction = createAction(Names.updateClaimsActionName, (authData: any) => ({ payload: { authData: authData }}));
export const updateFeaturesAction = createAction(Names.updateFeaturesActionName, (authData: any) => ({ payload: { features: authData.userFeatures }}));
export const updateNotificationsAction = createAction(Names.updateNotificationsActionName, (authData: any) => ({ payload: { notifications: authData.userNotifications }}));
export const markNotificationSeenAction = createAction(Names.markNotificationSeenActionName, (notificationId: string) => ({ payload: { notificationId: notificationId } }));
export const changeNotificationDisplayAction = createAction(Names.changeNotificationDisplayActionName, (notificationId: string, visible: boolean) => ({ payload: { notificationId: notificationId, visible: visible } }));
export const signOutAction = createAction(Names.signOutActionName, () => ({ payload: { } }));
export const loadInitDataAction = createAction(Names.loadInitDataActionName, (data: any) => ({ payload: { ...data } }))
export const setLicenseAgreementHandledAction = createAction(Names.setLicenseAgreementHandledActionName, (type: LicenseAgreementType | undefined) => ({ payload: { type: type } }))

// Thunks
export function signInThunk(userName: string, password: string): any {
    return async (dispatch: any) => {
        const response = await communication.post("/api/v1/login", { userName: userName, password: password });
        const resultResponse = response.result;

        if (resultResponse.errorMessage) {
            message.error(resultResponse.errorMessage, DEFAULT_ERROR_MESSAGE_DURATION);
        }

        communication.resetConnections(resultResponse.accessToken);

        dispatch(updateAuthDataAction(resultResponse));
        dispatch(updateClaimsAction(resultResponse));
        dispatch(updateFeaturesAction(resultResponse));
        dispatch(updateNotificationsAction(resultResponse));
    }
}

export function signUpThunk(signUpForm: SignUpForm): any {
    return async (dispatch: any) => {
        const response = await communication.post("/api/v1/signUp", signUpForm);
        const resultResponse = response.result;

        if (resultResponse.errorMessage) {
            message.error(resultResponse.errorMessage, DEFAULT_ERROR_MESSAGE_DURATION);
        } else {
            // Removing get params
            history.replaceState({}, document.title, "/");
            notification.success({
                message: "Successfully signed up!",
                description: "Please check your email for further instructions"
            });
        }

        communication.resetConnections(resultResponse.accessToken);

        dispatch(updateAuthDataAction(resultResponse));
    }
}

export function forceResetPasswordThunk(sessionID: string, userName: string, password: string): any {
    return async (dispatch: any) => {
        const response = await communication.post("/api/v1/forceResetPassword", { userName: userName, password: password, sessionID: sessionID });
        const resultResponse = response.result;

        if (resultResponse.errorMessage) {
            message.error(resultResponse.errorMessage, DEFAULT_ERROR_MESSAGE_DURATION);
        }

        communication.resetConnections(resultResponse.accessToken);

        dispatch(updateAuthDataAction(resultResponse));
        dispatch(updateClaimsAction(resultResponse));
        dispatch(updateFeaturesAction(resultResponse));
        dispatch(updateNotificationsAction(resultResponse));
    }
}

export function refreshTokenThunk(): any {
    return async (dispatch: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('x-amzn-marketplace-token');

        const response = await communication.post(`/api/v1/refreshToken?x-amzn-marketplace-token=${myParam}`, { });
        const resultResponse = response.result;

        communication.resetConnections(resultResponse.accessToken);

        if (resultResponse.accessToken) {
            dispatch(updateAuthDataAction(resultResponse));
            dispatch(updateClaimsAction(resultResponse));
            dispatch(updateFeaturesAction(resultResponse));
            dispatch(updateNotificationsAction(resultResponse));
        } else {
            dispatch(signOutAction());
        }
    }
}

export function logoutThunk(): any {
    return async (dispatch: any) => {
        const response = await communication.post("/api/v1/logout", { });
        const resultResponse = response.result;

        communication.resetConnections(resultResponse.accessToken);
        dispatch(signOutAction());
    }
}

export function markNotificationSeenThunk(notificationId: string): any {
    return async (dispatch: any) => {
        const response = await communication
            .get(`/api/v1/notification/${notificationId}/markRead`)
            .then(r => r.isSuccessful && dispatch(markNotificationSeenAction(notificationId)));
    }
}

export function initializeDataThunk() {
    return async (dispatch: any) => {
        const response = await communication.get(`/api/v1/initData`);
        if (response.isSuccessful) {
            await dispatch(loadInitDataAction(response.result));
        }
    }
}

export function acceptLicenseAgreementThunk(type: LicenseAgreementType | undefined) {
    return async (dispatch: any) => {
        const response = await communication.post(`/api/v1/licenseAgreement/agree/${type}`);
        if (response.isSuccessful) {
            await dispatch(setLicenseAgreementHandledAction(type));
        }
    }
}
