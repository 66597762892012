import moment from "moment";
import { NotificationAlertType } from "../../core/enums";
import { any, arrayDelete, arrayUpdate } from "../../core/reduxHelpers";
import { IAdminState, NotificationPageState, NotificationViewModel, UsersAndGroupsPageState } from "../../types";
import * as Names from "../actionNames";

const defaultState: IAdminState = {
    notifications: null,
    usersAndGroups: {
        users: [],
        groups: [],
    },
}

function adminReducer(state: IAdminState = defaultState, action: any): IAdminState {
    switch (action.type) {
        case Names.loadNotificationsActionName:
            return loadNotifications(state, action.payload);
        case Names.updateNotificationInListActionName:
            return updateNotificationInList(state, action.payload);
        case Names.removeNotificationFromListActionName:
            return removeNotificationFromList(state, action.payload);
        default:
            return state;
    }
}

function notificationPageReducer(state: NotificationPageState, action: any): NotificationPageState {
    switch (action.type) {
        case Names.createNotificationActionName:
            return createNotification(state);
        case Names.editNotificationActionName:
            return editNotification(state, action.payload);
        default:
            return state;
    }
}

function usersAndGroupsPageReducer(state: UsersAndGroupsPageState, action: any): UsersAndGroupsPageState {
    switch (action.type) {
        case Names.loadUsersAndGroupsActionName:
            return loadUsersAndGroups(state, action.payload);
        case Names.updateGroupsForUserActionName:
            return updateGroupsForUser(state, action.payload);
        default:
            return state;
    }
}

export {
    adminReducer,
    notificationPageReducer,
    usersAndGroupsPageReducer
}

function loadNotifications(state: IAdminState, payload: any): IAdminState {
    return {
        ...state,
        notifications: payload.notifications,
        usersAndGroups: payload.usersAndGroups
    }
}

function updateNotificationInList(state: IAdminState, payload: NotificationViewModel): IAdminState {
    return {
        ...state,
        notifications: any(state.notifications || [], n => n.id === payload.id)
            ? arrayUpdate(state.notifications ?? [], (n: NotificationViewModel) => n.id == payload.id, payload)
            : [...state.notifications || [], payload],
    }
}

function removeNotificationFromList(state: IAdminState, payload: any): IAdminState {
    return {
        ...state,
        notifications: arrayDelete(state.notifications ?? [], (n: NotificationViewModel) => n.id == payload.notificationId),
    }
}

function createNotification(state: NotificationPageState): NotificationPageState {
    return {
        ...state,
        notification: {
            id: null,
            markdownText: "",
            iconName: "",
            shouldDisplay: false,
            shouldDisplayOnAlertBanner: false,
            isSeen: false,
            dateTimeCreated: new Date(),
            activeStart: null,
            activeEnd: null,
            alertType: NotificationAlertType.Info,
            userGroupAssignmentIds: []
        }
    }
}

function editNotification(state: NotificationPageState, payload: any): NotificationPageState {
    return {
        ...state,
        notification: {
            ...payload.notification,
            activeStart: payload.notification.activeStart
                ? moment(payload.notification.activeStart)
                : moment(),
            activeEnd: payload.notification.activeEnd
                ? moment(payload.notification.activeEnd)
                : moment(),
        }
    }
}




function loadUsersAndGroups(state: UsersAndGroupsPageState, payload: any): UsersAndGroupsPageState {
    return {
        ...state,
        usersAndGroups: {
            users: payload.users,
            groups: payload.groups
        }
    }
}

function updateGroupsForUser(state: UsersAndGroupsPageState, payload: any): UsersAndGroupsPageState {
    return {
        ...state,
        usersAndGroups: {
            ...state.usersAndGroups,
            users: arrayUpdate(state.usersAndGroups.users, u => u.userId === payload.userId, { groupIds: payload.groupIds })
        }
    }
}
