import * as React from 'react';
import { Button, Typography } from 'antd';
import LabeledHandle from './components/LabeledHandle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { EntryDef, ExitDef, Popup, WorkflowNodeData } from '../../../types';
import { IconType } from '../../../core/enums';
import FlowGraphNodeSettingsModal from '../../FlowGraphNodeSettingsModal';
import { NodeProps, useReactFlow } from 'reactflow';

const { Text, Paragraph } = Typography;

export default function DefaultNode(props: NodeProps<WorkflowNodeData>) {

    const reactFlow = useReactFlow();
    let icon: any;
    switch (props.data.blueprint.iconType) {
        case IconType.FontAwesome: icon = <FontAwesomeIcon className="node-icon node-icon-fontawesome" icon={props.data.blueprint.iconName} />; break;
        case IconType.Ant: // TODO: Add Ant icon when needed
        default:
            icon = <></>;
            break;
    }

    const nodesSettingsPopup = React.createRef();
    const openNodeSettingsModal = () => {
        if (nodesSettingsPopup.current) {
            (nodesSettingsPopup.current as Popup).show();
        }
    };

    const removeNode = () => {
        reactFlow.deleteElements({ nodes: [{ id: props.id }] });
    }

    return (
        <>
            <div>
                <div className="node-button-container">
                    <Button type="text" className="node-button" onClick={() => { openNodeSettingsModal(); }}><FontAwesomeIcon icon={solid("pen-to-square")} /></Button>
                    <Button type="text" className="node-button" onClick={() => { removeNode(); }}><FontAwesomeIcon icon={solid("xmark-large")} /></Button>
                </div>
                <span style={{maxWidth: "210px", maxHeight: "30px", display: "block"}}>
                    <Text strong className="node-name" style={{maxWidth: "210px"}} ellipsis={{ tooltip: `${props.data.blueprint.name}, ver: ${props.data.blueprint.version}` }}>
                        {props.data.blueprint.name}
                        <Text type="secondary">{props.data.blueprint.version}</Text>
                    </Text>
                </span>
            </div>
            <Paragraph className="node-description" ellipsis={{ rows: 2, tooltip: props.data.blueprint.description }}>{props.data.blueprint.description}</Paragraph>
            {/* TODO: Missing data types for validation */}
            <div className="node-target-connections">
                { props.data.blueprint.entries.map((e: EntryDef, index: number) => <LabeledHandle id={e.handleId} type="target" positionIndex={index} label={e.text} validDataContracts={e.dataContracts} />) }
            </div>
            <div className="node-source-connections">
                { props.data.blueprint.exits.filter((e: ExitDef) => !e.hidden).map((e: ExitDef, index: number) => <LabeledHandle id={e.handleId} type="source" positionIndex={index} label={e.text} validDataContracts={e.dataContracts} />) }
            </div>
            <div style={{ clear: "both" }}></div>
            <FlowGraphNodeSettingsModal ref={nodesSettingsPopup} workflowNodeId={props.data.workflowNodeId} data={props.data} />
        </>
    );
}
