import * as React from 'react';
import { connect } from 'react-redux';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import { IApplicationState, ScanNode, ScanNodeResult, ScanPageDispatchProps, ScanPageOwnProps, ScanPageProps, ScanPageStateProps } from '../types';
import { selectScanPage } from '../store/selectors';
import { Alert, Badge, Button, Descriptions, Popover, Tabs, Tag, Tooltip, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Content } from 'antd/lib/layout/layout';
import { ScanNodeShutdownStatus, ScanNodeStatus, ScanStatus } from '../core/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { bindActionCreators } from 'redux';
import { openViewProjectTabAction } from '../store/actions/projectActions';
import { deleteScanThunk, fetchScanJsonFileThunk, refreshScanNodeThunk } from '../store/actions/scanActions';
import ScanNodeResultDisplay from '../components/ScanNodeResultDisplay';
import DangerousConfirmButton from '../components/ui/DangerousConfirmButton';
import { getCurrentUnixTimestamp } from '../core/dateTimeHelper';
import moment from 'moment';
import { RIGHT_TABS_STYLE } from '../constants';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/configureAppStore';
import { openViewWorkflowTab } from '../store/slices/workflowsSlice';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function ScanPage(props: ScanPageProps) {
    const dispatch = useDispatch<AppDispatch>();
    const [duration, setDuration] = React.useState(moment.utc(0).format('HH:mm:ss'));

    React.useEffect(() => {
        const timer = setInterval(() => {
            var start = Date.parse(props.scan?.scanStart || "") || getCurrentUnixTimestamp();
            var end = Date.parse(props.scan?.scanEnd || "");
            var duration = !props.scan?.scanEnd
                ? moment.utc(Date.now() - start).format('HH:mm:ss')
                : moment.utc(end - start).format('HH:mm:ss');
            setDuration(duration);
        }, 1000);
        return () => clearInterval(timer);
    }, [props.scan?.scanEnd]);

    const deletedNotification = props.isScanDeleted
            ? <Alert
                message="Scan Deleted"
                description="This scan has already been deleted."
                type="error"
                icon={<FontAwesomeIcon icon={solid("trash-can")} />}
                showIcon />
            : <></>;

    const viewProjectWrapped = (projectId: string) => () => props.openViewProjectTab(projectId);
    const viewWorkflowWrapped = (workflowId: string) => () => openViewWorkflowTab(dispatch, workflowId);
    const tabChange = (tabKey: string) => {
        const node = props.scan?.scanNodes[tabKey];
        props.refreshScanNode(node?.scanId ?? "", node?.scanNodeId ?? "");
    }

    var statusTag = <Tag color="gray">Unknown</Tag>;
    var statusBadge = <Badge status="default" text="Unknown" />;

    switch (props.scan?.scanStatus)
    {
        case ScanStatus.SetUpInProgress:
        case ScanStatus.SetupSucceeded:
        case ScanStatus.ScanInProgress:
            statusTag = <Tag color="blue">In progress</Tag>;
            statusBadge = <Badge status="processing" text="In progress" />;
            break;
        case ScanStatus.ScanFinishedSuccess:
            statusTag = <Tag color="green">Succeeded</Tag>;
            statusBadge = <Badge status="success" text="Success" />;
            break;
        case ScanStatus.ScanFinishedNodeWarning:
            statusTag = <Tag color="green">Succeeded with warnings</Tag>;
            statusBadge = <Badge status="warning" text="Success with warnings" />;
            break;
        case ScanStatus.SetupFailed:
        case ScanStatus.ScanFinishedNodeBootUpFailed:
        case ScanStatus.ScanFinishedNodeError:
        case ScanStatus.ScanFinishedNodeException:
        case ScanStatus.ScanFinishedSystemError:
            statusTag = <Tag color="red">Failed</Tag>;
            statusBadge = <Badge status="error" text="Error" />;
            break;
        case ScanStatus.ScanSkipped:
            statusTag = <Tag color="#444">Skipped</Tag>;
            statusBadge = <Badge color="#444" text="Skipped" />;
            break;
    }

    return (
        <GutteredPage>
            {deletedNotification}
            <PageHeader
                title={props.scan?.scanName}
                className="site-page-header"
                tags={statusTag}
                extra={[
                    <>
                        <Button
                            type='primary'
                            icon={<FontAwesomeIcon icon={solid("download")} />}
                            disabled={props.isScanDeleted}
                            onClick={() => props.fetchScanJsonFile(props.scan?.scanId ?? "")}>Download</Button>
                        <DangerousConfirmButton
                            popupText='Delete scan?'
                            icon={solid("trash-can")}
                            disabled={props.isScanDeleted}
                            onConfirm={() => props.deleteScan(props.scan?.scanId ?? "")}>Delete</DangerousConfirmButton>
                    </>
                ]}>
                <Content>
                    <Descriptions layout="horizontal" column={2} bordered>
                        <Descriptions.Item label="Project:"><Button type="link" icon={<FontAwesomeIcon icon={solid("globe")} />} onClick={viewProjectWrapped(props.scan?.projectId || "")}>{props.scan?.projectName}</Button></Descriptions.Item>
                        <Descriptions.Item label="Workflow:"><Button type="link" icon={<FontAwesomeIcon icon={solid("diagram-project")} />} onClick={viewWorkflowWrapped(props.scan?.workflowId || "")}>{props.scan?.workflowName}</Button></Descriptions.Item>
                        <Descriptions.Item label="Started at:">{new Date(props.scan?.scanStart || "").toLocaleString()}</Descriptions.Item>
                        <Descriptions.Item label="Ended at:">{props.scan?.scanEnd ? new Date(props.scan?.scanEnd || "")?.toLocaleString() : "Running..."}</Descriptions.Item>
                        <Descriptions.Item label="Status:">{statusBadge}</Descriptions.Item>
                        <Descriptions.Item label="Duration:">{duration}</Descriptions.Item>
                        {/* TODO: Put this in but validate the messages are correct: <Descriptions.Item label="Message:">{props.scan?.message}</Descriptions.Item> */}
                    </Descriptions>
                </Content>
            </PageHeader>
            <Tabs
                tabPosition="right"
                tabBarStyle={RIGHT_TABS_STYLE}
                id="scan-node-results"
                className="ant-tabs-simple-list"
                onChange={tabChange}>
                {(_.values(props.scan?.scanNodes || {}) || []).map((node: ScanNode, idx: number) =>
                    <TabPane tab={getNodeTabStatus(node, node.nodeName)} key={node.scanNodeId}>
                        <Title level={4}>{node.nodeName}</Title>
                        <b>Status:&nbsp;&nbsp;&nbsp;</b>{getNodeMainStatus(node)}
                        {
                            !!node.scanNodeResults
                            ?   (node.scanNodeResults || []).map((nodeResult: ScanNodeResult, nrIdx: number) =>
                                <div key={`${idx}_${nrIdx}`} style={{marginTop: 20}}>
                                    <Title level={5}>{`Exit handle: ${nodeResult.exitName}`}</Title>
                                    <ScanNodeResultDisplay scanNodeResult={nodeResult} scanId={node.scanId} scanNodeId={node.scanNodeId} />
                                </div>)
                            :   (<div className="scan-node-results-loading"><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></div>)
                        }
                    </TabPane>
                )}
            </Tabs>
        </GutteredPage>
    );
}

function getNodeTabStatus(scanNode: ScanNode, tabName: string): any {
    var status = <Badge status="default" text={ tabName ?? "Unknown" } />;
    switch(scanNode.status) {
        case ScanNodeStatus.SetUp:
            status = <Badge color="#333" text={ tabName ?? "Pending boot-up" } />
            break;
        case ScanNodeStatus.BootUpInProgress:
        case ScanNodeStatus.BootUpSucceeded:
            status = <Badge color="#888" text={ tabName ?? "Booting up" } />
            break;
        case ScanNodeStatus.ScanInProgress:
            status = <Badge status="processing" text={ tabName ?? "In progress" } />
            break;
        case ScanNodeStatus.BootUpFailed:
        case ScanNodeStatus.ScanFinishedError:
        case ScanNodeStatus.ScanFinishedException:
            status = <Badge status="error" text={ tabName ?? "Error" } />
            break;
        case ScanNodeStatus.ScanFinishedWarning:
            status = <Badge status="warning" text={ tabName ?? "Warning" } />
            break;
        case ScanNodeStatus.ScanFinishedSuccess:
            status = <Badge status="success" text={ tabName ?? "Success" } />
            break;
    }

    if (scanNode.shutdownStatus == ScanNodeShutdownStatus.ShutdownError) {
        status = <Badge status="error" text={ tabName ?? "Shutdown error" } />
    }

    return status;
}

function getNodeMainStatus(scanNode: ScanNode): any {
    var status = <Badge status="default" text={ "Unknown" } />;
    var showMessage = <>
        <pre>{scanNode.message}</pre>
        <Tooltip trigger={"click"} title="Copied!">
            <Button type='dashed' icon={<FontAwesomeIcon icon={regular("copy")} />} onClick={() => navigator.clipboard.writeText(scanNode.message)}>Copy message</Button>
        </Tooltip>
    </>;
    var message = (msg: string) => scanNode.message
        ? <Popover content={showMessage} placement="bottom" showArrow arrowPointAtCenter>
            <Text code style={{width: "400px"}}>
                {msg}: {scanNode.message.length > 500 ? <>{scanNode.message.substring(0, 100)}...</> : <>{scanNode.message}</>}
            </Text>
          </Popover>
        : <Text code>{msg}</Text>;
    switch(scanNode.status) {
        case ScanNodeStatus.SetUp:
            status = <Badge color="#333" text={ message("Pending boot-up") } />
            break;
        case ScanNodeStatus.BootUpInProgress:
        case ScanNodeStatus.BootUpSucceeded:
            status = <Badge color="#888" text={ message("Booting up") } />
            break;
        case ScanNodeStatus.ScanInProgress:
            status = <Badge status="processing" text={ message("In progress") } />
            break;
        case ScanNodeStatus.BootUpFailed:
        case ScanNodeStatus.ScanFinishedError:
        case ScanNodeStatus.ScanFinishedException:
            status = <Badge status="error" text={ message("Error") } />
            break;
        case ScanNodeStatus.ScanFinishedWarning:
            status = <Badge status="warning" text={  message("Warning") } />
            break;
        case ScanNodeStatus.ScanFinishedSuccess:
            status = <Badge status="success" text={ message("Success") } />
            break;
    }

    if (scanNode.shutdownStatus == ScanNodeShutdownStatus.ShutdownError) {
        status = <Badge status="error" text={ message("Shutdown error") } />
    }

    return status;
}

export default connect<ScanPageStateProps, ScanPageDispatchProps, ScanPageOwnProps, IApplicationState>(
    (state, ownProps) => selectScanPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        openViewProjectTab: openViewProjectTabAction,
        fetchScanJsonFile: fetchScanJsonFileThunk,
        deleteScan: deleteScanThunk,
        refreshScanNode: refreshScanNodeThunk,
    }, dispatch)
)(ScanPage)