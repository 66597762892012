import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { store } from './store/configureAppStore';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core'
import { initializeCommunication } from './core/communication';
import initScanHub from './store/hubs/notificationsHub';

initializeCommunication(store);

// SignalR Hubs initializations
initScanHub();

// Misc and other libraries initializations
library.add(fas as IconPack); // TODO: This is probably the reason why bandwidth is being overused - fontawesome
library.add(fab as IconPack);


const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
    <Provider store={store}>
        <React.StrictMode>
                <App />
                {/* <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />} />
                    </Routes>
                </BrowserRouter> */}
        </React.StrictMode>
    </Provider>
);

registerServiceWorker();
