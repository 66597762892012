import React, { useImperativeHandle } from "react";
import MainPropEditor from "./MainPropEditor";
import { Button, Select, Col, Modal, Row, Switch, Tabs, notification, Cascader, Tooltip, Descriptions } from "antd";
import { FlowGraphNodeSettingsModalProps, PropDef, PropGroupDef, PropValue, SettingEditorError } from "../types";
import { arrayUpdate, dictDelete, dictUpdateByKey } from "../core/reduxHelpers";
import { DataType } from "../core/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { values } from "lodash";
import { useReactFlow } from "reactflow";
import { produce } from "immer";
import _ from "lodash";

const { TabPane } = Tabs;
const { Option } = Select;

const FlowGraphNodeSettingsModal = React.forwardRef((props: FlowGraphNodeSettingsModalProps, ref: any) => {
    const [revertPropValues, setRevertPropValues] = React.useState({ ...props.data.propValues });
    const [propValues, setPropValues] = React.useState({ ...props.data.propValues });
    const [isDataChanged, setIsDataChanged] = React.useState(false);
    const [validation, setValidation] = React.useState<any>([]);
    const [resetFormTrigger, setResetFormTrigger] = React.useState(0);
    const [visible, setVisible] = React.useState(false);
    const reactFlow = useReactFlow();

    const closeModal = () => { setVisible(false) };
    const show = () => { setVisible(true) };

    useImperativeHandle(ref, () => ({ show }));

    const key = 'dataChangedQuestion';

    // const propUpdater = (draftDict: Dictionary<PropValue>, id: number, updates: any) => {
    //     var value = draftDict[id];
    //     value = { ...value, ...updates };
    // }

    // React.useEffect(() => {
    //     setRevertPropValues({ ...props.data.propValues });
    // }, [props.data.propValues])

    const getPropTypeByKey = (key: number) => {
        return props.data.blueprint.props.find(pr => pr.id == key)?.propTypeDescriptor?.dataType;
    }

    const cancel = () => {
        if (isDataChanged) {
            notification.open({
                key,
                message: "The node settings have changed",
                icon: <FontAwesomeIcon icon={solid("question")} style={{ marginTop: "10px", color: "#177ddc" }} />,
                description: <>
                    Do you want to apply the changes?<br />
                    <div style={{ float: "right", marginTop: "20px" }}>
                        <Button type="text" onClick={() => { revert(); close(); notification.close(key); }}>Revert</Button>
                        <Button type="primary" onClick={() => { apply(); close(); notification.close(key); }}>Apply</Button>
                    </div>
                </>,
                duration: null,
            });
        } else {
            setIsDataChanged(false);
            revert();
            close();
        }
    }

    const revert = () => {
        setIsDataChanged(false);
        setResetFormTrigger(resetFormTrigger + 1);
        setPropValues(revertPropValues);
    }

    const close = () => {
        setIsDataChanged(false);
        closeModal()
    };

    const apply = () => {
        var errors = values(validation).filter((i: any) => i.status === "error");
        if (errors.length > 0) {
            notification.open({
                message: "Setting errors",
                description: errors[0].message,
                type: "error"
            });
        } else {
            const node = reactFlow.getNode(props.workflowNodeId);
            if (node) {
                node.data = produce(node.data, (draft: any) => {
                    _.values(propValues).forEach(element => {
                        draft.propValues[element.blueprintPropType] = element;
                    });
                })
            }
            setPropValues(propValues);
            setRevertPropValues(propValues);
            setIsDataChanged(false);
        }
    };

    const onPropSelectionChange = (key: number, checked: boolean) => {
        var updates: any = { isEnabled: checked, blueprintPropType: key };
        if (getPropTypeByKey(key) == DataType.Bool) {
            updates["value"] = JSON.stringify({ value: checked });
        }
        setIsDataChanged(true);
        setPropValues(dictUpdateByKey(propValues, key, updates));
    }

    const onPropValueChange = (key: number, value: string) => {
        setIsDataChanged(true);
        setPropValues(dictUpdateByKey(propValues, key, { isEnabled: true, blueprintPropType: key, value: value }));
    }

    const setErrorStatus = (fieldId: string, error: SettingEditorError) => {
        if (validation[fieldId]?.status !== error.status) {
            setValidation(dictUpdateByKey(validation, fieldId, error));
        }
    }

    const onPropValueWithExtraChange = (key: number, value: string, extraData: string) => {
        setIsDataChanged(true);
        setPropValues(dictUpdateByKey(propValues, key, { isEnabled: true, blueprintPropType: key, value: value, extraData: extraData }));
    }

    const onPropDropdownFlagChange = (key: number, flagDropdownId: number, flag: string) => {
        setIsDataChanged(true);
        setPropValues(dictUpdateByKey(propValues, key, { extraData: JSON.stringify({ flagDropdownId: flagDropdownId }) }));
        if (props.data) {
            props.data.blueprint.props = arrayUpdate(props.data.blueprint.props, a => a.id === key, { flag: flag })
        }
    }

    const getFlag = (propDef: PropDef, propValue?: PropValue) => {
        if (propDef.propTypeDescriptor.isFlagGroup) {
            const options = propDef.propTypeDescriptor.flagGroupEnumData;
            const extraData = propValue && propValue.extraData && propValue.extraData[0] === '{'
                ? JSON.parse(propValue.extraData)
                : { flagDropdownId: 0 };
            const onChange = (flagDropdownId: number) => {
                onPropDropdownFlagChange(propDef.id, flagDropdownId, options[flagDropdownId].textValue);
            }
            return (
                <Select
                    placeholder={"test"}
                    style={{ width: "100%" }}
                    disabled={!propValue?.isEnabled ?? false}
                    onChange={onChange}
                    value={extraData.flagDropdownId}>
                    {options.map((i: any) => <Option value={i.value}>{i.textValue}</Option>)}
                </Select>
            )
        } else {
            return propDef.flag;
        }
    }

    const getCommand = (propDef: PropDef, propValue?: PropValue) => {
        const extraData = propValue && propValue.extraData && propValue.extraData[0] === '{'
            ? JSON.parse(propValue.extraData)
            : { commands: [ ] };
        const onChange = (value: any) => {
            onPropValueWithExtraChange(
                propDef.id,
                JSON.stringify({ value: value?.join(" ") ?? null }),
                JSON.stringify({ commands: value ?? null } ));
        }
        return (
            <Cascader
                options={propDef.commands}
                allowClear={true}
                changeOnSelect={true}
                displayRender={label => label.join(' ')}
                style={{ width: "100%" }}
                onChange={onChange}
                value={extraData.commands} />
        )
    }

    var renderGroup = (groupIndex: number, propGroup?: PropGroupDef) => {
        const shouldHide = (propGroup?: PropGroupDef) => {
            var alwaysVisible = propGroup?.displayConditions == null;
            var groupVisible = Object.entries(propGroup?.displayConditions ?? {}).some(a =>
                JSON.parse((propValues[a[0]] || props.data.propValues[a[0]])?.value || "{ \"value\": null }").value == a[1])

            return !alwaysVisible && !groupVisible;
        }
        const propsRendered = props.data.blueprint.props
            .filter((prop) => prop.groupId == propGroup?.groupId)
            .map((prop, index) =>
                <Row gutter={16} align="middle" key={`${groupIndex}-${index}`}>
                    {prop.propTypeDescriptor.isCommand
                        ? <>
                            <Col className="gutter-row node-prop-text" span={5}>
                                <span className={(true) ? "prop-enabled" : "prop-disabled"}>
                                    {prop.text}
                                </span>
                            </Col>
                            <Col className="gutter-row node-prop-flag" span={5}>
                                <span className={(true) ? "prop-enabled" : "prop-disabled"}>
                                    {getCommand(prop, propValues[prop.id])}
                                </span>
                            </Col>
                        </>
                        : <>
                            <Col className="gutter-row node-prop-text" span={5}>
                                <Switch
                                    className="propSwitch"
                                    size="small"
                                    style={{ display: prop.alwaysOn ? "none" : "inline-block" }}
                                    checked={prop.alwaysOn || propValues[prop.id]?.isEnabled}
                                    onChange={(checked: boolean) => onPropSelectionChange(prop.id, checked)} />
                                <span className={(true) ? "prop-enabled" : "prop-disabled"}>
                                    {prop.text}
                                </span>
                            </Col>
                            {
                                props.data.blueprint.hideFlagDescriptions
                                    ? <></>
                                    : <Col className="gutter-row node-prop-flag" span={5}>
                                        <span className={(true) ? "prop-enabled" : "prop-disabled"}>
                                            {getFlag(prop, propValues[prop.id])}
                                        </span>
                                    </Col>
                            }
                            <Col span={1}>
                                <Tooltip title={prop.help}>
                                    <FontAwesomeIcon icon={light("circle-question")} style={{ color: "rgba(255, 255, 255, 0.45)", float: "right", cursor: "help" }} />
                                </Tooltip>
                            </Col>
                            <Col span={props.data.blueprint.hideFlagDescriptions ? 15 : 10} className="prop-editors">
                                <MainPropEditor
                                    editorKey={`${groupIndex}-${index}`}
                                    onPropValueChange={onPropValueChange}
                                    setErrorStatus={setErrorStatus}
                                    propDef={prop}
                                    propValue={propValues[prop.id]}
                                    key={`${props.workflowNodeId}-editor-${index}`}
                                    resetFormTrigger={resetFormTrigger} />
                            </Col>
                            <Col span={3}>
                                {!prop.propTypeDescriptor.isList && prop.propTypeDescriptor.dataType != DataType.Bool
                                    ? <Button
                                        type="dashed"
                                        icon={<FontAwesomeIcon icon={solid("xmark-circle")} />}
                                        size="small"
                                        disabled={!propValues[prop.id]}
                                        onClick={() => {
                                            setPropValues({ ...dictDelete(propValues, prop.id) });
                                            setIsDataChanged(true);
                                        }} />
                                    : <></>}
                            </Col>
                        </>}
                </Row>
            );

        return (
            <div key={groupIndex} style={{ margin: "25px 0" }} hidden={shouldHide(propGroup)}>
                {propGroup?.groupName ? <h2>{propGroup.groupName}</h2> : <></>}
                <>{propsRendered}</>
            </div>);
    };

    return (
        <Modal
            className="flow-graph-node-settings-modal"
            open={visible}
            title={`Edit node: ${props.data.blueprint.name}`}
            width="70%"
            onOk={() => { apply(); close(); }}
            onCancel={() => { cancel(); }}
            footer={[
                <Button key="back" type="text" onClick={() => { revert(); close(); notification.close(key); }}>Close</Button>,
                <Button key="apply" type="primary" onClick={() => { apply(); notification.close(key); }}>Apply</Button>,
                <Button key="applyClose" type="primary" onClick={() => { apply(); close(); notification.close(key); }}>Apply &amp; close</Button>
            ]}>
            <Tabs defaultActiveKey="0">
                <TabPane tab="General" key="1">
                    <Descriptions layout="horizontal" size="small" column={1} bordered>
                        <Descriptions.Item label="Version">{props.data.blueprint.version}</Descriptions.Item>
                        <Descriptions.Item label="Author">{props.data.blueprint.author}</Descriptions.Item>
                        <Descriptions.Item label="License">
                            {props.data.blueprint.licenseUrl
                                ? <a href={props.data.blueprint.licenseUrl} target="_blank">{props.data.blueprint.license}</a>
                                : props.data.blueprint.license}
                        </Descriptions.Item>
                        <Descriptions.Item label="Category">{props.data.blueprint.category}</Descriptions.Item>
                        <Descriptions.Item label="Subcategory">{props.data.blueprint.subcategory}</Descriptions.Item>
                        <Descriptions.Item label="Description" span={2}>{props.data.blueprint.description}</Descriptions.Item>
                    </Descriptions>
                </TabPane>
                <TabPane tab="Settings" key="2">
                    {renderGroup(-1, undefined)}
                    {props.data.blueprint.propGroups
                        .filter(propGroup => props.data.blueprint.props.some(p => p.groupId == propGroup.groupId))
                        .map((propGroup, index) => renderGroup(index, propGroup))}
                </TabPane>
                {/* <TabPane tab="Documentation" key="3">

                </TabPane> */}
            </Tabs>
        </Modal>
    );
});

export default FlowGraphNodeSettingsModal;
