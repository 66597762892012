import * as React from 'react';
import { Row, Col, Tabs, notification } from 'antd';
import { ISettingTab, SettingsDisplayProps } from '../types';
import TabPane from 'antd/lib/tabs/TabPane';

export function SettingsDisplay(props: SettingsDisplayProps) {

    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 18 },
        },
    };

    const [activeKey, setActiveKey] = React.useState("setting-section-0");
    const [currentKeyHasChanges, setCurrentKeyHasChanges] = React.useState(false);
    const changeKey = (newKey: string) => {
        if (currentKeyHasChanges) {
            notification.open({
                message: 'Unsaved changes',
                description: 'There are unsaved changes on this tab. Please save them or discard.',
                type: 'error',
                onClose: close,
              });
        } else {
            setActiveKey(newKey);
        }
    };

    return (
        <Row style={props.visible === false ? { display: "none" } : {}}>
            <Col span={24}>
                <Tabs activeKey={activeKey} tabPosition='right' onChange={(newKey) => changeKey(newKey)}>
                    {
                        props.tabs.map((item: ISettingTab, idx: number) => {
                            return (<TabPane tab={item.displayTitle()} key={`setting-section-${idx}`}>
                                {item.displayComponent(setCurrentKeyHasChanges)}
                            </TabPane>);
                        })
                    }
                </Tabs>
            </Col>
        </Row>
    )
}