import React, { useEffect } from "react";
import { AutoComplete, Button, Col, Form, Input, Row, Select, Switch } from "antd";
import { FileAutoCompleteObject, IApplicationState, MainPropEditorDataInit, MainPropEditorDispatchProps, MainPropEditorOwnProps, MainPropEditorProps, MainPropEditorStateProps, PropDef, PropTypeDescriptor, PropValue, SettingEditorError } from "../types";
import { IconButton } from "./ui/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DataType } from "../core/enums";
import { selectMany } from "../core/reduxHelpers";
import TextArea from "antd/lib/input/TextArea";
import { selectMainPropEditorData } from "../store/selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushNewTagAction } from "../store/actions/tagActions";
import { checkFileExists } from "../core/treeHelper";

const { Option } = Select;

function MainPropEditor(props: MainPropEditorProps) {

    const propValue : PropValue = !!props.propValue
        ? {
            ...props.propValue,
            isEnabled: props.propDef.alwaysOn || props.propValue.isEnabled,
        }
        : {
            blueprintPropType: props.propDef.id,
            isEnabled: props.propDef.alwaysOn,
            value: "",
            groupId: undefined,
        };

    propValue.value = propValue.value && propValue.value.startsWith("{")
        ? JSON.parse(propValue.value)
        : { value: null };

    const dataInit: MainPropEditorDataInit = {
        allDataTags: props.allDataTags,
        pushNewTag: props.pushNewTag,
    }

    if (props.propDef.propTypeDescriptor.isList) {
        return getListEditor(
            props.resetFormTrigger,     // resetFormTrigger: number,
            `${props.editorKey}-1`,     // editorKey: string,
            propValue.value,            // value: any,
            {},                         // formItemRestField: any,
            0,                          // formListId: number,
            "",                         // formItemName: string,
            props.propDef,              // propDef: PropDef,
            propValue.isEnabled,        // propValue: PropValue,
            props.propDef.id,
            props.propDef.propTypeDescriptor.dataType,
            props.propDef.propTypeDescriptor.enumData,
            dataInit,
            props.directories,
            props.files,
            props.filesAndDirs,
            props.setErrorStatus,
            props.onPropValueChange);   // onPropValueChange?: (key: number, value: string) => void) : JSX.Element
    } else if (props.propDef.propTypeDescriptor.isComplex) {
        return getComplexEditor(
            props.resetFormTrigger,     // resetFormTrigger: number,
            `${props.editorKey}-1`,     // editorKey: string,
            propValue.value,            // value: any,
            {},                         // formItemRestField: any,
            0,                          // formListId: number,
            "",                         // formItemName: string,
            props.propDef,              // propDef: PropDef,
            propValue.isEnabled,        // propValue: PropValue,
            props.propDef.id,
            props.propDef.propTypeDescriptor.dataType,
            props.propDef.propTypeDescriptor.enumData,
            dataInit,
            props.directories,
            props.files,
            props.filesAndDirs,
            props.setErrorStatus,
            props.onPropValueChange);   // onPropValueChange?: (key: number, value: string) => void) : JSX.Element
    } else {
        return getSimpleEditor(
            props.resetFormTrigger,     // resetFormTrigger: number,
            `${props.editorKey}-1`,     // editorKey: string,
            propValue.value,            // value: any,
            {},                         // formItemRestField: any,
            0,                          // formListId: number,
            "",                         // formItemName: string,
            props.propDef,              // propDef: PropDef,
            propValue.isEnabled,        // propValue: PropValue,
            props.propDef.id,
            props.propDef.propTypeDescriptor.dataType,
            props.propDef.propTypeDescriptor.enumData,
            undefined,
            dataInit,
            props.directories,
            props.files,
            props.filesAndDirs,
            props.setErrorStatus,
            props.onPropValueChange);   // onPropValueChange?: (key: number, value: string) => void) : JSX.Element
    }
};

export default connect<MainPropEditorStateProps, MainPropEditorDispatchProps, MainPropEditorOwnProps, IApplicationState>(
    (state) => selectMainPropEditorData(state),
    dispatch => bindActionCreators({
        pushNewTag: pushNewTagAction,
    }, dispatch)
)(MainPropEditor)

function getListEditor(
    resetFormTrigger: number,
    editorKey: string,
    value: any,
    formItemRestField: any,
    formListId: number,
    formItemName: string,
    propDef: PropDef,
    isEnabled: boolean,
    id: number,
    dataType: DataType,
    enumData: any,
    dataInit: MainPropEditorDataInit,
    directories: FileAutoCompleteObject[],
    files: FileAutoCompleteObject[],
    filesAndDirs: FileAutoCompleteObject[],
    setErrorStatus: (fieldId: string, error: SettingEditorError) => void,
    onPropValueChange: (key: number, value: string) => void)
{
    const [form] = Form.useForm();
    useEffect(() => {
        if (resetFormTrigger) {
            form.resetFields();
        }
    }, [resetFormTrigger]);

    const propTypeDescriptor = propDef.propTypeDescriptor;
    const onValuesChange = (changedValues: any, allValues: any) => {
        if (changedValues && changedValues.value.length !== 0) {
            onPropValueChange(id, JSON.stringify({ value: allValues.value }));
        } else {
            onPropValueChange(id, JSON.stringify({ value: [] }));
        }
    }

    return (
        <Form form={form} name={`editor-${editorKey}`} onValuesChange={onValuesChange}>
            <Form.List name="value" initialValue={value?.value ?? []}>
                {(fields, { add, remove }) => (
                    <>
                        {
                            fields.map(({ key, name, ...restField }, idx) => (
                                <Row key={`item-list-value-${editorKey}-${key}`}>
                                    <Col span={22}>
                                        {
                                            propTypeDescriptor.innerProps.map((pr) =>
                                                <Row gutter={8}>
                                                    {
                                                        pr.map((p) => {
                                                            const currentValue = value.value;
                                                            return <Col span={p.columnWidth}>
                                                                {
                                                                    getSimpleEditor(
                                                                        resetFormTrigger,
                                                                        `${editorKey}-${key}-${p.propName}`,            // editorKey: string,
                                                                        currentValue && currentValue[idx]               // value: any,
                                                                            ? { value: currentValue[idx][p.propName] ?? "HAHAH" }
                                                                            : null,
                                                                        formItemRestField,                              // formItemRestField: any,
                                                                        idx,                                            // formListId: number,
                                                                        p.propName,                                     // formItemName: string,
                                                                        propDef,                                        // propDef: PropDef,
                                                                        isEnabled,                                      // propValue: PropValue,
                                                                        name,
                                                                        p.dataType,
                                                                        p.enumData,
                                                                        p.placeholder,
                                                                        dataInit,
                                                                        directories,
                                                                        files,
                                                                        filesAndDirs,
                                                                        setErrorStatus,
                                                                        undefined)                                      // onPropValueChange?: (key: number, value: string) => void) : JSX.Element
                                                                }
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            )
                                        }
                                    </Col>
                                    <Col span={2}>
                                        <IconButton
                                            style={{ marginLeft: "8px" }}
                                            icon={solid("circle-minus")}
                                            onClick={() => remove(name)}
                                            disabled={!isEnabled} />
                                    </Col>
                                </Row>))
                        }
                        <Row>
                            <Form.Item style={{ width: '100%' }}>
                                <Button
                                    key={`add-item-${editorKey}`}
                                    type="dashed"
                                    onClick={() => add(getDefaultObjectValue(propTypeDescriptor))}
                                    style={{ width: '100%' }}
                                    icon={<FontAwesomeIcon icon={solid("circle-plus")} />}
                                    disabled={!isEnabled}>
                                    Add item
                                </Button>
                            </Form.Item>
                        </Row>
                    </>
                )}
            </Form.List>
        </Form>
    );
}

function getComplexEditor(
    resetFormTrigger: number,
    editorKey: string,
    value: any,
    formItemRestField: any,
    formListId: number,
    formItemName: string,
    propDef: PropDef,
    isEnabled: boolean,
    id: number,
    dataType: DataType,
    enumData: any,
    dataInit: MainPropEditorDataInit,
    directories: FileAutoCompleteObject[],
    files: FileAutoCompleteObject[],
    filesAndDirs: FileAutoCompleteObject[],
    setErrorStatus: (fieldId: string, error: SettingEditorError) => void,
    onPropValueChange: (key: number, value: string) => void)
{
    const [form] = Form.useForm();
    useEffect(() => {
        if (resetFormTrigger) {
            form.resetFields();
        }
    }, [resetFormTrigger]);

    const propTypeDescriptor = propDef.propTypeDescriptor;
    const onValuesChange = (_: any, allValues: any) => {
        const data = Object.assign({}, ...propTypeDescriptor.innerProps[0].map((x) => ({[x.propName]: allValues[0][`editor-${editorKey}-${x.propName}`]})));
        onPropValueChange(id, JSON.stringify({ value: data }));
    }

    return (
        <Form form={form} name={`editor-${editorKey}`} onValuesChange={onValuesChange}>
            {
                propTypeDescriptor.innerProps.map(pr =>
                    <Row gutter={8}>
                        {
                            pr.map(p => {
                                return <Col span={p.columnWidth}>
                                    {
                                        getSimpleEditor(
                                            resetFormTrigger,
                                            `${editorKey}-${p.propName}`,                   // editorKey: string,
                                            value && value["value"]                         // value: any,
                                                ? { value: value["value"][p.propName] }
                                                : null,
                                            formItemRestField,                              // formItemRestField: any,
                                            formListId,                                     // formListId: number,
                                            `editor-${editorKey}-${p.propName}`,            // formItemName: string,
                                            propDef,                                        // propDef: PropDef,
                                            isEnabled,                                      // propValue: PropValue,
                                            id,
                                            p.dataType,
                                            p.enumData,
                                            p.placeholder,
                                            dataInit,
                                            directories,
                                            files,
                                            filesAndDirs,
                                            setErrorStatus,
                                            undefined)                                      // onPropValueChange?: (key: number, value: string) => void) : JSX.Element
                                    }
                                </Col>
                            })
                        }
                    </Row>
                )
            }
        </Form>
    );
}

function getSimpleEditor(
    resetFormTrigger: number,
    editorKey: string,
    value: any,
    formItemRestField: any,
    formListId: number,
    formItemName: string,
    propDef: PropDef,
    isEnabled: boolean,
    id: number,
    dataType: DataType,
    enumData: any,
    placeholder: string | undefined,
    dataInit: MainPropEditorDataInit,
    directories: FileAutoCompleteObject[],
    files: FileAutoCompleteObject[],
    filesAndDirs: FileAutoCompleteObject[],
    setErrorStatus: (fieldId: string, error: SettingEditorError) => void,
    onPropValueChange?: (key: number, value: string) => void) : JSX.Element
{
    // Simple editor is stand-alone when it is not a part of list or complex object. In that case it has
    // its own `onChange` handler
    const isStandAlone: boolean = !!onPropValueChange;
    const onPropValueChangeWDefault: any = onPropValueChange ?? ( (_1: number, _2: string) => { debugger; } );

    const onStringChange = (event: any) => {
        onPropValueChangeWDefault(id, JSON.stringify({ value: event.target.value }));
    }
    const onBoolChange = (checked: boolean) => {
        onPropValueChangeWDefault(id, JSON.stringify({ value: checked }));
    }
    const onEnumChange = (value: number) => {
        onPropValueChangeWDefault(id, JSON.stringify({ value: value }));
    }
    const onMEnumChange = (values: Array<number>) => {
        onPropValueChangeWDefault(id, JSON.stringify({ value: values }));
    }
    const onAutoCompleteChange = (value: string) => {
        onPropValueChangeWDefault(id, JSON.stringify({ value: value }));
    }

    var validationParams: any = {};
    var result = <></>;

    switch (dataType) {
        case DataType.Bool:
            if (isStandAlone && !propDef.alwaysOn) {
                return <></>;
            } else {
                result = (<Switch size="small" checked={value?.value} disabled={!isEnabled} key={editorKey} onChange={onBoolChange} />);
            }
            break;
        case DataType.String:
        case DataType.Int:
        case DataType.Decimal:
        case DataType.ASN:
        case DataType.CIDR:
        case DataType.CIDRv4:
        case DataType.CIDRv6:
        case DataType.IP:
        case DataType.IPv4:
        case DataType.IPv6:
        case DataType.Port:
        case DataType.Domain:
        case DataType.Url:
            result = (<Input value={value?.value} defaultValue={value?.value} placeholder={placeholder} disabled={!isEnabled} onChange={onStringChange} />);
            break;
        case DataType.LongString:
            result = (<TextArea rows={5} value={value?.value} placeholder={placeholder} disabled={!isEnabled} onChange={onStringChange} />);
            break;
        case DataType.Enum:
            result = (
                <Select style={{ width: "100%" }} disabled={!isEnabled} placeholder={placeholder} onChange={onEnumChange} value={value?.value} defaultValue={value?.value}>
                    {enumData.map((i: any) => <Option value={i.value}>{i.text}</Option>)}
                </Select>
            );
            break;
        case DataType.MultiEnum:
            result = (
                <Select mode="multiple" style={{ width: "100%" }} placeholder={placeholder} disabled={!isEnabled} onChange={onMEnumChange} value={value?.value ?? []} defaultValue={value?.value ?? []}>
                    {enumData.map((i: any) => <Option value={i.value}>{i.text}</Option>)}
                </Select>
            );
            break;
        case DataType.TagsList:
            result = (
                <Select mode="tags" allowClear placeholder="Tags" disabled={!isEnabled} onChange={onMEnumChange} onSelect={(t: any) => dataInit.pushNewTag(t)} value={value?.value || []} defaultValue={value?.value || []} style={{width: "100%"}}>
                    {dataInit.allDataTags.map(t => <Option value={t}>{t}</Option>)}
                </Select>
            );
            break;
        case DataType.LongString:
            result = (<Input.TextArea value={value?.value} defaultValue={value?.value} placeholder={placeholder} disabled={!isEnabled} onChange={onStringChange} />);
            break;
        case DataType.FileInput:
            validationParams = validate(editorKey, propDef.text, dataType, value?.value, isEnabled, setErrorStatus, { filesAndDirs });
            result = (
                    <AutoComplete
                        placeholder="Select input file"
                        style={{ width: "100%" }}
                        value={value?.value}
                        defaultValue={value?.value}
                        disabled={!isEnabled}
                        onChange={onAutoCompleteChange}
                        filterOption={(input, option) => (option?.value?.toString() ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.value?.toString() ?? '').localeCompare((optionB?.value?.toString() ?? ''))
                        }
                        options={files} />)
            break;
        case DataType.FileOutput:
            validationParams = validate(editorKey, propDef.text, dataType, value?.value, isEnabled, setErrorStatus, { filesAndDirs });
            result = (
                <AutoComplete
                    placeholder="Select output file"
                    style={{ width: "100%" }}
                    value={value?.value}
                    defaultValue={value?.value}
                    disabled={!isEnabled}
                    onChange={onAutoCompleteChange}
                    filterOption={(input, option) => (option?.value?.toString() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.value?.toString() ?? '').localeCompare((optionB?.value?.toString() ?? ''))
                    }
                    options={filesAndDirs} />)
            break;
        case DataType.Directory:
            validationParams = validate(editorKey, propDef.text, dataType, value?.value, isEnabled, setErrorStatus, { filesAndDirs });
            result = (
                <AutoComplete
                    placeholder="Select directory"
                    style={{ width: "100%" }}
                    value={value?.value}
                    defaultValue={value?.value}
                    disabled={!isEnabled}
                    onChange={onAutoCompleteChange}
                    filterOption={(input, option) => (option?.value?.toString() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.value?.toString() ?? '').localeCompare((optionB?.value?.toString() ?? ''))
                    }
                    options={directories} />)
            break;
        default:
            return <>Not implemented {dataType}</>; // TODO: Remove "not implemented" string before production. It should be empty value
    }

    if (!isStandAlone) {
        result = <Form.Item {...validationParams} {...formItemRestField} name={[formListId, formItemName]}>{result}</Form.Item>
    }

    return result;
}

function getDefaultObjectValue(propTypeDescriptor: PropTypeDescriptor) {
    const props = selectMany(propTypeDescriptor.innerProps, i => i);
    const newObj = props.reduce((o: {}, p) => ({ ...o, [p.propName]: getSimpleDefaultValue(p.dataType) }), {});

    return newObj;
}

function getSimpleDefaultValue(dataType: DataType) {
    switch (dataType) {
        case DataType.Bool:
            return false;
        case DataType.MultiEnum:
            return [];
        default:
            return "";
    }
}

function validate(editorKey: string, propText: string, dataType: DataType, value: any, isEnabled: boolean, setErrorStatus: (fieldId: string, error: SettingEditorError) => void, context: any = undefined) {
    var parameters: ValidationParameters[] = [
        {
            dataType: DataType.FileInput,
            validateStatus: "warning",
            help: "Selected input file currently doesn't seem to exist",
            validator: (value, context) => !checkFileExists(context.filesAndDirs, value)
        },
        {
            dataType: DataType.FileInput,
            validateStatus: "error",
            help: "Input file must not end with forward slash ('/')",
            validator: (value, context) => value?.endsWith('/') ?? false
        },
        {
            dataType: DataType.FileOutput,
            validateStatus: "warning",
            help: "Selected output file currently exists and is in danger of being overwritten",
            validator: (value, context) => checkFileExists(context.filesAndDirs, value)
        },
        {
            dataType: DataType.FileOutput,
            validateStatus: "error",
            help: "Output file must not end with forward slash ('/')",
            validator: (value, context) => value?.endsWith('/') ?? false
        },
        {
            dataType: DataType.Directory,
            validateStatus: "error",
            help: "Directory must end with forward slash ('/')",
            validator: (value, context) => !value?.endsWith('/') ?? false
        }
    ]

    var output: ValidationParameters = parameters
        .sort((p1, p2) => p1.validateStatus?.localeCompare(p2.validateStatus ?? "") || (p1.order ?? 0) - (p2.order ?? 0))
        .filter(p => p.dataType == dataType && isEnabled && p.validator(value, context))[0]

    setErrorStatus(editorKey, { status: output?.validateStatus, message: `${propText}: ${output?.help}` });

    return output
        ? {
            validateStatus: output.validateStatus,
            help: output.help,
        }
        : { };
}

interface ValidationParameters {
    order?: number;
    dataType: DataType;
    validator: (value: any, context: any) => boolean;
    validateStatus: "warning" | "error" | "" | undefined;
    help: string;
}

