import React from 'react'
import { Button, Col, Form, Input, Popover, Row } from 'antd';
import CenteredComponentPage from '../../components/pageInterfaces/CenteredComponentPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ForceResetPasswordDispatchProps, ForceResetPasswordProps, ForceResetPasswordStateProps, IApplicationState } from '../../types';
import { forceResetPasswordThunk } from '../../store/actions/authActions';
import { selectForceResetPasswordData } from '../../store/selectors';
import { useForm } from 'antd/lib/form/Form';

function ForceResetPasswordPage(props: ForceResetPasswordProps) {
    const [form] = useForm();
    const complexityContent = <>
        The following characters count as <br />special characters:<br /><br />
        <span className="courier">^ $ * . [ ] { } ( ) ? - " ! @ # %</span><br />
        <span className="courier">& / \ , &gt; &lt; ' : ; | _ ~ ` + =</span>
    </>;
    const complexityMessage = <>
        The password requires: an uppercase <br /> letter, a lowercase letter, a digit, and a&nbsp;
        <Popover placement="topLeft" title="Password special characters" content={complexityContent} trigger="click">
            <span className="ant-tooltip-custom">special char</span>
        </Popover>
    </>;

    return (
        <CenteredComponentPage forceRender>
            <Row className="login-form">
                <Col>
                    <img src="ScourNomadLogo.png" />
                    <h1>ScourNomad</h1>
                </Col>
                <Col>
                    <h3>Reset Password</h3>
                    <p style={{ marginLeft: "50px" }}>You have been required by the Administrator to reset your password.</p>
                    <Form
                        form={form}
                        className="force-reset-password-form"
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={async (values: any) => {
                            props.forceResetPassword(props.sessionID, props.userName, values.password);
                        }}
                        autoComplete="off">
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={
                                [
                                    { required: true, message: 'Please input your password' },
                                    { pattern: new RegExp("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)"), message: complexityMessage },
                                    { min: 15, message: 'Password needs to have at least 15 characters' },
                                ]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Retype"
                            name="retype-password"
                            dependencies={['password']}
                            rules={
                                [
                                    { required: true, message: 'Please retype the password' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("The passwords don't match"));
                                        },
                                    }),
                                ]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

        </CenteredComponentPage>
    );
}


export default connect<ForceResetPasswordStateProps, ForceResetPasswordDispatchProps, {}, IApplicationState>(
    (state) => selectForceResetPasswordData(state),
    dispatch => bindActionCreators({
        forceResetPassword: forceResetPasswordThunk
    }, dispatch)
)(ForceResetPasswordPage)