import { Col, Row } from 'antd';
import * as React from 'react';

export default function CenteredComponentPage(props: any) {
    return (
        <Row className="centered-component-page" align="middle">
            <Col flex="auto"></Col>
            <Col>{props.children}</Col>
            <Col flex="auto"></Col>
        </Row>
    );
}