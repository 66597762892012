import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Table } from 'antd';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import { IApplicationState, Project, ProjectsPageDispatchProps, ProjectsPageOwnProps, ProjectsPageProps, ProjectsPageState, ProjectsPageStateProps } from '../types';
import { bindActionCreators } from 'redux';
import { selectProjectsPage } from '../store/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import DangerousConfirmButton from '../components/ui/DangerousConfirmButton';
import { deleteProjectThunk, openCreateProjectTabAction, openEditProjectTabAction, openViewProjectTabAction } from '../store/actions/projectActions';

const { Column } = Table;

function ProjectsPage(props: ProjectsPageProps) {
    if (props.isLoading) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    const deleteProjectWrapped = (projectId: string) => () => props.deleteProject(projectId);
    const editProjectWrapped = (projectId: string) => () => props.openEditProjectTab(projectId);
    const viewProjectWrapped = (projectId: string) => () => props.openViewProjectTab(projectId);

    return (
        <GutteredPage>
            <Row>
                <Col span={12}>
                    <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={solid("globe")} />}
                        onClick={() => props.openCreateProjectTab()}>
                            New Project
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={props.projects}
                        rowKey="id"
                        pagination={{position: ["bottomLeft"]}}
                        scroll={{ x: true }}>
                        <Column title="Project name" dataIndex="name" render={(text: any, record: Project) => <Button type="link" onClick={viewProjectWrapped(record.projectId)}>{text}</Button>} />
                        <Column title="Seed resources" width="240px" render={(text: any, record: Project) => record.seedResources ? record.seedResources.length : (record.seedResourcesCount ?? "0")} />
                        <Column title="Date and Time Created" width="200px" dataIndex="dateTimeCreated" render={(text: any, record: Project) => new Date(record.dateTimeCreated).toLocaleString()} />
                        <Column title="Version" width="120px" dataIndex="version" />
                        <Column title="Actions" width="120px" render={(text, record: Project) => (
                            <>
                                <Button
                                    type='text'
                                    icon={<FontAwesomeIcon icon={solid("pen-to-square")}
                                    onClick={editProjectWrapped(record.projectId)} />} />
                                <DangerousConfirmButton
                                    popupText={<>Delete project?</>}
                                    icon={solid("trash-can")}
                                    isInProgress={props.projects.filter(p => p.projectId === record.projectId)[0].isDeleteInProgress}
                                    onConfirm={deleteProjectWrapped(record.projectId)} />
                            </>
                        )}/>
                    </Table>
                </Col>
            </Row>
        </GutteredPage>
    );
}

export default connect<ProjectsPageStateProps, ProjectsPageDispatchProps, ProjectsPageOwnProps, IApplicationState>(
    (state, ownProps) => selectProjectsPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        openEditProjectTab: openEditProjectTabAction,
        openCreateProjectTab: openCreateProjectTabAction,
        openViewProjectTab: openViewProjectTabAction,
        deleteProject: deleteProjectThunk,
    }, dispatch)
)(ProjectsPage)