// Partial Linq implementation

import { Dictionary } from "@reduxjs/toolkit";
import _ from "lodash";

type KeyType = string | number;

export function single<T = any>(elements: Array<T>, filter: (item: T) => boolean): T {
    const foundElements = elements.filter(el => filter(el));
    if (foundElements.length != 1) {
        throw new Error(`single: Expected 1 element, found ${foundElements.length}`);
    }
    return foundElements[0];
}

export function singleOrDefault<T>(elements: Array<T>, filter: (item: T) => boolean, defaultRet?: T): T | null {
    const foundElements = elements.filter(el => filter(el));
    if (foundElements.length > 1) {
        throw new Error(`singleOrDefault: Expected at most 1 element, found ${foundElements.length}`);
    }
    return !any(foundElements) ? (defaultRet ?? null) : foundElements[0];
}

export function first<T = any>(elements: Array<T>, filter?: (item: T) => boolean): T {
    if (filter) {
        const foundElements = elements.filter(el => filter(el));
        if (foundElements.length == 0) {
            throw new Error(`first: Expected at least 1 element, found 0`);
        }
        return foundElements[0];
    } else {
        if (elements.length == 0) {
            throw new Error(`first: Expected at least 1 element, found 0`);
        }
        return elements[0];
    }
}

export function firstOrDefault<T>(elements: Array<T>, filter?: (item: T) => boolean, defaultRet?: T): T | null {
    if (filter) {
        const foundElements = elements.filter(el => filter(el));
        return !any(foundElements) ? (defaultRet ?? null) : foundElements[0];
    } else {
        return !any(elements) ? (defaultRet ?? null) : elements[0];
    }
}

export function last<T = any>(elements: Array<T>, filter?: (item: T) => boolean): T {
    if (filter) {
        const foundElements = elements.filter(el => filter(el));
        if (foundElements.length == 0) {
            throw new Error(`last: Expected at least 1 element, found 0`);
        }
        return foundElements[foundElements.length - 1];
    } else {
        if (elements.length == 0) {
            throw new Error(`last: Expected at least 1 element, found 0`);
        }
        return elements[elements.length - 1]
    }
}

export function lastOrDefault<T>(elements: Array<T>, filter?: (item: T) => boolean, defaultRet?: T): T | null {
    if (filter) {
        const foundElements = elements.filter(el => filter(el));
        return !any(foundElements) ? (defaultRet ?? null) : foundElements[foundElements.length - 1];
    } else {
        return !any(elements) ? (defaultRet ?? null) : elements[elements.length - 1];
    }
}

export function where<T>(elements: Array<T>, filter: (item: T) => boolean, defaultRet?: T): Array<T> {
    return elements.filter(el => filter(el));
}

export function any<T>(elements: Array<T>, filter?: (item: T) => boolean): boolean {
    if (filter) {
        return elements.some((value: T) => filter(value))
    } else {
        return elements.length > 0;
    }
}

export function all<T>(elements: Array<T>, filter: (item: T) => boolean): boolean {
    return elements.every((value: T) => filter(value))
}

export function select<T, TNew>(elements: Array<T>, filter: (item: T) => TNew) {
    return elements.map(filter);
}

export function selectMany<T, TNew>(elements: Array<T>, filter: (item: T) => Array<TNew>) {
    return elements.reduce<Array<TNew>>(function(a, b) { return a.concat(filter(b)); }, []);
}

export function unique<T>(elements: Array<T>) {
    return elements.filter((v, idx) => elements.indexOf(v) === idx);
}

export function toDictionary<T>(elements: Array<T>, keySelector: (el: T) => any, valueSelector?: (el: T) => any) {
    return Object.assign({}, ...elements.map((el) => ({ [keySelector(el)]: (valueSelector ? valueSelector(el) : el) })));
}

export function keys<V>(dictionary: { [k: KeyType]: V }) {
    return Object.keys(dictionary);
}

export function values<V>(dictionary: Dictionary<V>) {
    return Object
        .keys(dictionary)
        .map((k: KeyType) => dictionary[k] || null)
        .filter((k): k is NonNullable<V> => !!k);
}

export function groupBy<T, TKey>(elements: Array<T>, keyGetter: (el: T) => TKey): Map<TKey, Array<T>> {
    const map = new Map();
    elements.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

export function intersect<T>(array1: T[], array2: T[], areEqual: (i1: T, i2: T) => boolean): Array<T> {
    // Not sure if this works for objects so reimplementing
    // return array1.filter(value => array2.includes(value));
    var result: Array<T> = [];
    array1.forEach(i1 => {
        array2.forEach(i2 => {
            if (areEqual(i1, i2)) {
                result = [...result, i1]
            }
        });
    });
    return result;
}

// Redux array element updates
export function arrayUpdate<T>(elements: Array<T>, filter: (item: T) => boolean, updates: {}): Array<T> {
    return elements.map((el: T) => {
        if (filter(el)) {
            return {
                ...el,
                ...updates
            }
        } else {
            return el
        }
    })
}


// This is actually not working correctly. It works correctly only when it is expected
// that elements won't be removed (because it is merging two objects)
export function arrayUpdateDeep<T>(elements: Array<T>, filter: (item: T) => boolean, updates: {}): Array<T> {
    return elements.map((el: T) => {
        if (filter(el)) {
            return _.merge(el, updates);
        } else {
            return el
        }
    })
}

export function arrayUpdateAll<T>(elements: Array<T>, updates: {}): Array<T> {
    return elements.map((el: T) => {
        return {
            ...el,
            ...updates
        }
    })
}

export function arrayUpdateCb<T, TContext>(elements: Array<T>, filter: (item: T) => boolean, updates: (item: T, externalContext?: TContext) => {}, externalContext?: TContext): Array<T> {
    return elements.map((el: T) => {
        if (filter(el)) {
            return {
                ...el,
                ...updates(el, externalContext)
            }
        } else {
            return el
        }
    })
}

export function arrayUpdateAllCb<T, TContext>(elements: Array<T>, updates: (item: T, idx: number, externalContext?: TContext) => {}, externalContext?: TContext): Array<T> {
    return elements.map((el: T, idx: number) => {
        return {
            ...el,
            ...updates(el, idx, externalContext)
        }
    })
}

// Redux array element updates
export function arrayUpsert<T>(elements: Array<T>, newElements: Array<T>, idLocator: (element: T) => any): Array<T> {
    var newArray = [...elements]
    newElements.forEach(el => {
        const i = newArray.findIndex(_el => idLocator(_el) === idLocator(el));
        if (i > -1) newArray[i] = el;
        else newArray.push(el);
    });

    return newArray;
}


export function arrayDelete<T>(elements: Array<T>, filter: (item: T) => boolean): Array<T> {
    return elements.filter((el: T) => filter(el) === false);
}

// Redux dict element updates
export function dictUpdateByKey<TValue>(dict: Dictionary<TValue>, key: KeyType, updates: {}): Dictionary<TValue> {
    let values = {
        ...dict,
        [key]: {
            ...dict[key],
            ...updates
        }
    } as Dictionary<TValue>;
    return values;
}

export function dictUpdateByKeyCb<TValue, TContext>(dict: Dictionary<TValue>, key: KeyType, updates: (item: TValue | undefined, externalContext?: TContext) => {}, externalContext?: TContext): Dictionary<TValue> {
    let values = {
        ...dict,
        [key]: {
            ...dict[key],
            ...updates(dict[key], externalContext)
        }
    } as Dictionary<TValue>;
    return values;
}

export function dictDelete(dict: { [key: KeyType]: any }, key: KeyType) {
    let dictCopy = { ...dict };
    delete dictCopy[key];
    return {
        ...dictCopy,
    }
}

export function dictMap<TValue>(dict: Dictionary<TValue>, mapFn: (key: any, value: any) => any) {
    return Object.keys(dict).reduce(function(result: any, key) {
        result[key] = mapFn(key, dict[key])
        return result
    }, {})
}

export function arrayToDictMap<TValue>(array: Array<TValue>, mapKey: (obj: TValue) => KeyType, mapValue: (obj: TValue) => any = (obj: TValue) => obj) {
    return Object.fromEntries(array.map((item: TValue) => [mapKey(item), mapValue(item)]))
}
