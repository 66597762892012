import { createAction } from "@reduxjs/toolkit";
import { communication } from "../../core/communication";
import { PageType } from "../../core/enums";
import * as Names from '../actionNames';
import { openTabAction } from "./tabActions";
import { IApplicationState } from "../../types";
import { Key } from "react";
import { fetchNodeBlueprints } from "../slices/workflowsSlice";

// Simple Actions
export const loadProgrammableNodesAction = createAction(Names.loadProgrammableNodesActionName, (data) => ({ payload: data }));
export const setProgrammableNodeDeleteInProgressAction = createAction(Names.setProgrammableNodeDeleteInProgressActionName, (nodeId, enabled) => ({ payload: { nodeId: nodeId, enabled: enabled } }));
export const deleteProgrammableNodeAction = createAction(Names.deleteProgrammableNodeActionName, (nodeId) => ({ payload: nodeId } ));
export const addProgrammableNodeAction = createAction(Names.addProgrammableNodeActionName, (data) => ({ payload: data } ));
export const updateProgrammableNodeAction = createAction(Names.updateProgrammableNodeActionName, (data) => ({ payload: data } ));

// Complex Actions
export function openViewProgrammableNodesTabAction() {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.ProgrammableNodes));
        dispatch(fetchProgrammableNodesThunk());
    }
}

// Thunks
export function fetchProgrammableNodesThunk() {
    return async (dispatch: any, store: () => IApplicationState) => {
        const response = await communication.get("/api/v1/programmableNodes");
        dispatch(loadProgrammableNodesAction(response.result));
    }
}

export function deleteProgrammableNodeThunk(nodeId: string) {
    return async (dispatch: any, store: () => IApplicationState) => {
        dispatch(setProgrammableNodeDeleteInProgressAction(nodeId, true))
        const response = await communication.post(`/api/v1/programmableNode/delete/${nodeId}`);
        if (response.isSuccessful) {
            dispatch(deleteProgrammableNodeAction(nodeId));
            dispatch(fetchNodeBlueprints(null))
        } else {
            dispatch(setProgrammableNodeDeleteInProgressAction(nodeId, false))
        }
    }
}

export function importProgrammableNodeThunk(manifestPath: Key) {
    return async (dispatch: any, store: () => IApplicationState) => {
        const response = await communication.post(`/api/v1/programmableNode/import`, manifestPath);
        if (response.isSuccessful) {
            dispatch(addProgrammableNodeAction(response.result));
            dispatch(fetchNodeBlueprints(null))
        }
    }
}

