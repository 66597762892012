import * as React from 'react';
import { connect } from 'react-redux';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import { IApplicationState, ProjectPageOwnProps, ProjectPageProps, ProjectPageState, ProjectPageStateProps } from '../types';
import { selectProjectPage } from '../store/selectors';
import ProjectEdit from '../components/ProjectEdit';
import ProjectDetails from '../components/ProjectDetails';
import { Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function ProjectPage(props: ProjectPageProps) {
    if (!props.project) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }
    return (
        <GutteredPage>
            {
                props.isProjectAlreadyDeleted
                ? <Alert
                    message="Project Deleted"
                    description="This project has already been deleted."
                    type="error"
                    icon={<FontAwesomeIcon icon={solid("trash-can")} />}
                    showIcon />
                : <></>
            }
            {
                props.project && (props.project.isEditMode || props.project.isCreateMode)
                ? <ProjectEdit tabId={props.tabId} disabled={props.isProjectAlreadyDeleted} />
                : <ProjectDetails tabId={props.tabId} disabled={props.isProjectAlreadyDeleted} />
            }
        </GutteredPage>
    );
}

export default connect<ProjectPageStateProps, {}, ProjectPageOwnProps, IApplicationState>(
    (state, ownProps) => selectProjectPage(state, ownProps.tabId, false),
)(ProjectPage)