import React from 'react'
import { Col, Row } from 'antd';
import CenteredComponentPage from '../../components/pageInterfaces/CenteredComponentPage';

export default function NoAccessErrorPage() {
    return (
        <CenteredComponentPage>
            <Row className="no-active-licenses">
                <Col style={{ display: "grid" }}>
                    <img src="ScourNomadLogo.png" style={{ justifySelf: "center" }}/>
                    <h1>ScourNomad</h1>
                    <h3>No active licenses found</h3>
                    <p>Please renew your subscription on <a href="https://aws.amazon.com/marketplace/seller-profile?id=ac89b759-4626-4998-80fa-023dd4595399">AWS Marketplace</a>.</p>
                    <p>Or take a look at the available licensing options on <a href="https://scournomad.com/purchase">our website</a>.</p>
                    <p>If you believe you are seeing this message due to an error, please <a href="mailto:support@scournomad.com">send us an email</a>.</p>
                </Col>
            </Row>
        </CenteredComponentPage>
    );
}
