import { NEW_GUID } from "../constants";
import { UserGroupAssignementViewModel, UsersAndGroupsViewModel } from "../types";
import { UserGroupType } from "./enums";


export function UsersAndGroupsToSelection(usersAndGroups: UsersAndGroupsViewModel): UserGroupAssignementViewModel[] {
    var resultList = [
        {
            id: NEW_GUID,
            name: "Everyone",
            type: UserGroupType.Everyone
        }
    ]

    resultList.push(...usersAndGroups.groups.map(g => ({
        id: g.groupId,
        name: g.groupName,
        type: UserGroupType.Group
    })));

    resultList.push(...usersAndGroups.users.map(u => ({
        id: u.userId,
        name: u.userName,
        type: UserGroupType.User
    })));

    return resultList;
}