import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button, Typography, Switch, FormInstance, Popconfirm, Popover, Tooltip } from 'antd';
import { SettingGroup, SettingEditorData, SettingsEditorProps, ISettingEditorData, SettingSection } from '../../types';
import { Necessity, SettingCoverageType, SettingType } from '../../core/enums';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppDispatch } from '../../store/configureAppStore';
import { initiateOAuthFlow, storeSettings } from '../../store/slices/settingsSlice';

const { Title } = Typography;

const SettingsEditorComponent = (props: SettingsEditorProps) => {

    const dispatch = useDispatch<AppDispatch>();

    let settingData: ISettingEditorData;
    if (!props.settingEditorData) {
        throw new Error("Setting Editor Data needs to be set");
    } else {
        settingData = props.settingEditorData;
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 18 },
        },
    };

    const outputSettingGroup = (sg: SettingGroup, form: FormInstance<any>, idx: number) => {
        return (sg.settings.map((s, idxInner) => <SettingEditor
            key={`setting-${idx}-${idxInner}`}
            setting={s}
            form={form}
            coverageType={settingData.coverageType}
            entityId={settingData.entityId} />));
    }

    if (!props.settingSection) return <>Error getting setting section.</>;

    const [form] = Form.useForm();
    const ss = props.settingSection;

    return (
        <Form
            className='settings-display'
            {...formItemLayout}
            form={form}
            onChange={() => props.setCurrentKeyHasChanges(true)}
            onFinish={(values: any) => { dispatch(storeSettings({ settings: values, coverageType: props.settingEditorData.coverageType, entityId: props.settingEditorData.entityId })); }}>

            { settingData.coverageType === SettingCoverageType.Project ? <></> : <Title level={2}>{ss.displayName}</Title> }

            {
                ss.settingGroups
                    //.sort((sg1, sg2) => sg1.order - sg2.order)
                    .map((sg, idx) => sg.displayName
                        ? <div className="settingGroup" key={sg.id}>
                            <Title level={4}>{sg.displayName}</Title>
                            {sg.description ? <p>{sg.description}</p> : <></>}
                            {outputSettingGroup(sg, form, idx)}
                        </div>
                        : <>{outputSettingGroup(sg, form, idx)}</>)
            }

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={<FontAwesomeIcon icon={solid("cog")} />}
                    onClick={() => props.setCurrentKeyHasChanges(false) }>
                        Save settings
                </Button>
                <Button
                    type="text"
                    htmlType="reset"
                    onClick={() => props.setCurrentKeyHasChanges(false) }>
                        Reset settings
                </Button>
            </Form.Item>

        </Form>
    )
};

function SettingEditor(props: SettingEditorData) {

    const dispatch = useDispatch<AppDispatch>();

    let editor: any;
    let initialValue: any = getInitialData(props.setting.value);

    const outputNecessity = (n: Necessity) => {
        switch (n) {
            case Necessity.Optional:
                return <span className="dark-gray">Optional</span>
            case Necessity.Recommended:
                return <span className="blue">Recommended</span>
            case Necessity.ConditionallyRequired:
                return <span className="yellow">Conditionally required</span>
            case Necessity.Required:
                return <span className="red">Required</span>
            default:
                return <>Unknown</>
        }
    };

    const usagePopover = <>
        <h2>Usage</h2>
        <FontAwesomeIcon icon={solid("diagram-project")} /> Nodes:
        <ul>
            {props.setting.settingUsage.map(u => <li>{u.name} <span>{outputNecessity(u.necessity)}</span></li>)}
        </ul>
    </>;

    const keyPopover = <>
        <h2>Setting key</h2>
        <Row>
            <Col>
                <Tooltip trigger={"click"} title="Copied!">
                    <Button type='link' icon={<FontAwesomeIcon icon={regular("copy")} />} onClick={() => navigator.clipboard.writeText(props.setting.key)} />
                </Tooltip>
            </Col>
            <Col><Input className='setting-key-edit' readOnly value={props.setting.key} style={{ width: "300px" }} /></Col>
        </Row>
    </>;

    const help = <>
        <Popover content={usagePopover} className="usage-link">
            <FontAwesomeIcon icon={solid("clipboard-list")} /> <span>Usage</span>
        </Popover>
        <Popover content={keyPopover} className="usage-link">
            <FontAwesomeIcon icon={solid("key")} /> <span>Seting key</span>
        </Popover>
    </>;

    const getEditorItem = (editor: any) => {
        return <Form.Item
            label={props.setting.displayName}
            name={props.setting.key}
            tooltip={props.setting.description}
            help={help}
            initialValue={initialValue}
            getValueProps={(value) => props.setting.settingType === SettingType.Bool
                ? { value: value, checked: value }
                : { value: value }}
            extra={
                <ClearButton
                    setting={props.setting}
                    form={props.form}
                    coverageType={props.coverageType}
                    entityId={props.entityId}
                    onClick={() => { props.form.setFieldValue(props.setting.key, null); }} />
                }>
            {editor}
        </Form.Item>
    }


    switch (props.setting.settingType) {
        case SettingType.Bool:
            editor = <>{getEditorItem(<Switch disabled={props.setting.isReadOnly} size="small" defaultChecked={getInitialData(props.setting.value)} />)} <span className="dark-gray">"[unset]"</span></>;
            break;
        case SettingType.String:
        case SettingType.Int:
        case SettingType.Decimal:
            editor = getEditorItem(<Input placeholder="[unset]" disabled={props.setting.isReadOnly} />);
            break;
        case SettingType.Password:
            editor = getEditorItem(<Input.Password placeholder="[unset]" disabled={props.setting.isReadOnly} />);
            break;
        case SettingType.OAuthToken:
            editor = <>
                {getEditorItem(<Input.Password name={props.setting.key} placeholder="[unset]" disabled={props.setting.isReadOnly} addonAfter={
                    <Button type='text' className='oauth-sync' icon={<FontAwesomeIcon icon={["fab", props.setting.extraDataObject.icon]} />} onClick={() =>
                        dispatch(initiateOAuthFlow({ coverageType: props.coverageType, entityId: props.entityId, settingKey: props.setting.key, oAuthType: props.setting.extraDataObject.oAuthSettingType }))} />
                } />)}
            </>;
            break;
        case SettingType.Enum:
        case SettingType.MultiEnum:
        case SettingType.List:
        default:
            editor = <></>;
            break;
    }

    return editor;
};

function ClearButton(props: SettingEditorData) {
    const [visible, setVisible] = React.useState(false);
    const showPopconfirm = () => {
        setVisible(true);
    };
    const handleOk = (e: any) => {
        if (props.onClick) {
            props.onClick(e)
        }
        setVisible(false);
    };
    const handleCancel = () => {
        setVisible(false);
    };

    return props.setting.confirmDeleteText != null
    ? (
        <Popconfirm
            title={props.setting.confirmDeleteText}
            open={visible}
            onConfirm={handleOk}
            onCancel={handleCancel}>
            <Button
                type='link'
                onClick={() => { showPopconfirm() }}
                icon={<FontAwesomeIcon icon={solid("xmark-circle")} />} />
        </Popconfirm>
    )
    : (
        <Button
            type='link'
            onClick={props.onClick}
            icon={<FontAwesomeIcon icon={solid("xmark-circle")} />} />
    )
}

function getInitialData(value?: string): any {
    return value
        ? JSON.parse(value).value
        : null;
}

export default (settingSection: SettingSection, settingEditorData: ISettingEditorData) => ({
    displayComponent: (setCurrentKeyHasChanges: (changed: boolean) => void) =>
        <SettingsEditorComponent settingSection={settingSection} settingEditorData={settingEditorData} setCurrentKeyHasChanges={setCurrentKeyHasChanges} />,
    displayTitle: () => settingSection?.displayName ?? "",
});