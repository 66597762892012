import { arrayDelete, arrayUpdate } from "../../core/reduxHelpers";
import { ProgrammableNodes, ProgrammableNodesPageStateProps } from "../../types";
import * as Names from "../actionNames";

const defaultState: ProgrammableNodes = {
    isLoading: true,
    nodes: [],
}

function programmableNodesReducer(state: ProgrammableNodes = defaultState, action: any): ProgrammableNodes {
    switch (action.type) {
        case Names.loadProgrammableNodesActionName:
            return loadProgrammableNodes(state, action.payload);
        case Names.setProgrammableNodeDeleteInProgressActionName:
            return setProgrammableNodeDeleteInProgress(state, action.payload);
        case Names.deleteProgrammableNodeActionName:
            return deleteProgrammableNode(state, action.payload);
        case Names.addProgrammableNodeActionName:
            return addProgrammableNode(state, action.payload);
        case Names.updateProgrammableNodeActionName:
            return updateProgrammableNode(state, action.payload);
        default:
            return state;
    }
}

export {
    programmableNodesReducer
}

function loadProgrammableNodes(state: ProgrammableNodes, payload: any): ProgrammableNodes {
    return {
        ...state,
        isLoading: false,
        nodes: [ ...payload ]
    };
}

function setProgrammableNodeDeleteInProgress(state: ProgrammableNodes, payload: any): ProgrammableNodes {
    return {
        ...state,
        nodes: arrayUpdate(state.nodes, (node) => node.nodeId == payload.nodeId, { isDeleteInProgress: payload.enabled })
    };
}

function deleteProgrammableNode(state: ProgrammableNodes, payload: any): ProgrammableNodes {
    return {
        ...state,
        nodes: arrayDelete(state.nodes, (node) => node.nodeId == payload)
    };
}

function addProgrammableNode(state: ProgrammableNodes, payload: any): ProgrammableNodes {
    return {
        ...state,
        nodes: [
            ...state.nodes,
            { ...payload }
        ]
    };
}

function updateProgrammableNode(state: ProgrammableNodes, payload: any): ProgrammableNodes {
    return {
        ...state,
        nodes: arrayUpdate(state.nodes, n => n.nodeId == payload.nodeId, { ...payload })
    };
}