import React, { useImperativeHandle } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Col, DatePicker, Form, InputNumber, Modal, Row, Segmented, Select } from "antd";
import { RunWorkflowModalProps } from "../types";
import { TimeUnits } from "../core/enums";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/configureAppStore";
import { deferProjectWorkflowScan, intervalProjectWorkflowScan, selectWorkflowNamesAndIds } from "../store/slices/workflowsSlice";

const { Option } = Select;

const ScheduleWorkflowModal = React.forwardRef((props: RunWorkflowModalProps, ref: any) => {
    const [visible, setVisible] = React.useState(false);
    const [selected, changeSelected] = React.useState('Defer');
    const [dateTimeText, setDateTimeText] = React.useState('');
    const [valueUnit, setValueUnit] = React.useState(TimeUnits.Hours);
    const [form] = Form.useForm();

    const workflowNamesAndIds = useSelector<RootState, any>((state) => selectWorkflowNamesAndIds(state))
    const dispatch = useDispatch<AppDispatch>();

    const show = () => { setVisible(true); }
    useImperativeHandle(ref, () => ({ show }));

    const close = () => {
        setVisible(false);
    };

    const performReset = () => {
        form.resetFields();
        changeSelected("Defer");
        setDateTimeText("");
        setValueUnit(TimeUnits.Hours);
        close();
    }

    const performSubmit = () => {
        var validation = form.validateFields()
            .then(() => {
                form.submit();
            });
    }

    const selectAfter = (
        <Select defaultValue="hours" style={{ width: 100 }} onChange={(v) => { setValueUnit(Number(v) as TimeUnits); }}>
          <Option value={1}>minutes</Option>
          <Option value={2}>hours</Option>
          <Option value={3}>days</Option>
        </Select>
      );

    return (
        <Modal
            className="schedule-workflow-modal"
            open={visible}
            title="Schedule workflow"
            width={650}
            onOk={() => { performSubmit(); }}
            onCancel={() => { performReset(); }}
            footer={[
                <Button key="back" type="text" onClick={() => { performReset(); }}>Cancel</Button>,
                <Button key="schedule" type="primary" onClick={() => { performSubmit(); }} icon={<FontAwesomeIcon icon={solid("calendar-plus")} />}>Schedule</Button>
            ]}>
            <Segmented
                value={selected}
                defaultValue={'Defer'}
                onChange={(v) => { changeSelected(v as string); }}
                options={['Defer', 'Interval']} />
            <Form
                form={form}
                onFinish={(values: any) => {
                    selected == "Defer"
                        ? dispatch(deferProjectWorkflowScan({ workflowId: values.workflowId, projectId: props.projectId, timeAndDate: values.timeAndDate }))
                        : dispatch(intervalProjectWorkflowScan({ workflowId: values.workflowId, projectId: props.projectId, interval: values.interval, valueUnit: valueUnit }));
                    performReset(); }}>
                <Form.Item
                    label="Workflow"
                    name="workflowId"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    labelAlign="left"
                    rules={[
                        { required: true, message: 'Workflow is required' },
                    ]}>
                    <Select placeholder="Select Workflow" style={{ width: "100%" }}>
                    {
                        workflowNamesAndIds.map((wni: any) => <Option value={wni.id}>{wni.name}</Option>)
                    }
                    </Select>
                </Form.Item>
                <div className="schedule-type" hidden={selected != 'Defer'}>
                    <Form.Item
                        label="Time and date"
                        name="timeAndDate"
                        wrapperCol={{ span: 18 }}
                        labelCol={{ span: 6 }}
                        labelAlign="left"
                        rules={[
                            { required: selected == 'Defer', message: 'Time and date are required' },
                            { validator: (_, value) => (selected != 'Defer' || !value || value > new Date()) ? Promise.resolve() : Promise.reject(new Error('Selected date and time must be in the future')) }
                        ]}>
                        <DatePicker
                            showTime
                            placeholder="Select Date"
                            style={{ width: "100%" }}
                            suffixIcon={<FontAwesomeIcon color="#434343" icon={regular('calendar')} />}
                            onChange={(value: any) => { (value && value > new Date())
                                ? setDateTimeText(value.toLocaleString())
                                : setDateTimeText("") }} />
                    </Form.Item>
                    <Row>
                        <Col offset={6} span={18}><p>Execute on: <strong>{dateTimeText}</strong></p></Col>
                    </Row>
                </div>
                <div className="schedule-type" hidden={selected != 'Interval'}>
                    <Form.Item
                        label="Interval"
                        name="interval"
                        wrapperCol={{ span: 18 }}
                        labelCol={{ span: 6 }}
                        labelAlign="left"
                        rules={[
                            { required: selected == 'Interval', message: 'Value is required' }
                        ]}>
                        <InputNumber min={1} step={1} precision={0} style={{ width: "100%" }} addonAfter={selectAfter} placeholder="Set time units" />
                    </Form.Item>
                </div>
                {/* TODO: Add later if needed. Also client-side libs for validation are not working */}
                {/* <div className="schedule-type" hidden={selected != 'Schedule'}>
                    <Form.Item
                        label="Chron expression"
                        name="cronExpression"
                        wrapperCol={{ span: 18 }}
                        labelCol={{ span: 6 }}
                        labelAlign="left"
                        rules={[
                            { required: selected == 'Schedule', message: 'Chron expression is required' },
                            { validator: (_, value) => {
                                if (selected != 'Schedule') {
                                    return Promise.resolve();
                                }
                                var tokens = cronParser.parseString(value);
                                if (Object.keys(tokens.errors).length === 0) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(new Error('Invalid cron expression'));
                                }
                            }}
                        ]}>
                        <Input
                            placeholder="* * * * *"
                            suffix={<FontAwesomeIcon color="#434343" icon={regular('stopwatch')} />}
                            onChange={(event: any) => {
                                try { setChronText(cronstrue.toString(event.target.value)); }
                                catch { setChronText(""); }
                            }} />
                    </Form.Item>
                    <Row>
                        <Col offset={6} span={18}><p>Execute: <strong>{chronText}</strong></p></Col>
                    </Row>
                </div> */}
            </Form>
        </Modal>
    );
});

export default ScheduleWorkflowModal;