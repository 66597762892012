import * as React from 'react';
import { Typography } from 'antd';
import { Connection, Node, Handle, Position, useReactFlow } from 'reactflow';
import { DataType } from '../../../../core/enums';
import { intersect, single } from '../../../../core/reduxHelpers';
import { ConnectionType, DataContract, EntryExitDef, FlowGraphValidation, LabeledHandleProps } from '../../../../types';

import 'reactflow/dist/style.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/configureAppStore';

const { Paragraph } = Typography;

export default function LabeledHandle(props: LabeledHandleProps) {
    const base = 119;
    const increment = 20;

    const validation = useSelector<RootState, FlowGraphValidation>(state => state.workflowData.validation);
    const reactFlow = useReactFlow();
    const handleRef = React.useRef(null)

    function validate(
        currentConnectionType: ConnectionType,
        currentValidDataContracts: DataContract[],
        incomingConnectionType: ConnectionType,
        incomingValidDataContracts: DataContract[]): boolean {

        if (currentConnectionType === incomingConnectionType) {
            return false;
        } else if (currentValidDataContracts.some((dt: DataContract) => dt.dataType == DataType.Any) || incomingValidDataContracts.some((dt: DataContract) => dt.dataType == DataType.Any)) {
            return true;
        } else {
            return intersect(currentValidDataContracts, incomingValidDataContracts,
                (i1, i2) => i1.dataType === i2.dataType && i1.objectType === i2.objectType).length > 0;
        }
    }

    function validateConnection(connection: Connection): boolean {
        const nodes = reactFlow.getNodes();
        const exitElt = single(nodes, e => e.id === connection.source);
        const entryElt = single(nodes, e => e.id === connection.target);
        const exitHandle: EntryExitDef = single(exitElt.data.blueprint.exits, e => e.handleId === connection.sourceHandle);
        const entryHandle: EntryExitDef = single(entryElt.data.blueprint.entries, e => e.handleId === connection.targetHandle);

        if (entryHandle.dataContracts.some((dt: DataContract) => dt.dataType == DataType.Any) || exitHandle.dataContracts.some((dt: DataContract) => dt.dataType == DataType.Any)) {
            return true;
        } else {
            return intersect(exitHandle.dataContracts, entryHandle.dataContracts,
                (i1, i2) => i1.dataType === i2.dataType && i1.objectType === i2.objectType).length > 0;
        }
    }

    React.useEffect(()=>{
        if (handleRef?.current) {
            if (validation.isValidating) {
                var isValid = validate(props.type, props.validDataContracts, validation.handleType || "source", validation.validContracts || []);
                if (isValid) {
                    (handleRef.current as Element).classList.add("white-border");
                } else {
                    (handleRef.current as Element).classList.add("red-border");
                }
            } else {
                (handleRef.current as Element).classList.remove("white-border");
                (handleRef.current as Element).classList.remove("red-border");
            }
        }
    }, [validation.isValidating]);

    return (
        <>
            <Handle
                {...props}
                ref={handleRef}
                type={props.type}
                position={props.type === "target" ? Position.Left : Position.Right}
                isValidConnection={(connection) => validateConnection(connection) }
                style={{ background: '#fff', top: `${base + props.positionIndex * increment}px` }} />
                <Paragraph
                    className='handle'
                    ellipsis={{ rows: 1, tooltip: props.label }}
                    style={{ margin: 0, textAlign: props.type === "target" ? "left" : "right" }}>
                        {props.label ?? "<unknown>"}
                </Paragraph>
        </>
    );
}
