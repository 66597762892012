import { BlueprintType, IconType, ScanNodeStatus } from "./core/enums";
import { NodeBlueprint } from "./types";

export const DEFAULT_WARNING_MESSAGE_DURATION = 5;
export const DEFAULT_ERROR_MESSAGE_DURATION = 5;
export const NEW_GUID = "00000000-0000-0000-0000-000000000000";
export const SCAN_EXIT_DATA_PAGE_SIZE = 10;

export const UNKNOWN_NODE_BLUEPRINT: NodeBlueprint = {
    nodeBlueprintId: "",
    blueprintType: BlueprintType.Unknown,
    name: "UNKNOWN",
    version: "?",
    description: "Unknown node",
    author: "Unknown",
    license: "Unknown",
    licenseUrl: "",
    hideFlagDescriptions: false,
    iconColor: "",
    iconName: "question-circle",
    iconType: IconType.FontAwesome,
    category: "Unknown",
    subcategory: "Unknown",
    entries: [],
    exits: [],
    props: [],
    propGroups: []
}

export const ScanNodeInProgressStatuses = [
    ScanNodeStatus.SetUp,
    ScanNodeStatus.BootUpInProgress,
    ScanNodeStatus.ScanInProgress,
]

export const RIGHT_TABS_STYLE = {
    margin: "0",
    border: "0",
    borderLeft: "1px solid #303030",
}

export const TOP_TABS_STYLE = {
    border: "0",
    borderBottom: "1px solid #303030"
}
