import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { routerMiddleware } from 'connected-react-router';
import { History, createBrowserHistory } from 'history';
import rootReducer from './reducers/rootReducer';
import { messagingMiddleware } from './middleware/messagingMiddleware';
import { refreshTokenThunk } from './actions/authActions';
import { Tuple, configureStore } from '@reduxjs/toolkit';

// TODO: Add hot reloading and other enhancers if necesary: https://redux.js.org/usage/configuring-your-store
function configureAppStore() {

    // Create browser history to use in the Redux store
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
    const history = createBrowserHistory({ basename: baseUrl });

    // const middleware = [
    //     thunkMiddleware,
    //     messagingMiddleware,
    //     //routerMiddleware(history)
    // ];

    // const enhancers = [];
    // const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    // if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    //     enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    // }

    // const store = createStore(
    //     rootReducer(history),
    //     undefined,
    //     compose(applyMiddleware(...middleware), ...enhancers)
    // );

    const store = configureStore({
        reducer: rootReducer(history),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(messagingMiddleware)
    })

    store.dispatch(refreshTokenThunk());

    return store;
}

export const store = configureAppStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
