import { IncomingActionCallback } from "../types";
import { HubType } from "./enums";
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default class Hub {
    private _incomingActions: IncomingActionCallback[] = [];
    private _hubType: HubType;
    private _url: string;
    private _connection: HubConnection | undefined;
    private _store: any;

    constructor(hubType: HubType, url: string, store: any) {
        this._hubType = hubType;
        this._url = url;
        this._store = store;

        const token = store.getState().auth.accessToken;
        this.resetConnection(token);
    }

    private actionCallback = (callback: any) => (...data: any) => {
        return callback(this._store, ...data);
    }

    public addCallback = (actionName: string, callback: (store: any, ...data: any) => void) => {
        this._incomingActions.push({
            actionName: actionName,
            callback: this.actionCallback(callback),
        });

        this._connection?.on(actionName, callback);

        return this;
    }

    public intiateConnection() {
        this._connection?.start();
    }

    public resetConnection(token: string | null) {
        if (token) {
            console.log("Token found, connecting with identity")
            this._connection = new HubConnectionBuilder()
                .configureLogging(LogLevel.Debug)
                .withUrl(this._url, {
                    accessTokenFactory: () => token,
                    headers: { "Authorization": `bearer ${token}` }
                })
                .withAutomaticReconnect()
                .build();
            this._connection?.stop();
            this._incomingActions.forEach(ia => {
                this._connection?.on(ia.actionName, ia.callback);
            });
            this._connection?.start();
        } else {
            console.log("Token not found, disconnecting")
            this._incomingActions.forEach(ia => {
                this._connection?.stop();
            });
        }
    }
}