import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ProgrammableNodesPageStateProps } from "../../../types";

export default function programmableNodesInitializer(payload: any): ProgrammableNodesPageStateProps {
    return {
        nodes: [],
        manifestTreeData: [],
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("code"),
        tabText: "Programmable nodes",
        isLoading: true,
        params: []
    };
}