import { DefaultOptionType } from "antd/lib/select";
import { FileAndSize, FileAutoCompleteObject, TreeItemObject } from "../types";
import { TreeItemObjectType } from "./enums";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataNode } from "antd/lib/tree";
import { single } from "./reduxHelpers";
import { some } from "lodash";

export function getDirectories(treeItems: TreeItemObject[]): string[] {
    var dirItems = treeItems.filter(i => i.type == TreeItemObjectType.Directory);
    var dirPaths = dirItems.map(i => i.key);

    var childPaths: string[] = dirItems
        .map(i => getDirectories(i.children))
        .reduce((a: string[], b: string[]) => a.concat(b), [])

    var root = [ "/" ];

    return dirPaths
        .concat(childPaths)
        .concat(root)
        .filter((value, index, array) => array.indexOf(value) === index);
}

export function getSelectValuesFromStr(treeItems: TreeItemObject[], filterByType?: TreeItemObjectType): FileAutoCompleteObject[] {
    var currentItems: FileAutoCompleteObject[] = treeItems
        .filter(i => (filterByType && i.type === filterByType) || (!filterByType))
        .map(i => ({
            label: <><FontAwesomeIcon
                icon={["fas", i.type === TreeItemObjectType.Directory ? "folder-open" : "file-lines"]}
                className={ i.type === TreeItemObjectType.Directory ? "dir-cell" : "file-cell" } /> {i.key}</>,
            value: i.key,
            type: i.type,
            children: [],
        }));

    var childItems = treeItems
        .map(i => getSelectValuesFromStr(i.children, filterByType))
        .reduce((a: FileAutoCompleteObject[], b: FileAutoCompleteObject[]) => a.concat(b), [])

    return currentItems.concat(childItems);
}

export function filterFileTreeItemsByName(treeItems: TreeItemObject[], filterByName: string): TreeItemObject[] {
    if (treeItems && treeItems.length > 0) {
        var filteredItems = treeItems.filter(i => {
            if (i.type == TreeItemObjectType.Directory) {
                const filteredChildren = filterFileTreeItemsByName(i.children, filterByName);
                return filteredChildren && filteredChildren.length > 0;
            } else if (i.type == TreeItemObjectType.NormalFile) {
                return i.name.match(filterByName) !== null;
            }
        })
        return filteredItems;
    } else {
        return treeItems;
    }
}

export function getFullFilePaths(treeItems: TreeItemObject[], filterByName: string = ".*"): FileAndSize[] {
    var objects = [...treeItems];
    var results = [];
    var idx = 0;

    while (idx < objects.length)
    {
        if (objects[idx].type == TreeItemObjectType.NormalFile && objects[idx].name.match(filterByName)) {
            results.push({
                filePath: objects[idx].key,
                fileSize: objects[idx].size
            });
        }
        objects.push(...objects[idx].children);
        idx += 1;
    }

    return results;
}

export function fullPathsToTreeItems(paths: FileAndSize[]): TreeItemObject[] {
    var root: TreeItemObject = {
        name: "/",
        path: "/",
        key: "/",
        icon: "folder",
        size: 0,
        type: TreeItemObjectType.Directory,
        children: []
    };

    paths.forEach(path => {
        var fileParts = path.filePath.split('/').filter(p => !!p);
        var dirParts = fileParts.slice(0, -1);
        var fileName = fileParts.slice(-1)[0];
        var pointer = root;
        var subPath = "";
        dirParts.forEach(dirPart => {
            subPath += `/${dirPart}`;
            if (!some(pointer.children, c => c.name == dirPart && c.type == TreeItemObjectType.Directory)) {
                pointer.children.push({
                    name: dirPart,
                    key: `${subPath}/`,
                    path: `${subPath}/`,
                    icon: "folder-open",
                    type: TreeItemObjectType.Directory,
                    size: 0,
                    children: [],
                });
            }
            pointer = single(pointer.children, c => c.name == dirPart);
            pointer.size += path.fileSize;
        });
        pointer.children.push({
            name: fileName,
            key: path.filePath,
            path: path.filePath,
            icon: "file-lines",
            type: TreeItemObjectType.NormalFile,
            size: path.fileSize,
            children: [],
        });
    });

    return root.children;
}

export function checkFileExists(treeItems: FileAutoCompleteObject[], fullPath: string): boolean {
    fullPath = fullPath ?? "/";
    fullPath = fullPath[0] === "/" ? fullPath : `/${fullPath}`;

    return treeItems.some(i => i.value === fullPath && i.type == TreeItemObjectType.NormalFile);
}

export function mapData(items: TreeItemObject[]): DataNode[] {
    return items
        .map(i => ({
            title: i.name,
            key: i.key,
            icon: i.type != TreeItemObjectType.Directory
                 ? <FontAwesomeIcon icon={["fas", i.icon]} className="file-cell" />
                 : ({ expanded }: any) => expanded
                    ? <FontAwesomeIcon icon={["fas", "folder-open"]} className="dir-cell" />
                    : <FontAwesomeIcon icon={["fas", "folder-closed"]} className="dir-cell" />,
            type: i.type,
            selected: false,
            children: mapData(i.children)
        }))
        .sort((a, b) => a.type - b.type || a.title.localeCompare(b.title));
}