import * as React from 'react';
import moment from 'moment';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import DangerousConfirmButton from '../components/ui/DangerousConfirmButton';
import { connect, useDispatch } from 'react-redux';
import { Button, Table, Row, Col } from 'antd';
import { openViewWorkflowTab } from '../store/slices/workflowsSlice';
import { IApplicationState, ScanSchedule, ScanSchedulesPageDispatchProps, ScanSchedulesPageOwnProps, ScanSchedulesPageProps, ScanSchedulesPageStateProps } from '../types';
import { bindActionCreators } from 'redux';
import { selectScanSchedulesPage } from '../store/selectors';
import { openViewProjectTabAction } from '../store/actions/projectActions';
import { ExecutionType, TimeUnits, TimeUnitsDescription } from '../core/enums';
import { deleteScanScheduleThunk } from '../store/actions/scanActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppDispatch } from '../store/configureAppStore';

const { Column } = Table;

function ScanSchedulesPage(props: ScanSchedulesPageProps) {
    if (!props.scanSchedules) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    const dispatch = useDispatch<AppDispatch>();

    const executionTypeString = (execType: ExecutionType) => {
        switch (execType) {
            case ExecutionType.Deferred: return "Deferred";
            case ExecutionType.Interval: return "Interval";
            default: return "Unknown";
        }
    }

    const scheduleWhenString = (record: ScanSchedule) => {
        switch (record.executionType) {
            case ExecutionType.Deferred: return <>Schedule on: <strong>{moment(record.runDateTime).format("LL")}</strong> at: <strong>{moment(record.runDateTime).format("LT")}</strong></>;
            case ExecutionType.Interval: return <>Interval: <strong>Every {record.interval} {TimeUnitsDescription.get(record.units ?? TimeUnits.Unknown)}</strong></>;
            default: return "Unknown";
        }
    }

    return (
        <GutteredPage>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={props.scanSchedules}
                        rowKey="id"
                        pagination={{position: ["bottomLeft"]}}
                        scroll={{ x: true }}>
                        <Column title="Project" dataIndex="projectName" render={(text: any, record: ScanSchedule) => <Button type="link" icon={<FontAwesomeIcon icon={solid("globe")} />} onClick={() => props.openViewProjectTab(record.projectId)}>{text}</Button>} />
                        <Column title="Workflow" dataIndex="workflowName" render={(text: any, record: ScanSchedule) => <Button type="link" icon={<FontAwesomeIcon icon={solid("diagram-project")} />} onClick={() => openViewWorkflowTab(dispatch, record.workflowId)}>{text}</Button>} />
                        <Column title="Schedule type" dataIndex="executionType" render={(_: any, record: ScanSchedule) => <>{ executionTypeString(record.executionType) }</>} />
                        <Column title="Schedule" render={(_, record: ScanSchedule) => scheduleWhenString(record)}/>
                        <Column title="Actions" render={(_, record: ScanSchedule) => (
                            <>
                                <DangerousConfirmButton
                                    popupText='Delete scan schedule?'
                                    icon={solid("trash-can")}
                                    isInProgress={record.isDeleteInProgress}
                                    onConfirm={() => props.deleteScanSchedule(record.id)} />
                            </>
                        )}/>
                    </Table>
                </Col>
            </Row>
        </GutteredPage>
    );
}


export default connect<ScanSchedulesPageStateProps, ScanSchedulesPageDispatchProps, ScanSchedulesPageOwnProps, IApplicationState>(
    (state: IApplicationState, ownProps: any) => selectScanSchedulesPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        deleteScanSchedule: deleteScanScheduleThunk,
        openViewProjectTab: openViewProjectTabAction,
    }, dispatch)
)(ScanSchedulesPage)