import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import { Workflow, WorkflowPageProps } from '../types';
import WorkflowEdit from '../components/WorkflowEdit';
import WorkflowDetails from '../components/WorkflowDetails';
import { Alert } from 'antd';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppDispatch, RootState } from '../store/configureAppStore';
import { fetchWorkflow, selectWorkflow } from '../store/slices/workflowsSlice';

export default function WorkflowPage(props: WorkflowPageProps) {

    const dispatch = useDispatch<AppDispatch>();

    const pageData = useSelector<RootState, any>(state => state.pages.tabs.find(t => t.tabId === props.tabId)?.params);
    const workflow = useSelector<RootState, Workflow>(state => selectWorkflow(state, props.tabId, pageData.workflowId ?? ""));

    React.useEffect(() => {
        if (!workflow.isDeleted && !workflow.isCreateMode) {
            dispatch(fetchWorkflow({ workflowId: workflow.workflowId }));
        }
    }, []);

    if (pageData.isLoading) {
        return (
            <GutteredPage><FontAwesomeIcon icon={solid("loader")} spin className='page-loading' /></GutteredPage>
        );
    }

    return (
        <GutteredPage>
            {
                workflow.isDeleted
                    ? <Alert
                        message="Workflow Deleted"
                        description="This workflow has already been deleted."
                        type="error"
                        icon={<FontAwesomeIcon icon={solid("trash-can")} />}
                        showIcon />
                    : <></>
            }
            {
                (workflow.isEditMode || workflow.isCreateMode)
                    ? <WorkflowEdit workflow={workflow} />
                    : <WorkflowDetails workflow={workflow} />
            }
        </GutteredPage>
    );
}
