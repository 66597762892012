import React from 'react';
import { ElementDisplayProps } from '../../types';

export default function ImageDisplay(elementDisplayProps: ElementDisplayProps) {
    var images = elementDisplayProps.results.map(r => {
        return (
            <div style={{ marginBottom: "20px" }}>
                <h4>{r.FileName}</h4>
                <img src={`data:image/gif;base64,${atob(r.Data)}`} style={{ width: "100%" }}/>
            </div>
        );
    });
    return (<>{images}</>);
}