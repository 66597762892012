import { Dictionary } from "@reduxjs/toolkit";
import { arrayUpdateAll, dictDelete, dictUpdateByKey, toDictionary } from "../../core/reduxHelpers";
import { Project, ProjectPageState } from "../../types";
import * as Names from "../actionNames";

function projectReducer(state: Dictionary<Project> = {}, action: any): Dictionary<Project> {
    switch (action.type) {

        // Loading a single project from server is finished - upsert the project object
        // This is usually a more detailed load, containing description and seed resources.
        // TODO: loadProjectAction and projectUpdateFinished both update the project. this should be reprogramed into single responsibility.
        case Names.loadProjectActionName:
            if (action.payload.response.isSuccessful) {
                const loadedProject = action.payload.response.result;
                return dictUpdateByKey(state, loadedProject.projectId, {
                    ...loadedProject,
                    isCreateMode: false,
                    isEditMode: action.payload.isEdit,
                    isSaveInProgress: false,
                    isDeleteInProgress: false
                });
            } else {
                return state;
            }

        // Loading multiple projects from server is finished - upsert the project object
        // This is usually a simplified data version. Each Project object contains only data needed for table row output.
        case Names.loadProjectsActionName:
            if (action.payload.response.isSuccessful) {
                const p = arrayUpdateAll(action.payload.response.result.projects, { isDeleteInProgress: false });
                return {
                    ...state,
                    ...toDictionary(p, (el: any) => el.projectId)
                };
            } else {
                return state;
            }

        // Start a server operation of storing the project updates.
        case Names.projectUpdateInProgressActionName:
            return dictUpdateByKey(state, action.payload.projectId, { isSaveInProgress: true })

        // Server operation for storing the Project updates has completed.
        case Names.projectUpdateFinishedActionName:
            if (action.payload.response.isSuccessful) {
                const newProjectId = action.payload.response.result.projectId;
                return dictUpdateByKey(state, newProjectId, {
                    ...action.payload.response.result,
                    isSaveInProgress: false,
                    // Disable create edit mode when saving is done, but only if the project was
                    // created - we move to view mode
                    isCreateMode: action.payload.response.isSuccessful ? false : action.payload.isCreateMode
                })
            } else {
                if (!action.payload.isCreateMode)
                    return dictUpdateByKey(state, action.payload.projectId, { isSaveInProgress: false });
                else
                    return state;
            }

        // Start a delete Project operation on the backend server.
        case Names.projectDeleteInProgressActionName:
            return dictUpdateByKey(state, action.payload.projectId, { isDeleteInProgress: true })

        // The delete operation has been completed.
        case Names.projectDeleteFinishedActionName:
            if (action.payload.response.isSuccessful) {
                return dictDelete(state, action.payload.projectId);
            } else {
                return dictUpdateByKey(state, action.payload.projectId, { isDeleteInProgress: false })
            }

        default:
            return state;
    }
}

function projectPageReducer(state: ProjectPageState, action: any): ProjectPageState {
    switch (action.type) {
        case Names.updateProjectPageDataActionName:
            let tabText = `Project: ${action.payload.project.name}` ?? "No name";
            if (state.project?.isCreateMode) {
                tabText = `Create Project: ${action.payload.project.name}`
            }
            if (state.project?.isEditMode) {
                tabText = `Edit Project: ${action.payload.project.name}`
            }
            return {
                ...state,
                tabText: tabText,
                projectId: action.payload.project.projectId ?? state.projectId,
                project: { ...state.project, ...action.payload.project }
            };

        default:
            return state;
    }
}

export {
    projectReducer,
    projectPageReducer
}