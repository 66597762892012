// TODO: see to move this into union perhaps: https://stackoverflow.com/a/60041791/2413587
export enum PageType {
    Unknown = -1,
    Projects = 1,
    Project,
    Workflows,
    Workflow,
    FlowGraph,
    Scans,
    Scan,
    ScanSchedules,
    ProgrammableNodes,
    ReportTemplates,
    Files,
    EditFile,
    Settings,
    Admin_Notifications,
    Admin_Notification,
    Admin_UsersAndGroups,
}

// Types of icons possible on nodes
export enum IconType { // TODO: Map automatically from C#
    Ant = 1,        // Currently not implemented
    FontAwesome
}

//
// DATA TYPES
//

export enum SettingType {
    Bool = 1,
    Int = 2,
    Decimal = 3,
    String = 4,
    Enum = 5,
    MultiEnum = 6,
    List = 7,
    Password = 8,
    OAuthToken = 9
}

// This is the front-end copy of the following back-end enum: Core > Enums > Node > SeedResourceType
// TODO: See how we could regenerate this automatically perhaps?
export enum DataType {  // TODO: Map automatically from C#
    Empty = 0,
    Bool = 100,
    Int = 200,
    Decimal = 300,
    String = 400,
    Enum = 500,
    MultiEnum = 600,
    FileInput = 700,
    FileOutput = 800,
    Directory = 900,
    LongString = 1000,
    ASN = 1100,
    CIDR = 1200,
    CIDRv4 = 1300,
    CIDRv6 = 1400,
    IP = 1500,
    IPv4 = 1600,
    IPv4WithPort = 1601,
    IPv6 = 1700,
    IPv6WithPort = 1701,
    Port = 1800,
    Domain = 1900,
    Url = 2000,
    File = 2100,
    Request = 2200,
    Response = 2300,
    Object = 2400,
    Password = 2500,
    TagsList = 2600,

    Any = 30000,
    Complex = 30001,
    Command = 30002,
    Mixed = 30003,
};

export const SeedResourceDescriptions = new Map<DataType, string>([
    [DataType.ASN, 'ASN'],
    [DataType.CIDRv4, 'CIDRv4'],
    [DataType.CIDRv6, 'CIDRv6'],
    [DataType.IPv4, 'IPv4'],
    [DataType.IPv4WithPort, 'IPv4 with Port'],
    [DataType.IPv6, 'IPv6'],
    [DataType.IPv6WithPort, 'IPv6 with Port'],
    [DataType.Domain, 'Domain'],
    [DataType.Url, 'Url'],
    [DataType.String, 'String'],
    [DataType.File, 'File'],
]);

//
// Node Exits
//
export class NodeBlueprintExitType // TODO: Map automatically from C#
{
    public static Amass_IntelData: string = "Amass_IntelData";
    public static AmassEnum_RawBlob: string = "AmassEnum_RawBlob";
    public static AmassEnum_SubdomainResults: string = "AmassEnum_SubdomainResults";
    public static AmassIntel_RawBlob: string = "AmassIntel_RawBlob";
    public static AmassIntel_SubdomainResults: string = "AmassIntel_SubdomainResults";
    public static AmassV4_RawBlob: string = "AmassV4_RawBlob";
    public static AmassV4_Results: string = "AmassV4_Results";
    public static Aquatone_PagesData: string = "Aquatone_PagesData";
    public static Aquatone_RawBlob: string = "Aquatone_RawBlob";
    public static Aquatone_Screenshots: string = "Aquatone_Screenshots";
    public static Aquatone_SimilarityClusters: string = "Aquatone_SimilarityClusters";
    public static Aquatone_SourceFiles: string = "Aquatone_SourceFiles";
    public static Arjun_RawBlob: string = "Arjun_RawBlob";
    public static Arjun_Urls: string = "Arjun_Urls";
    public static Censys_Data: string = "Censys_Data";
    public static ConditionalTagging_Outputs: string = "ConditionalTagging_Outputs";
    public static Converter_Conversions: string = "Converter_Conversions";
    public static CrtSh_Data: string = "CrtSh_Data";
    public static DnsX_RawOutput: string = "DnsX_RawOutput";
    public static DnsX_Results: string = "DnsX_Results";
    public static DroopeScan_Results: string = "DroopeScan_Results";
    public static EyeWitness_RawBlob: string = "EyeWitness_RawBlob";
    public static EyeWitness_ResponseData: string = "EyeWitness_ResponseData";
    public static EyeWitness_Screenshots: string = "EyeWitness_Screenshots";
    public static EyeWitness_SourceFiles: string = "EyeWitness_SourceFiles";
    public static FFUF_RawBlob: string = "FFUF_RawBlob";
    public static FFUF_Results: string = "FFUF_Results";
    public static FileIn_Files: string = "FileIn_Files";
    public static FullHunt_Data: string = "FullHunt_Data";
    public static GAU_RawBlob: string = "GAU_RawBlob";
    public static GAU_Urls: string = "GAU_Urls";
    public static Gobuster_DirSubdirectories: string = "Gobuster_DirSubdirectories";
    public static Gobuster_DnsSubdomains: string = "Gobuster_DnsSubdomains";
    public static GoBuster_FoundSubdirectories: string = "GoBuster_FoundSubdirectories";
    public static GoBuster_FoundSubdomains: string = "GoBuster_FoundSubdomains";
    public static Gobuster_FuzzUrls: string = "Gobuster_FuzzUrls";
    public static Gobuster_GCSUrls: string = "Gobuster_GCSUrls";
    public static Gobuster_RawBlob: string = "Gobuster_RawBlob";
    public static Gobuster_S3Urls: string = "Gobuster_S3Urls";
    public static Gobuster_TFTPDomains: string = "Gobuster_TFTPDomains";
    public static Gobuster_VHostDomains: string = "Gobuster_VHostDomains";
    public static Hakrawler_Results: string = "Hakrawler_Results";
    public static History_CurrentData: string = "History_CurrentData";
    public static History_HistoricalData: string = "History_HistoricalData";
    public static HistoryCompare_Additions: string = "HistoryCompare_Additions";
    public static HistoryCompare_CurrentItems: string = "HistoryCompare_CurrentItems";
    public static HistoryCompare_Removals: string = "HistoryCompare_Removals";
    public static Httprobe_Results: string = "Httprobe_Results";
    public static HttpX_RawOutput: string = "HttpX_RawOutput";
    public static HttpX_Results: string = "HttpX_Results";
    public static JoomScan_Results: string = "JoomScan_Results";
    public static Kiterunner_RawOutput: string = "Kiterunner_RawOutput";
    public static Kiterunner_Results: string = "Kiterunner_Results";
    public static Masscan_RawOutput: string = "Masscan_RawOutput";
    public static Masscan_Results: string = "Masscan_Results";
    public static Merge_Outputs: string = "Merge_Outputs";
    public static Naabu_RawOutput: string = "Naabu_RawOutput";
    public static Naabu_Results: string = "Naabu_Results";
    public static Nmap_HostsAll: string = "Nmap_HostsAll";
    public static Nmap_HostsDown: string = "Nmap_HostsDown";
    public static Nmap_HostsUp: string = "Nmap_HostsUp";
    public static Nuclei_RawBlob: string = "Nuclei_RawBlob";
    public static Nuclei_Results: string = "Nuclei_Results";
    public static ParamSpider_AllUrls: string = "ParamSpider_AllUrls";
    public static ParamSpider_RawBlob: string = "ParamSpider_RawBlob";
    public static ParamSpider_UrlsWithParameters: string = "ParamSpider_UrlsWithParameters";
    public static Raw_Blob: string = "Raw_Blob";
    public static RegexFilter_MatchedOutputs: string = "RegexFilter_MatchedOutputs";
    public static RegexFilter_UnmatchedOutputs: string = "RegexFilter_UnmatchedOutputs";
    public static RegexUpdate_Outputs: string = "RegexUpdate_Outputs";
    public static RemoveProperty_Outputs: string = "RemoveProperty_Outputs";
    public static SeedResources_ASNs: string = "SeedResources_ASNs";
    public static SeedResources_CIDRv4: string = "SeedResources_CIDRv4";
    public static SeedResources_CIDRv6: string = "SeedResources_CIDRv6";
    public static SeedResources_Domains: string = "SeedResources_Domains";
    public static SeedResources_IPv4: string = "SeedResources_IPv4";
    public static SeedResources_IPv4WithPort: string = "SeedResources_IPv4WithPort";
    public static SeedResources_IPv6: string = "SeedResources_IPv6";
    public static SeedResources_IPv6WithPort: string = "SeedResources_IPv6WithPort";
    public static SeedResources_Urls: string = "SeedResources_Urls";
    public static SeedResources_Strings: string = "SeedResources_Strings";
    public static SeedResources_Files: string = "SeedResources_Files";
    public static SetOperations_Outputs: string = "SetOperations_Outputs";
    public static Shodan_Data: string = "Shodan_Data";
    public static Split_Outputs_1: string = "Split_Outputs_1";
    public static Split_Outputs_2: string = "Split_Outputs_2";
    public static Split_Outputs_3: string = "Split_Outputs_3";
    public static Split_Outputs_4: string = "Split_Outputs_4";
    public static Split_Outputs_5: string = "Split_Outputs_5";
    public static Split_OutputsRest: string = "Split_OutputsRest";
    public static SQLMap_Results: string = "SQLMap_Results";
    public static Sublist3r_RawBlob: string = "Sublist3r_RawBlob";
    public static Sublist3r_Results: string = "Sublist3r_Results";
    public static SwitchNode_Outputs_1: string = "SwitchNode_Outputs_1";
    public static SwitchNode_Outputs_2: string = "SwitchNode_Outputs_2";
    public static SwitchNode_Outputs_3: string = "SwitchNode_Outputs_3";
    public static SwitchNode_Outputs_4: string = "SwitchNode_Outputs_4";
    public static SwitchNode_Outputs_5: string = "SwitchNode_Outputs_5";
    public static SwitchNode_OutputsRest: string = "SwitchNode_OutputsRest";
    public static Tagging_Outputs: string = "Tagging_Outputs";
    public static TaggingFilter_MatchedOutputs: string = "TaggingFilter_MatchedOutputs";
    public static TaggingFilter_UnmatchedOutputs: string = "TaggingFilter_UnmatchedOutputs";
    public static TruffleHog_Secrets: string = "TruffleHog_Secrets";
    public static Unique_Outputs: string = "Unique_Outputs";
    public static VirusTotal_Data: string = "VirusTotal_Data";
    public static Wappalyzer_Technologies: string = "Wappalyzer_Technologies";
    public static WaybackUrls_Urls: string = "WaybackUrls_Urls";
    public static WebAnalyze_RawBlob: string = "WebAnalyze_RawBlob";
    public static WebAnalyze_Technologies: string = "WebAnalyze_Technologies";
    public static WhatWeb_Technologies: string = "WhatWeb_Technologies";
    public static WPScan_Results: string = "WPScan_Results";
    public static Yara_GroupedResults: string = "Yara_GroupedResults";
    public static Yara_MatchedFiles: string = "Yara_MatchedFiles";
    public static Yara_RawBlob: string = "Yara_RawBlob";
    public static Yara_Results: string = "Yara_Results";
    public static Yara_UnmatchedFiles: string = "Yara_UnmatchedFiles";
};


//
// Nodes
//

// This could be used in the future to implement different kinds of edge UIs
export enum EdgeUIType {
    Default = 1
}

// This could be used in the future to implement different kinds of node UIs
export enum NodeUIType {
    Default = 1
}

//
// Other Statuses
//

export enum ScanStatus // TODO: Map automatically from C#
{
    Unknown = -1,
    SetUpInProgress = 10,
    SetupSucceeded = 20,
    SetupFailed = 30,
    ScanInProgress = 40,
    ScanFinishedSuccess = 50,
    ScanFinishedNodeWarning = 60,
    ScanFinishedSystemError = 70,
    ScanFinishedNodeError = 80,
    ScanFinishedNodeException = 90,
    ScanFinishedNodeBootUpFailed = 100,
    ScanSkipped = 10000,
}

export enum ScanNodeStatus { // TODO: Map automatically from C#
    SetUp = 10,
    BootUpInProgress = 20,
    BootUpSucceeded = 30,
    BootUpFailed = 40,
    ScanInProgress = 60,
    ScanFinishedSuccess = 70,
    ScanFinishedWarning = 80,
    ScanFinishedError = 90,
    ScanFinishedException = 100,
}

export enum ScanNodeShutdownStatus { // TODO: Map automatically from C#
    Running = 10,
    ShutdownInProgress = 20, // Currently not used
    ShutdownSuccess = 30,
    ShutdownError = 40,
}

export enum LoginScreenType { // TODO: Map automatically from C#
    ValidatingLogin = 1,
    PerformLogin,
    PerformSignUp,
    ForgotPassword,
    ForceResetPassword,
    NoAccess,
    LoggedIn,
}

export enum NotificationAlertType { // TODO: Map automatically from C#
    Info,
    Warning,
    Alert,
}

export enum UserGroupType { // TODO: Map automatically from C#
    User,
    Group,
    Everyone,
}

export enum HubType {
    ScanHub
}

export enum SettingSectionType { // TODO: Map automatically from C#
    CommunicationSettings,
    IntegrationCredentials,
}

export enum SettingCoverageType { // TODO: Map automatically from C#
    Organization = 1,
    User = 2,
    Project = 3,
}

export enum Necessity { // TODO: Map automatically from C#
    Optional,
    Recommended,
    ConditionallyRequired,
    Required
}

export enum SettingUsageType { // TODO: Map automatically from C#
    Node
}

export enum ExecutionType {
    Immediate,
    Deferred,
    Interval,
    Cron,
}

export enum TimeUnits { // TODO: Map automatically from C#
    Unknown = 0,
    Minutes,
    Hours,
    Days
}

export const TimeUnitsDescription = new Map<TimeUnits, string>([
    [TimeUnits.Days, 'days'],
    [TimeUnits.Hours, 'hours'],
    [TimeUnits.Minutes, 'minutes'],
    [TimeUnits.Unknown, '<unknown>'],
]);

export enum TreeItemObjectType
{
    Directory = 1,
    NormalFile,
    Unknown,
}

export enum BlueprintType {
    Unknown,
    IntegratedTool,
    Programmable,
}

export enum LicenseAgreementType {
    TermsAndConditions = 1,
    PrivacyPolicy
}