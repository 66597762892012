import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NEW_GUID } from "../../../constants";
import { ScanPageState } from "../../../types";
import { ScanStatus } from '../../../core/enums';

export default function scanPageInitializer(payload: any): ScanPageState {
    const scanId = (payload.data && payload.data.scanId) || NEW_GUID;

    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("satellite-dish"),
        tabText: "Scan",
        scanId: scanId,
        isScanAlreadyDeleted: false,
        isDeleteInProgress: false,
        params: [],
        scan: {
            scanId: NEW_GUID,
            scanName: "<unknown scan>",
            message: "",
            projectId: NEW_GUID,
            projectVersion: 1,
            workflowId: NEW_GUID,
            workflowVersion: 1,
            projectName: "",
            workflowName: "",
            scanStart: "",
            scanEnd: undefined,
            scanStatus: ScanStatus.Unknown,
            isReviewed: false,
            scanNodes: {},
            isDeleteInProgress: false
        }
    };
}