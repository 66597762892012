import * as React from 'react';
import { WorkflowDetailsProps } from "../types";
import { Button, Col, Row, Typography } from 'antd';
import MarkdownFormatter from './ui/MarkdownFormatter';
import DangerousConfirmButton from './ui/DangerousConfirmButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/configureAppStore';
import { openEditWorkflowTab, openEditFlowGraphTab, deleteWorkflow } from '../store/slices/workflowsSlice';

const { Title, Text } = Typography;

export default function WorkflowDetails(props: WorkflowDetailsProps) {

    const dispatch = useDispatch<AppDispatch>();
    const isDisabled = props.workflow.isDeleted;

    return (
        <>
            <Row>
                <Col span={24} className='workflow-details'>
                    <Title className='main-title'>{props.workflow.name}</Title>
                    <Text className='meta' type="secondary">Workflow created on: {new Date(props.workflow.dateTimeCreated).toLocaleString()}</Text><br />
                    {
                        props.workflow.dateTimeCreated != props.workflow.dateTimeUpdated
                        ? <><Text className='meta' type="secondary">Workflow updated on: {new Date(props.workflow.dateTimeUpdated).toLocaleString()} (ver {props.workflow.version})</Text><br /></>
                        : <></>
                    }
                    <MarkdownFormatter>{props.workflow.description}</MarkdownFormatter>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button
                        type='primary'
                        disabled={isDisabled}
                        icon={<FontAwesomeIcon icon={solid("pen-to-square")} />}
                        onClick={() => { openEditWorkflowTab(dispatch, props.workflow.workflowId) }}>Edit</Button>
                    <DangerousConfirmButton
                        popupText={<>Delete workflow</>}
                        icon={solid("trash-can")}
                        isInProgress={props.workflow.isDeleteInProgress}
                        disabled={isDisabled}
                        onConfirm={() => { dispatch(deleteWorkflow({ workflowId: props.workflow.workflowId })) }}>Delete</DangerousConfirmButton>
                    <Button
                        type='text'
                        disabled={isDisabled}
                        icon={<FontAwesomeIcon icon={solid("diagram-project")} />}
                        onClick={() => { openEditFlowGraphTab(dispatch, props.workflow.workflowId) }}>Edit flow chart</Button>
                </Col>
            </Row>
        </>
    );
}
