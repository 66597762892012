import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function New(props: any) {
    return (
        <span className="new-marker"><FontAwesomeIcon icon={solid("sparkles")} /> New</span>
    );
}

New.defaultProps = {
    classNames: [],
    style: {}
}