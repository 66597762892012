import React, { Key } from "react";
import GutteredPage from "../components/pageInterfaces/GutteredPage";
import { AutoComplete, Button, Card, Col, Descriptions, Empty, Modal, Popover, Progress, Row, Tree, Typography, UploadFile, UploadProps, notification } from "antd";
import { connect } from "react-redux";
import { FilesPageDispatchProps, FilesPageOwnProps, FilesPageProps, FilesPageStateProps, IApplicationState } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import { selectFilesPage } from "../store/selectors";
import { openEditFileTabAction, fetchFilesThunk, deleteFilesThunk, fetchZippedFileOrDirThunk } from "../store/actions/filesActions";
import { communication } from "../core/communication";
import { DataNode } from "antd/lib/tree";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DangerousConfirmButton from "../components/ui/DangerousConfirmButton";
import Dragger from "antd/lib/upload/Dragger";
import { getDirectories, mapData } from "../core/treeHelper";

const { DirectoryTree } = Tree;
const { Text } = Typography;

function FilesPage(props: FilesPageProps) {

    const [selectedKeys, setSelectedKeys] = React.useState<Key[]>([]);
    const [treeData, setTreeData] = React.useState<DataNode[]>([]);
    const [dirs, setDirs] = React.useState<{ value: string, label: string }[]>([]);
    const [uploadModalType, setUploadModalType] = React.useState<string | undefined>(undefined);
    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadPath, setUploadPath] = React.useState("");

    React.useEffect(() => {
        setTreeData(mapData(props.files));
        setDirs([...getDirectories(props.files).map(d => ({ value: d, label: d }))]);
    }, [ props.files ]);

    const uploadProps: UploadProps = {
        name: 'file',
        headers: {
            authorization: communication.getToken(),
        },
        multiple: true,
        accept: uploadModalType === "upload-and-unpack" ? ".tar.gz" : "",
        onChange(info) {
            switch (info.file.status) {
                case "uploading":
                    setIsUploading(true);
                    break;
                case "done":
                    if (info.file.response.isSuccessful) {
                        notification.success({
                            message: "Success",
                            description: `Successfully uploaded the file \"${info.file.name}\"`
                        });
                        props.fetchFiles();
                    } else {
                        notification.error({
                            message: "Error",
                            description: `Failed to upload the file \"${info.file.name}\"${info.file.response?.messageDescription ? ": " + info.file.response?.messageDescription : ""}`
                        });
                        info.file.status = "error";
                    }
                    setIsUploading(false);
                    break;
                case "error":
                    notification.error({
                        message: "Error",
                        description: `Failed to upload the file \"${info.file.name}\": Either the file is too large or there is a network issue`
                    });
                    setIsUploading(false);
                    break;
            }
        },
        itemRender: (_1, file, _2, { remove }) => <UploadedFile file={file} remove={remove} />,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const usedPercent = Math.round(props.usedSizeBytes / props.maxSizeBytes * 100);

    const humanFileSize = (size: number) => {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        var size = Number((size / Math.pow(1024, i)).toFixed(2));
        if (Number.isNaN(size)) {
            return "Unknown";
        }
        return size * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB'][i];
    }

    const content = <>
        <Descriptions title="Filesystem usage" bordered size="small" column={1}>
            <Descriptions.Item label="Total space">{humanFileSize(props.maxSizeBytes)}</Descriptions.Item>
            <Descriptions.Item label="Used space">{humanFileSize(props.usedSizeBytes)}</Descriptions.Item>
            <Descriptions.Item label="Free space">{humanFileSize(props.maxSizeBytes - props.usedSizeBytes)}</Descriptions.Item>
            <Descriptions.Item label="Used percent">{usedPercent}%</Descriptions.Item>
        </Descriptions>
    </>

    return (
        <GutteredPage>
            <Row>
                <Col span={18}>
                    <Button type="primary" icon={<FontAwesomeIcon icon={solid("upload")} />} onClick={() => setUploadModalType("upload")}>Upload from desktop</Button>
                    <Button type="primary" icon={<FontAwesomeIcon icon={solid("file-zipper")} />} onClick={() => setUploadModalType("upload-and-unpack")}>Upload and extract</Button>
                    {/* <Button type="primary" onClick={() => { }} icon={<FontAwesomeIcon icon={solid("cloud-arrow-down")} />}>Download from Internet</Button> */}
                    <Button type="primary" onClick={() => props.fetchZippedFileOrDir(selectedKeys)} icon={<FontAwesomeIcon icon={solid("download")} />} disabled={(() => selectedKeys.length == 0)()}>Download</Button>
                    <DangerousConfirmButton
                        popupText={<>Delete file?</>}
                        icon={solid("trash-can")}
                        disabled={(() => selectedKeys.length == 0)()}
                        onConfirm={() => {
                            props.deleteFiles(selectedKeys);
                            setSelectedKeys([]);
                        }}>Delete
                    </DangerousConfirmButton>
                </Col>
                <Col span={6}>
                    <div>
                        <Progress
                            className="filesystem-size"
                            percent={usedPercent}
                            status={usedPercent < 90 ? "normal" : "exception"}
                            size="small"
                            format={(percent) =>
                                <>
                                    {percent}% used
                                    <Popover placement="bottomRight" content={content}>
                                        <FontAwesomeIcon icon={light("info-circle")} style={{ fontSize: "15px", marginLeft: "10px" }} />
                                    </Popover>
                                </>}
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{ height: "70vh" }}>
                <Col span={6}>
                    {
                        treeData.length !== 0
                            ? <DirectoryTree
                                className="filesystem-tree"
                                showIcon
                                selectable
                                multiple
                                // draggable
                                blockNode
                                treeData={treeData}
                                titleRender={(node) => <>{node.title}<span className="filesystem-item-space">{humanFileSize(props.fileSizes[node.key as number] || -1)}</span></>}
                                onSelect={(keys, info) => { setSelectedKeys(keys) }} />
                            : <div className="filesystem-tree-empty">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Filesystem empty" />
                            </div>
                    }
                </Col>
                <Col span={18} style={{alignItems: "center", justifyItems: "center", display: "grid"}}>
                    <Empty style={{color: "rgba(255, 255, 255, 0.3)"}} image={<FontAwesomeIcon icon={solid("rectangle-terminal")} style={{color: "rgba(255, 255, 255, 0.1)"}} />} description="Coming up: file editor" />
                </Col>
            </Row>
            <Modal
                title={uploadModalType === "upload-and-unpack" ? "Upload and extract files" : "Upload files"}
                closable={!isUploading}
                open={uploadModalType !== undefined}
                onOk={() => setUploadModalType(undefined)}
                onCancel={() => setUploadModalType(undefined)}
                footer={[
                    <Button type="primary" loading={isUploading} onClick={() => setUploadModalType(undefined)}>{ isUploading ? <>Uploading...</> : <>Close</> }</Button>
                ]}>
                    <AutoComplete
                        style={{ width: "100%", marginBottom: "20px" }}
                        placeholder="Destination directory"
                        onChange={(item) => setUploadPath(item)}
                        filterOption={(input, option) => (option?.label?.toString() ?? '').startsWith(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label?.toString() ?? '').localeCompare((optionB?.label?.toString() ?? ''))
                        }
                        options={dirs} />
                    <Dragger {...uploadProps} action={`/api/v1/${uploadModalType === "upload-and-unpack" ? "uploadAndDecompressFiles" : "uploadFiles"}?uploadPath=${encodeURI(uploadPath)}`} disabled={!uploadPath}>
                        <p className="ant-upload-drag-icon">
                            <FontAwesomeIcon icon={light("upload")} style={{ color: "#177ddc", fontSize: "40px"}} />
                        </p>
                        <p className="ant-upload-text">{!uploadPath ? "Set destination path first" : `Click or drag ${uploadModalType === "upload-and-unpack" ? "an archive" : "any"} file to this area to upload`}</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload
                        </p>
                    </Dragger>
            </Modal>
        </GutteredPage>
    );
}

function UploadedFile(props: UploadedFileProps) {
    const [startTime, setStartTime] = React.useState<Date>(new Date());

    React.useEffect(() => {
        setStartTime(new Date())
    }, []);

    var seconds = ((new Date()).getTime() - startTime.getTime()) / 1000;
    var fileSize = props.file.size || 0;
    var filePercent = (props.file.percent || 0) / 100;
    var currentBytes = fileSize * filePercent;
    var uploadSpeed = props.file.status === "uploading" ? Math.round(currentBytes / seconds) : 0;

    var status: "active" | "success" | "exception";
    switch (props.file.status) {
        case "done":
            status = "success";
            break;
        case "error":
            status = "exception";
            break;
        default:
            status = "active";
            break;
    }

    const humanFileSize = (size: number) => {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        var size = Number((size / Math.pow(1024, i)).toFixed(2));
        if (Number.isNaN(size)) {
            return "Unknown";
        }
        return size * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    return (
    <Card style={{ marginTop: "20px" }}>
        <Row style={{ margin: "0" }} align="middle" gutter={16}>
            <Col span={3}>
                <FontAwesomeIcon icon={solid("file-lines")} style={{ fontSize: "25px"}} />
            </Col>
            <Col span={18}>
                <h3 style={{ margin: "0" }}>{props.file.name}</h3>
                <Progress percent={Math.round(props.file.percent || 0)} status={status} size="small"/>
                <span>{humanFileSize(currentBytes)} / {humanFileSize(fileSize)}</span>
                <span style={{ float: "right", marginRight: "32px" }}>{humanFileSize(uploadSpeed)}/s</span>
            </Col>
            <Col span={3}>
                <Button type="dashed" icon={<FontAwesomeIcon icon={solid("xmark")} style={{ fontSize: "auto", color: "#666" }} />} onClick={props.remove} />
            </Col>
        </Row>
    </Card>);
}

interface UploadedFileProps {
    file: UploadFile<any>;
    remove: () => void;
}


export default connect<FilesPageStateProps, FilesPageDispatchProps, FilesPageOwnProps, IApplicationState>(
    (state: IApplicationState, ownProps: any) => selectFilesPage(state, ownProps.tabId),
    dispatch => bindActionCreators({
        fetchFiles: fetchFilesThunk,
        deleteFiles: deleteFilesThunk,
        openEditFileTab: openEditFileTabAction,
        fetchZippedFileOrDir: fetchZippedFileOrDirThunk,
    }, dispatch)
)(FilesPage)
