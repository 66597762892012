// Init
export const loadInitDataActionName = "root/initData";
export const setLicenseAgreementHandledActionName = "root/setLicenseAgreementHandled"

// Core tabs actions
export const openTabActionName = "pages/openTab";
export const closeTabActionName = "pages/closeTab";
export const changePageParamsActionName = "pages/changePageParams";
export const selectTabActionName = "pages/selectTab";
export const reorderTabsActionName = "pages/reorderTabs";
export const pageFinishedLoadingActionName = "pages/pageFinishedLoading";

// Projects
export const loadProjectsActionName = "project/loadProjects";
export const loadProjectActionName = "project/loadProject";
export const projectUpdateInProgressActionName = "project/projectUpdateInProgress";
export const projectUpdateFinishedActionName = "project/projectUpdateFinished";
export const projectDeleteInProgressActionName = "project/projectDeleteInProgress";
export const projectDeleteFinishedActionName = "project/projectDeleteFinished";
export const updateProjectPageDataActionName = "page/project/updateProjectPageData";
export const updateProjectTabNameActionName = "page/project/updateProjectTabName";

// Workflows
// export const loadWorkflowsActionName = "workflow/loadWorkflows";
// export const loadWorkflowActionName = "workflow/loadWorkflow";
// export const loadFlowGraphActionName = "workflow/loadFlowGraph";
// export const workflowUpdateInProgressActionName = "workflow/workflowUpdateInProgress";
// export const workflowUpdateFinishedActionName = "workflow/workflowUpdateFinished";
// export const workflowDeleteInProgressActionName = "workflow/workflowDeleteInProgress";
// export const workflowDeleteFinishedActionName = "workflow/workflowDeleteFinished";
// export const updateWorkflowPageDataActionName = "page/workflow/updateWorkflowPageData";
// export const updateWorkflowTabNameActionName = "page/workflow/updateWorkflowTabName";

// Scans
export const loadScansActionName = "scan/loadScans";
export const loadScanActionName = "scan/loadScan";
export const loadScanSchedulesActionName = "scan/loadScanSchedulesActionName";
export const updateScanPageDataActionName = "page/scan/updateScanPageData";
export const scanDeleteInProgressActionName = "scan/scanDeleteInProgress";
export const scanDeleteFinishedActionName = "scan/scanDeleteFinished";
export const removeScanFromListActionName = "scan/removeScanFromList";
export const refreshScanWithNodeActionName = "scan/refreshScanWithNode";
export const refreshScanWithNodeExitActionName = "scan/refreshScanWithNodeExit";
export const scanScheduleDeleteInProgressActionName = "scan/scanScheduleDeleteInProgress";
export const removeScanScheduleFromListActionName = "scan/removeScanScheduleFromList";

// Settings
export const loadSettingsActionName = "settings/loadSettings";
export const saveSettingsActionName = "settings/saveSettings";

// Programmable nodes
export const loadProgrammableNodesActionName = "programmableNodes/load";
export const setProgrammableNodeDeleteInProgressActionName = "programmableNodes/deleteInProgress";
export const deleteProgrammableNodeActionName = "programmableNodes/deleteProgrammableNode";
export const addProgrammableNodeActionName = "programmableNodes/addProgrammableNode";
export const updateProgrammableNodeActionName = "programmableNodes/updateProgrammableNode";

// Node Blueprints
export const fetchNodeBlueprintsInProgressActionName = "nodeBlueprints/fetchNodeBlueprintsInProgress";
export const fetchNodeBlueprintsFinishedActionName = "nodeBlueprints/fetchNodeBlueprintsFinished";

// Flow Graph
// export const addNodeToGraphActionName = "page/flowGraph/addNodeToGraph";
// export const removeElementsActionName = "page/flowGraph/removeElements";
// // export const moveNodesActionName = "page/flowGraph/moveNodes";
// // export const edgeUpdateActionName = "page/flowGraph/edgeUpdate";
// // export const edgeConnectActionName = "page/flowGraph/edgeConnect";
// export const onNodesChangeActionName = "page/flowGraph/onNodesChange";
// export const onEdgesChangeActionName = "page/flowGraph/onEdgesChange";
// export const onConnectActionName = "page/flowGraph/onConnect";
// export const applyPropChangesToNodeActionName = "page/flowgraph/applyPropChangesToNode";
// export const flowGraphUpdateInProgressActionName = "flowGraph/flowGraphUpdateInProgress";
// export const flowGraphUpdateFinishedActionName = "flowGraph/flowGraphUpdateFinished";
// export const beginConnectionValidationActionName = "flowgraph/beginConnectionValidation";
// export const endConnectionValidationActionName = "flowgraph/endConnectionValidation";
// export const selectNodeSettingsActionName = "page/flowgraph/selectNodeSettings";

// Tags
export const loadDataTagsActionName = "tags/loadDataTags";
export const pushNewTagActionName = "tags/pushNewTag";

// Auth
export const signInActionName = "auth/signIn";
export const signOutActionName = "auth/signOut";
export const updateAuthDataActionName = "auth/updateAuthData";
export const updateClaimsActionName = "auth/updateClaims";
export const updateFeaturesActionName = "auth/updateFeatures"
export const updateNotificationsActionName = "auth/updateNotifications"

// Admin
export const loadNotificationsActionName = "admin/loadNotifications";
export const loadUsersAndGroupsActionName = "page/admin/loadUsersAndGroups";
export const createNotificationActionName = "page/admin/createNotification";
export const editNotificationActionName = "page/admin/editNotification";
export const updateNotificationInListActionName = "admin/updateNotificationInList";
export const removeNotificationFromListActionName = "admin/removeNotificationFromList";
export const updateGroupsForUserActionName = "page/admin/updateGroupsForUser";

export const markNotificationSeenActionName = "user/markNotificationSeen";
export const changeNotificationDisplayActionName = "user/changeNotificationDisplay";

// Files
export const loadFilesActionName = "page/files/loadFiles";
export const loadFileActionName = "page/file/loadFileData";
