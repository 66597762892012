import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppLayout from './components/AppLayout';
import LoadingPage from './pages/auth/LoadingPage';
import LoginUnknownErrorPage from './pages/auth/LoginUnknownErrorPage';
import SignInPage from './pages/auth/SignInPage';
import { LoginScreenType } from './core/enums';
import { refreshTokenThunk } from './store/actions/authActions';
import { selectAuthData } from './store/selectors';

import './styles/App.css'
import { AppDispatchProps, AppProps, AppStateProps, IApplicationState } from './types';
import ForceResetPasswordPage from './pages/auth/ForceResetPasswordPage';
import NoAccessErrorPage from './pages/auth/NoAccessErrorPage';
import SignUpPage from './pages/auth/SignUpPage';

function App(props: AppProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const marketplaceToken = urlParams.get('x-amzn-marketplace-token');
    const isFreeTrial = urlParams.get('x-amzn-marketplace-offer-type') === "free-trial";
    const page = urlParams.get('page');

    switch (page) {
        case "signup": return <SignUpPage marketplaceToken={marketplaceToken} isFreeTrial={isFreeTrial} />;
        case "noaccess": return <NoAccessErrorPage />;
    }

    switch (props.loginScreen) {
        case LoginScreenType.ValidatingLogin: return <LoadingPage />;
        case LoginScreenType.PerformLogin: return <SignInPage />;
        case LoginScreenType.PerformSignUp: return <SignUpPage marketplaceToken={marketplaceToken} isFreeTrial={isFreeTrial} />;
        case LoginScreenType.ForceResetPassword: return <ForceResetPasswordPage />;
        case LoginScreenType.NoAccess: return <NoAccessErrorPage />;
        case LoginScreenType.LoggedIn: return <AppLayout />;
        default: return <LoginUnknownErrorPage />;
    }
}

export default connect<AppStateProps, AppDispatchProps, {}, IApplicationState>(
    (state) => selectAuthData(state),
    dispatch => bindActionCreators({
        refreshToken: refreshTokenThunk
    }, dispatch)
)(App)