import { Alert, Button, Col, Form, Input, Popover, Row, Select } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createProjectThunk, deleteProjectThunk, updateProjectThunk } from '../store/actions/projectActions';
import { updateProjectPageDataAction } from '../store/actions/tabActions';
import { selectProjectPage } from '../store/selectors';
import { IApplicationState, Popup, ProjectEditDispatchProps, ProjectEditOwnProps, ProjectEditProps, ProjectPageStateProps } from "../types";
import MarkdownFormatter from './ui/MarkdownFormatter';
import DangerousConfirmButton from './ui/DangerousConfirmButton';
import { SeedResourceDescriptions, DataType } from '../core/enums';
import { RuleObject } from 'antd/lib/form';
import { SeedResourceValidations } from '../core/validations';
import { IconButton } from './ui/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ScheduleWorkflowModal from './ScheduleWorkflowModal';
import RunWorkflowModal from './RunWorkflowModal';
import { pushNewTagAction } from '../store/actions/tagActions';

const { Option } = Select;

const examples = new Map<DataType, string[]>([
    [DataType.ASN, [ '15169', '32934', '13335' ]],
    [DataType.CIDRv4, [ '192.0.2.0/24',  '10.0.0.0/24' ]],
    [DataType.CIDRv6, [ '2002::1234:abcd:ffff:c0a8:101/64', '2000::/4' ]],
    [DataType.IPv4, [ '192.0.2.19', '10.0.0.1', '129.168.0.1' ]],
    [DataType.IPv4WithPort, [ '192.0.2.19:80', '129.168.0.1:22' ]],
    [DataType.IPv6, [ '2001:db8:3333:4444:5555:6666:7777:8888', '::1234:5678', '::' ]],
    [DataType.IPv6WithPort, [ '2001:db8:3333:4444:5555:6666:7777:8888p80' ]],
    [DataType.Domain, [ 'example.com', 'sub.example.com' ]],
    [DataType.Url, [ 'https://www.example.com', 'https://www.example.com/test', 'https://sub.example.com?attribute=81' ]],
]);

function ProjectEdit(props: ProjectEditProps) {
    const [preview, setPreview] = React.useState(false);
    const [form] = Form.useForm();
    const deleteProjectWrapped = (projectId: string) => () => props.deleteProject(projectId);
    const seedResourceTypes = [...(SeedResourceDescriptions.keys() as unknown as Array<DataType>)];

    form.setFieldsValue(props.project);

    const printHelp = () => {
        const content = <div style={{maxHeight: "300px", overflow: "scroll"}}>
            { seedResourceTypes.map((sr: DataType) =>
                <>
                    <h3>{SeedResourceDescriptions.get(sr)}</h3>
                    <ul>
                        {
                            (examples.get(sr) || []).map((i: string) => <li>{i}</li>)
                        }
                    </ul>
                </>
            )}
        </div>;

        return <Popover content={content} title="Examples" zIndex={10000}>
            <FontAwesomeIcon className="icon" icon={regular("circle-question")} />
        </Popover>
    }

    // TODO: Extract this to core
    const validateSeedResource = (getFieldValue: any, index: DataType): RuleObject => {
        const resourceType = getFieldValue("seedResources")[index].resourceType as DataType;
        const rule = SeedResourceValidations.get(resourceType) || { };
        return rule;
    }
    const scheduleWorkflowPopupRef = React.createRef();
    const openScheduleWorkflowPopupModal = () => {
        if (scheduleWorkflowPopupRef.current) {
            (scheduleWorkflowPopupRef.current as Popup).show();
        }
    };
    const runWorkflowPopupRef = React.createRef();
    const openRunWorkflowPopupModal = () => {
        if (runWorkflowPopupRef.current) {
            (runWorkflowPopupRef.current as Popup).show();
        }
    };

    if (!props.project) {
        return (<Row><Col span={24}>{/* TODO: Add skeleton loading - the if statement doesn't work any more as Project is initialized in reducer */}</Col></Row>);
    } else {
        return (
            <Row>
                <Col span={24}>
                    <Form
                        autoComplete="off"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                        form={form}
                        initialValues={props.project}
                        onValuesChange={(changedValues, allValues) => props.updateProjectPageData(props.tabId, allValues)}
                        onFinish={(values: any) => {
                            props.project && props.project.isCreateMode
                                ? props.createProject({...props.project, ...values}, props.tabId)
                                : props.updateProject({...props.project, ...values}, props.tabId)}
                            }>
                        <Form.Item label="Project Name" name="name" rules={[{ required: true, message: 'Project Name required.' }]}>
                            <Input placeholder="Example project" disabled={props.disabled} showCount maxLength={200} />
                        </Form.Item>
                        <Form.Item label="Project Description" name="description">
                            {
                                preview
                                    ? <MarkdownFormatter>{form.getFieldValue("description")}</MarkdownFormatter>
                                    : <Input.TextArea className="markdown-editor" placeholder="Here comes project description in markdown" disabled={props.disabled} showCount maxLength={10000} />
                            }
                        </Form.Item>
                        <Button type="text" onClick={() => setPreview(!preview)} disabled={props.disabled} style={{ float: "right", marginBottom: "10px" }}>
                            {preview ? "Edit" : "Preview"}
                        </Button>
                        <div style={{ clear: "both" }}></div>
                        <label title="Seed Resources"><>{printHelp()}</> Seed resources</label>
                        <div className="form-item-list">
                            <Form.List name="seedResources">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row key={`space-${key}`} style={{ display: 'flex', marginBottom: 8 }} gutter={16}>
                                            <Col span={18}>
                                                <Row>
                                                    {/* TODO: Create different seed resources editors - add file picker */}
                                                    <Col span={8} style={{ paddingRight: "10px"}}>
                                                        <Form.Item
                                                            {...restField}
                                                            wrapperCol={{ span: 24 }}
                                                            name={[name, 'resourceType']}
                                                            rules={[{ required: true, message: 'Missing seed resource type' }]}>
                                                            <Select
                                                                key={`seed-resource-type-${key}`}
                                                                disabled={props.disabled} placeholder="Seed Resource type"
                                                                onChange={() => form.validateFields([["seedResources", name, "value"]]).catch(() => { })}>
                                                                {seedResourceTypes.map(srt => (
                                                                    <Select.Option value={srt}>{SeedResourceDescriptions.get(srt)}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            {...restField}
                                                            wrapperCol={{ span: 24 }}
                                                            name={[name, 'value']}
                                                            rules={[
                                                                { required: true, message: 'Missing seed resource value' },
                                                                ({ getFieldValue }) => validateSeedResource(getFieldValue, name)
                                                            ]}>
                                                            <Input key={`seed-resource-value-${key}`} disabled={props.disabled} placeholder={(() => "Seed Resource")()} showCount maxLength={200} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            wrapperCol={{ span: 24 }}
                                                            name={[name, 'tags']}>
                                                            <Select
                                                                mode="tags"
                                                                disabled={props.disabled}
                                                                allowClear
                                                                placeholder="Tags"
                                                                maxTagTextLength={200}
                                                                maxTagCount={1000}
                                                                onSelect={(t: any) => props.pushNewTag(t)}>
                                                                    {props.allDataTags.map(t => <Option value={t}>{t}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={1} style={{lineHeight: "32px", paddingLeft: "0"}}>
                                                <IconButton icon={solid("circle-minus")} onClick={() => remove(name)} disabled={props.disabled} />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            disabled={props.disabled}
                                            onClick={() => add({ resourceType: null, value: null })}
                                            block
                                            icon={<FontAwesomeIcon icon={solid("circle-plus")} />}>
                                            Add seed resource
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                            </Form.List>
                        </div>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={props.isSaveInProgress}
                                disabled={props.disabled}>
                                    {props.project.isCreateMode ? "Create project" : "Save project"}
                            </Button>
                            {
                                props.project.isEditMode
                                    ?
                                        (<>
                                            <DangerousConfirmButton
                                                popupText={<>Delete project?</>}
                                                icon={solid("trash-can")}
                                                isInProgress={props.isDeleteInProgress}
                                                disabled={props.disabled}
                                                onConfirm={deleteProjectWrapped(props.projectId)}>
                                                    Delete
                                            </DangerousConfirmButton>
                                            <Button
                                                type="text"
                                                disabled={props.disabled}
                                                icon={<FontAwesomeIcon icon={solid("calendar-days")} />}
                                                onClick={openScheduleWorkflowPopupModal}>Schedule</Button>
                                            <Button
                                                type="text"
                                                disabled={props.disabled}
                                                icon={<FontAwesomeIcon icon={solid("play")} />}
                                                onClick={openRunWorkflowPopupModal}>Run scan</Button>
                                        </>)
                                    : <></>
                            }
                        </Form.Item>
                        <ScheduleWorkflowModal ref={scheduleWorkflowPopupRef} projectId={props.projectId} />
                        <RunWorkflowModal ref={runWorkflowPopupRef} projectId={props.projectId} />
                    </Form>
                </Col>
            </Row>
        );
    }
}

// TODO: We should check if the project has changed before comitting the save
export default connect<ProjectPageStateProps, ProjectEditDispatchProps, ProjectEditOwnProps, IApplicationState>(
    (state, ownProps) => selectProjectPage(state, ownProps.tabId, false),
    dispatch => bindActionCreators({
        updateProject: updateProjectThunk,
        createProject: createProjectThunk,
        deleteProject: deleteProjectThunk,
        pushNewTag: pushNewTagAction,
        updateProjectPageData: updateProjectPageDataAction
    }, dispatch)
)(ProjectEdit)