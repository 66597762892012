import { IPage, SettingsPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function settingsPageInitializer(payload: IPage): SettingsPageState {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("gears"),
        tabText: "Settings",
        params: [],
    };
}