import { createAction } from "@reduxjs/toolkit";
import { communication } from "../../core/communication";
import { PageType } from "../../core/enums";
import { IApplicationState } from "../../types";
import * as Names from '../actionNames';
import { openTabAction, pageFinishedLoadingAction } from "./tabActions";

export const loadScansAction = createAction(Names.loadScansActionName, (data: any) => ({ payload: { response: data }}));
export const loadScanAction = createAction(Names.loadScanActionName, (data: any) => ({ payload: { response: data }}));
export const loadScanSchedulesAction = createAction(Names.loadScanSchedulesActionName, (data: any) => ({ payload: { response: data }}));
export const updateScanPageDataAction = createAction(Names.updateScanPageDataActionName, (tabId: number, scan: any) => ({ payload: { tabId: tabId, scan: scan }}));
export const scanDeleteInProgressAction = createAction(Names.scanDeleteInProgressActionName, (scanId: string) => ({ payload: { scanId: scanId }}));
export const scanDeleteFinishedAction = createAction(Names.scanDeleteFinishedActionName, (scanId: string) => ({ payload: { scanId: scanId }}));
export const removeScanFromListAction = createAction(Names.removeScanFromListActionName, (scanId: string) => ({ payload: { scanId: scanId }}));
export const refreshScanWithNodeAction = createAction(Names.refreshScanWithNodeActionName, (scanId: string, scanNodeId: any, data: any) => ({ payload: { scanId: scanId, scanNodeId: scanNodeId, response: data }}));
export const refreshScanWithNodeExitAction = createAction(Names.refreshScanWithNodeExitActionName, (pageIdx: number, scanId: string, scanNodeId: string, exitTypeId: string, data: any) => ({ payload: { pageIdx: pageIdx, scanId: scanId, scanNodeId: scanNodeId, exitTypeId: exitTypeId, data: data }}));
export const scanScheduleDeleteInProgressAction = createAction(Names.scanScheduleDeleteInProgressActionName, (scanScheduleId: string) => ({ payload: { scanScheduleId: scanScheduleId }}));
export const removeScanScheduleFromListAction = createAction(Names.removeScanScheduleFromListActionName, (scanScheduleId: string) => ({ payload: { scanScheduleId: scanScheduleId }}));
// Complex Actions

export function openViewScansTabAction() {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.Scans));
        dispatch(updateScansThunk());
    }
}

export function openViewScanSchedulesTabAction() {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.ScanSchedules));
        dispatch(fetchScanSchedulesThunk());
    }
}

export function openViewScanTabAction(scanId: string) {
    return async (dispatch: any) => {
        dispatch(openTabAction(PageType.Scan));
        dispatch(updateScanThunk(scanId));
    }
}

// Thunks

export function fetchScansThunk(tabId?: number) {
    return async (dispatch: any, store: () => IApplicationState) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        const response = await communication.get("/api/v1/scans");
        dispatch(loadScansAction(response));
        dispatch(pageFinishedLoadingAction(actualTabId));
    }
}

export function updateScansThunk(tabId?: number) {
    return async (dispatch: any, store: () => IApplicationState) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        await dispatch(fetchScansThunk());
        dispatch(pageFinishedLoadingAction(actualTabId));
    }
}

export function fetchScanThunk(scanId: string, clearNewFlag: boolean = true, tabId?: number) {
    return async (dispatch: any, store: () => IApplicationState) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        const response = await communication.get(`/api/v1/scan?scanId=${scanId}&clearNewFlag=${clearNewFlag}`);
        dispatch(updateScanPageDataAction(actualTabId, response.result));
        dispatch(loadScanAction(response));
        if (tabId != -1) {
            dispatch(pageFinishedLoadingAction(actualTabId));
        }
    }
}

export function fetchScanSchedulesThunk(tabId?: number) {
    return async (dispatch: any, store: () => IApplicationState) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        const response = await communication.get("/api/v1/scanSchedules");
        dispatch(loadScanSchedulesAction(response));
    }
}

export function refreshScanNodeThunk(scanId: string, scanNodeId: string) {
    return async (dispatch: any, store: () => IApplicationState) => {
        // TODO: Here before loading we could reset scanNodeResults of the scan node to null to trigger loading when
        // the status is still one of in progress statuses
        const response = await communication.get(`/api/v1/scanNode?scanId=${scanId}&scanNodeId=${scanNodeId}`);
        dispatch(refreshScanWithNodeAction(scanId, scanNodeId, response.result));
    }
}

export function refreshScanNodeResultThunk(pageIdx: number, scanId: string, scanNodeId: string, exitTypeId: string) {
    return async (dispatch: any) => {
        const response = await communication.get(`/api/v1/scanNodeResults?pageIdx=${pageIdx}&scanId=${scanId}&scanNodeId=${scanNodeId}&exitTypeId=${exitTypeId}`);
        dispatch(refreshScanWithNodeExitAction(pageIdx, scanId, scanNodeId, exitTypeId, response.result));
    }
}

// updateScanThunk is used when the scan details are viewed. That's why the clearNewFlag is always true
// in this case - "is new?" flag needs to be cleared to false when viewed for the first time
export function updateScanThunk(scanId: string, tabId?: number) {
    return async (dispatch: any, store: () => IApplicationState) => {
        const actualTabId = tabId ?? store().pages.latestOpenedTabId;
        await dispatch(fetchScanThunk(scanId, true, tabId));
        dispatch(pageFinishedLoadingAction(actualTabId));
    }
}

export function fetchScanJsonFileThunk(scanId: string) {
    return async () => {
        await communication.getFile(`/api/v1/downloadRawScan?scanId=${scanId}`, `scan-${scanId}.json`);
    }
}

export function deleteScanThunk(scanId: string) {
    return async (dispatch: any, store: () => IApplicationState) => {
        dispatch(scanDeleteInProgressAction(scanId)); // TODO: Not currently handled
        const response = await communication.post(`/api/v1/scan/${scanId}/delete`);
        if (response.isSuccessful) {
            dispatch(removeScanFromListAction(scanId));
        }
        dispatch(scanDeleteFinishedAction(scanId)); // TODO: Not currently handled
    }
}

export function deleteScanScheduleThunk(scanScheduleId: string) {
    return async (dispatch: any, store: () => IApplicationState) => {
        dispatch(scanScheduleDeleteInProgressAction(scanScheduleId));
        const response = await communication.post(`/api/v1/scanSchedule/${scanScheduleId}/delete`);
        if (response.isSuccessful) {
            dispatch(removeScanScheduleFromListAction(scanScheduleId));
        }
    }
}