import { NEW_GUID } from "../../../constants";
import { ProjectPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function projectPageInitializer(payload: any): ProjectPageState {
    const isEditMode = (payload.data && payload.data.isEditMode) || false;
    const isCreateMode = (payload.data && payload.data.isCreateMode) || false;
    const projectId = (payload.data && payload.data.projectId) || NEW_GUID;

    let tabText = "Project";
    if (isEditMode) tabText = "Edit Project";
    if (isCreateMode) tabText = "Create Project";

    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("globe"),
        tabText: tabText,
        projectId: projectId,
        isProjectAlreadyDeleted: false,
        isDeleteInProgress: false,
        isSaveInProgress: false,
        params: [],
        project: {
            projectId: NEW_GUID,
            version: 1,
            dateTimeCreated: new Date(),
            dateTimeUpdated: new Date(),
            name: "",
            description: "",
            seedResources: [],
            seedResourcesCount: undefined,
            isCreateMode: isCreateMode,
            isEditMode: isEditMode,
            isSaveInProgress: false,
            isDeleteInProgress: false
        }
    };
}