import { IPage, ProjectsPageState } from "../../../types";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function projectsPageInitializer(payload: IPage): ProjectsPageState {
    return {
        tabId: payload.tabId,
        pageType: payload.pageType,
        tabIcon: solid("globe"),
        tabText: "Projects",
        projects: [],
        params: [],
    };
}