import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Avatar, Badge, Menu, Dropdown, Button, Empty, Modal, Col, Row, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { openTabAction } from '../store/actions/tabActions';
import { openViewProjectsTabAction } from '../store/actions/projectActions';
import { NotificationAlertType } from '../core/enums';
import { AppHeaderDispatchProps, AppHeaderProps, AppHeaderStateProps, IApplicationState, IFeature } from '../types';
import { openViewScanSchedulesTabAction, openViewScansTabAction } from '../store/actions/scanActions';
import { changeNotificationDisplayAction, logoutThunk, markNotificationSeenThunk } from '../store/actions/authActions';
import { selectAppHeaderData } from '../store/selectors';
import { openNotificationsTabAction } from '../store/actions/adminActions';
import { any } from '../core/reduxHelpers';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { extractTitle } from '../core/markdownHelpers';
import MarkdownFormatter from './ui/MarkdownFormatter';
import { AccessType, PermissionType } from '../core/permissionTypes';
import { openViewFilesTabAction } from '../store/actions/filesActions';
import { openViewProgrammableNodesTabAction } from '../store/actions/programmableNodesActions';
import { AppDispatch } from '../store/configureAppStore';
import { openWorkflowsTab } from '../store/slices/workflowsSlice';
import { openViewOrganizationSettingsTab } from '../store/slices/settingsSlice';

const { Header } = Layout;
const { Text } = Typography;

declare const SERVER_ENV: any;

function AppHeader(props: AppHeaderProps) {
    var idx = 1;
    const dispatch = useDispatch<AppDispatch>();
    const [closingBanner, setClosingBanner] = React.useState(false);

    const menuItems = <>
        <Menu.Item key={idx++} onClick={() => props.openViewProjectsTab()}
            hidden={!isAuthorized(props.features, PermissionType.UserFeature_Projects)}>
            <FontAwesomeIcon icon={solid("globe")} style={{fontSize: "16px"}}/> Projects
        </Menu.Item>
        <Menu.Item key={idx++} onClick={() => openWorkflowsTab(dispatch)}
            hidden={!isAuthorized(props.features, PermissionType.UserFeature_Workflows)}>
            <FontAwesomeIcon icon={solid("diagram-project")} style={{fontSize: "16px"}}/> Workflows
        </Menu.Item>
        <Menu.SubMenu key={idx++}
            title={<><FontAwesomeIcon icon={solid("satellite-dish")} style={{fontSize: "16px"}}/> Scans</>}
            style={hideUnauthorized(props.features, PermissionType.UserFeature_Scans)}>
            <Menu.Item key={idx++} onClick={() => props.openViewScansTab()}
                hidden={!isAuthorized(props.features, PermissionType.UserFeature_Scans)}>
                <FontAwesomeIcon icon={solid("satellite-dish")} style={{fontSize: "16px"}}/> Scan Results
            </Menu.Item>
            <Menu.Item key={idx++} onClick={() => props.openViewScanSchedulesTab()}
                hidden={!isAuthorized(props.features, PermissionType.UserFeature_Scans)}>{/* TODO: Turn this permission into scheduled scans */}
                <FontAwesomeIcon icon={solid("calendar-days")} style={{fontSize: "16px"}}/> Scheduled Scans
            </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key={idx++} onClick={() => props.openViewProgrammableNodesTab()}
            hidden={!isAuthorized(props.features, PermissionType.UserFeature_ProgrammableNodes)}>
            <FontAwesomeIcon icon={solid("code")} style={{fontSize: "16px"}}/> Programmable Nodes
        </Menu.Item>
        {/* <Menu.Item key={idx++} onClick={() => props.openTab(PageType.ReportTemplates)}>
            <FontAwesomeIcon icon={solid("file-invoice")} style={{fontSize: "16px"}}/> Report Templates
        </Menu.Item> */}
        <Menu.Item key={idx++} onClick={() => props.openViewFilesTab()}
            hidden={!isAuthorized(props.features, PermissionType.UserFeature_Files)}>
            <FontAwesomeIcon icon={solid("folder-tree")} style={{fontSize: "16px"}}/> Filesystem
        </Menu.Item>
        <Menu.Divider style={hideUnauthorized(props.features,
            PermissionType.AdminFeature_Notifications, PermissionType.UserFeature_Settings)} />
        <Menu.Item key={idx++} onClick={() => dispatch(openViewOrganizationSettingsTab(null))}
            hidden={!isAuthorized(props.features, PermissionType.UserFeature_Settings)}>
            <FontAwesomeIcon icon={solid("cogs")} style={{fontSize: "16px"}}/> Settings
        </Menu.Item>
        <Menu.Divider style={hideUnauthorized(props.features, PermissionType.AdminFeature_Notifications)} />
        <Menu.SubMenu key={idx++}
            title={<><FontAwesomeIcon icon={solid("user-shield")} style={{fontSize: "16px"}}/> Admin</>}
            style={hideUnauthorized(props.features, PermissionType.AdminFeature_Notifications)}>
            <Menu.Item key={idx++} onClick={() => props.openNotificationsTab()}
                hidden={!isAuthorized(props.features, PermissionType.AdminFeature_Notifications)}>
                <FontAwesomeIcon icon={solid("bell-on")} style={{fontSize: "16px"}}/> Notifications
            </Menu.Item>
        </Menu.SubMenu>
        <Menu.Divider />
        <Menu.SubMenu key={idx++} title={<><FontAwesomeIcon icon={solid("circle-info")} style={{fontSize: "16px"}}/> Information</>}>
            <Menu.Item key={idx++} onClick={() => { window.open("https://docs.scournomad.com/" ,"_blank") }}>
                <FontAwesomeIcon icon={solid("life-ring")} style={{fontSize: "16px"}}/> Help<FontAwesomeIcon icon={solid("up-right-from-square")} style={{fontSize: "10px", float: "right", marginTop: "5px"}}/>
            </Menu.Item>
            <Menu.Item key={idx++} onClick={() => { window.open("https://discord.gg/rMfvfk5jAY" ,"_blank") }}>
                <FontAwesomeIcon icon={solid("messages-question")} style={{fontSize: "16px"}}/> Discord community<FontAwesomeIcon icon={solid("up-right-from-square")} style={{fontSize: "10px", float: "right", marginTop: "5px"}}/>
            </Menu.Item>
        </Menu.SubMenu>
    </>;
    // TODO: Temp solution as it is ugly but works
    const allMenuItemsHidden = menuItems.props.children.every((c: any) => c.props.hidden != false);
    const menu = (
        <Menu id='main-dropdown-menu' className='main-menu'>
            {
                props.hasUnconfirmedLicenses || allMenuItemsHidden
                ? <><Empty
                    description={<>No access</>}
                    image={<FontAwesomeIcon icon={solid("ban")} style={{ height: "40px", color: "rgba(255, 255, 255, 0.3)"}} />}
                    style={{ width: "14vw", margin: "40px 8px", color: "rgba(255, 255, 255, 0.3)" }}/></>
                : menuItems
            }
        </Menu>
    );
    const notificationsMenu = (
        <Menu
            id='notifications-dropdown-menu'
            className='main-menu'>
            {
                (!props.notifications || props.notifications.length === 0)
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "40px 70px" }} />
                : props.notifications.map((n, idx) =>
                    <Menu.Item key={idx} onClick={() => { props.changeNotificationDisplay(n.id ?? "", true) /* props.markNotificationSeen(n.id || ""); */ }}>
                        {
                            n.isSeen
                                ? <span style={{color: "#999", whiteSpace: "nowrap"}}><FontAwesomeIcon icon={["fas", n.iconName as IconName]} style={{ marginLeft: "11px" }} /> <Text ellipsis={true}>{extractTitle(n.markdownText)}</Text></span>
                                : <span style={{color: "white", whiteSpace: "nowrap"}}><Badge dot color="blue" offset={[-5, 3]} style={{ marginLeft: "5px" }}></Badge><FontAwesomeIcon icon={["fas", n.iconName as IconName]} /> <Text ellipsis={true}>{extractTitle(n.markdownText)}</Text></span>
                        }
                    </Menu.Item>)
            }
        </Menu>
    );
    const closeNotificationModal = (notificationId: string) => {
        props.changeNotificationDisplay(notificationId, false);
        props.markNotificationSeen(notificationId);
    }

    return (
        <>
            <Header id="app-header">
                <div id="app-header-details">
                    <h1>
                        <Dropdown overlay={menu} trigger={['hover']} placement="bottomRight">
                            <div id="hamburger" className="header-button" style={{float: "right"}}>
                                <FontAwesomeIcon icon={solid("ellipsis-stroke-vertical")} />
                            </div>
                        </Dropdown>
                        <img src="ScourNomadLogo.png" /> ScourNomad
                    </h1>
                </div>
                <div className="user-data" style={{float: 'right'}}>
                    <div className="version-data">
                        <h3>Version: {SERVER_ENV.SOLUTION_VERSION_TAG}</h3>
                        <h3>Build: {SERVER_ENV.SOLUTION_COMMIT_TAG}</h3>
                    </div>
                    {/* <Badge
                        count={10}
                        overflowCount={9}
                        offset={[-23, 0]}
                        size="small">
                        <MenuButton
                            icon={solid("envelope")}
                            size="20px"
                            style={{marginRight: '23px'}}/>
                    </Badge>*/}
                    <Dropdown overlay={notificationsMenu} trigger={['hover']} placement="bottomRight">
                        <div className="header-button">
                            <Badge
                                count={props.unreadNotificationCount}
                                overflowCount={9}
                                offset={[-20, 18]}
                                color="blue"
                                size="small">
                                <Button
                                    className='header-button'
                                    icon={<FontAwesomeIcon
                                        color={props.unreadNotificationCount > 0 ? "white" : "#999"}
                                        icon={props.unreadNotificationCount > 0 ? solid("bell-on") : solid("bell")} />} />
                            </Badge>
                        </div>
                    </Dropdown>
                    {/* <Switch
                        id='theme-switch'
                        checkedChildren={<FontAwesomeIcon icon={solid("sun")} />}
                        unCheckedChildren={<FontAwesomeIcon icon={solid("moon")} />}
                        onChange={onThemeChange} /> */}
                    <Avatar
                        className='main-avatar'
                        icon={<FontAwesomeIcon icon={regular("user")} />}
                        size='large' />
                    <div className='main-user'>
                        <span>{props.fullName}</span><br/>
                        <a href="#" onClick={() => props.logout()}>Sign out</a>
                    </div>
                    <Modal
                        open={!!props.displayNotification}
                        onOk={() => closeNotificationModal(props.displayNotification?.id ?? "")}
                        onCancel={() => closeNotificationModal(props.displayNotification?.id ?? "")}
                        title={null}
                        width="50%"
                        maskClosable={true}
                        okText="Close"
                        cancelButtonProps={{hidden: true}}>
                        <MarkdownFormatter>{props.displayNotification && props.displayNotification.markdownText}</MarkdownFormatter>
                    </Modal>
                </div>
            </Header>
            {
                props.bannerDisplayNotifications.map(n => {
                    var cls: string;
                    switch(n.alertType) {
                        case NotificationAlertType.Info: cls = "notification-banner-info"; break;
                        case NotificationAlertType.Warning: cls = "notification-banner-warning"; break;
                        case NotificationAlertType.Alert: cls = "notification-banner-alert"; break;
                    }
                    return (
                        <Header className={`notification-banner ${cls}`}>
                            <Row align="middle">
                                <Col offset={4} span={1}>
                                    <FontAwesomeIcon className="icon" icon={["fas", n.iconName as IconName]} />
                                </Col>
                                <Col span={14}>
                                    <MarkdownFormatter>{n.markdownText}</MarkdownFormatter>
                                </Col>
                                <Col span={1}>
                                    <Button
                                        className="closeButton"
                                        icon={<FontAwesomeIcon icon={solid("x")} />}
                                        loading={closingBanner}
                                        onClick={() => {
                                            setClosingBanner(true);
                                            closeNotificationModal(n.id || "");
                                        }}/>
                                </Col>
                            </Row>
                        </Header>);
                })
            }
        </>
    );
}

function onThemeChange(checked: boolean) {
    // if (checked) {
    //     (document.getElementById("theme-style") as HTMLLinkElement).href = "/theme-light.css";
    // } else {
    //     (document.getElementById("theme-style") as HTMLLinkElement).href = "/theme-dark.css";
    // }
}

function isAuthorized(features: IFeature[], ...requiredPermissions: PermissionType[]): boolean {
    if (!features || !requiredPermissions) {
        return false;
    } else {
        return any(requiredPermissions, rp => any(features, p => p.permissionType === rp && p.accessType === AccessType.Allowed));
    }
}

function hideUnauthorized(permissions: IFeature[], ...requiredPermissions: PermissionType[]): any {
    return !isAuthorized(permissions, ...requiredPermissions)
        ? { display: "none" }
        : { };
}

export default connect<AppHeaderStateProps, AppHeaderDispatchProps, {}, IApplicationState>(
    (state) => selectAppHeaderData(state),
    dispatch => bindActionCreators({
        openTab: openTabAction,
        openViewProjectsTab: openViewProjectsTabAction,
        openViewScansTab: openViewScansTabAction,
        openViewScanSchedulesTab: openViewScanSchedulesTabAction,
        openViewProgrammableNodesTab: openViewProgrammableNodesTabAction,
        openNotificationsTab: openNotificationsTabAction,
        openViewFilesTab: openViewFilesTabAction,
        logout: logoutThunk,
        markNotificationSeen: markNotificationSeenThunk,
        changeNotificationDisplay: changeNotificationDisplayAction,
    }, dispatch)
)(AppHeader)