import * as React from 'react';
import GutteredPage from '../components/pageInterfaces/GutteredPage';
import DangerousConfirmButton from '../components/ui/DangerousConfirmButton';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Table } from 'antd';
import { Workflow, WorkflowsPageProps } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppDispatch, RootState } from '../store/configureAppStore';
import { fetchWorkflows, selectWorkflows, openCreateNewWorkflowTab, deleteWorkflow, openViewWorkflowTab, openEditWorkflowTab } from '../store/slices/workflowsSlice';

const { Column } = Table;

export default function WorkflowsPage(props: WorkflowsPageProps) {

    const dispatch = useDispatch<AppDispatch>();
    const workflows = useSelector<RootState, Workflow[]>(state => selectWorkflows(state));

    React.useEffect(() => {
        dispatch(fetchWorkflows(null));
    }, []);

    return (
        <GutteredPage>
            <Row>
                <Col span={12}>
                    <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={solid("diagram-project")} />}
                        onClick={() => { openCreateNewWorkflowTab(dispatch) }}>
                            New Workflow
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={workflows}
                        rowKey="id"
                        pagination={{position: ["bottomLeft"]}}
                        scroll={{ x: true }}>
                        <Column title="Workflow name" dataIndex="name" render={(text: any, record: Workflow) => <Button type="link" onClick={() => openViewWorkflowTab(dispatch, record.workflowId)}>{text}</Button>} />
                        <Column title="Date and Time Created" width="200px" dataIndex="dateTimeCreated" render={(text: any, record: Workflow) => new Date(record.dateTimeCreated).toLocaleString()} />
                        <Column title="Version" width="120px" dataIndex="version" />
                        <Column title="Actions" width="120px" render={(text, record: Workflow) => (
                            <>
                                <Button
                                    type='text'
                                    icon={<FontAwesomeIcon icon={solid("pen-to-square")}
                                    onClick={() => { openEditWorkflowTab(dispatch, record.workflowId) }} />} />
                                <DangerousConfirmButton
                                    popupText={<>Delete workflow?</>}
                                    icon={solid("trash-can")}
                                    isInProgress={record.isDeleteInProgress}
                                    onConfirm={() => { dispatch(deleteWorkflow({ workflowId: record.workflowId })) }} />
                            </>
                        )}/>
                    </Table>
                </Col>
            </Row>
        </GutteredPage>
    );
}
